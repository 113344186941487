const VerticalCenter = ({ text, cClass, cStyle }) => {
    return (
        <div className="table w-full h-full">
            <div
                className={`table-cell vertical-middle w-full h-full text-center ${cClass ? cClass : "white text-xl"}`}
                style={cStyle ? cStyle : {}}
            >
                {text}
            </div>
        </div>
    );
};

export default VerticalCenter;
