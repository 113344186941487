/*
 ============================================================================
   Web Solution Project, ID SW Development Department, LG ELECTRONICS INC., SEOUL, KOREA
   Copyright(c) 2021 by LG Electronics Inc.
   idcap version : 5
 ============================================================================
*/
export var extIDCAPSecure,extRegisterIDCAPCloseHandler,extDisableIdcapConsoleLog,extWebWorker,isSubscribeParam,retry_count,idcap;
if(void 0===idcap)if("object"===typeof window)(function(){function c(a){1==idcap.isLogEnabled&&(a=1024<a.length?a.substring(0,1024):a,!0!==extWebWorker&&console.log(a))}function m(a,d){if(!0!==extWebWorker){var e="",h=document.createEvent("HTMLEvents");c(n("event received, ",d));h.initEvent(a,!0,!1);for(e in d)d.hasOwnProperty(e)&&(h[e]=d[e]);document.dispatchEvent(h)}}idcap={API_VERSION:"5",isRemoteInitialize:!1,isRemote:!1,remote_ip:"",isLogEnabled:!1,isMaxCount:4};var g=0,b=[{command_id:"0",param_text:'{"command_id" : "0", "command" : "notify_sdk_version","parameters":{"idcap_js_extension_version" : "5"}}'}],
k=[{command:"0",param_text:'{"command" : "0", "command" : "notify_sdk_version", "parameters":{"idcap_js_extension_version" : "5"}}'}],l=null,p=!1,v=!1,q=!1,A=null,w=null,E=null,B=!1,n=null,C=!1,z=!1,D=0,x="127.0.0.1";!0!==idcap.isLogEnabled?!0!==extWebWorker&&console.log("ID -  console log is disabled"):!0!==extWebWorker&&console.log("ID -  console log is enabled");c("check external value : extDisableIdcapConsoleLog = "+extDisableIdcapConsoleLog+", extIDCAPSecure = "+extIDCAPSecure+", extRegisterIDCAPCloseHandler = "+
extRegisterIDCAPCloseHandler);B=function(){var a=navigator.userAgent;a.match(/Windows/);a.match(/Macintosh/);a.match(/Mac OS X/);var d=a.match(/Web0S/),e=a.match(/SmartTV/),h=a.match(/WebAppManager/);c("UA = '"+a+"'");return d||e||h?(z=!1,idcap.isRemote=!1):z=idcap.isRemote=!0}();n=function(a,d){var e="";var h=[],r="";for(e in d)d.hasOwnProperty(e)&&h.push(e);h.sort();for(e=0;e<h.length;e+=1){var u=e,f=h,t="",y="";var F=f[u];try{t=d[F],y=typeof t}catch(G){t="<unknown value>",y="unknown"}"function"===
y?t="{/*function*/}":"object"===y?t=n("",t):"string"===y&&(t='"'+t+'"');r+='"'+F+'" : '+t;u<f.length-1&&(r+=", ")}return a+"{"+r+"}"};A=function(){c("initialize_idcap_websocket");v?c("websocket : connection is in progress"):(v=!0,0==z?!0!==extIDCAPSecure?(c("default idcap connection"),l=new WebSocket("ws://127.0.0.1:8153/hcap_command")):(c("secure idcap connection"),l=new WebSocket("wss://localhost:8154/idcap_command")):(c("remote connection"),l="https:"===location.protocol?new WebSocket("wss://"+
x+":8154/idcap_command"):new WebSocket("ws://"+x+":8153/idcap_command")),l.onopen=function(){c("websocket : onopen");v=!1;p=!0;setTimeout(w,5)},l.onmessage=function(a){a=JSON.parse(a.data);var d=a.command_id,e=a.command,h=a.result;delete a.command_id;delete a.command;c("ws.onmessage : "+JSON.stringify(a));"event"!==d&&delete a.result;if("event"===d)"debug_event_received"===e?(a.enable_log?!0!==extWebWorker&&console.log("idcap console log is enabled"):!0!==extWebWorker&&console.log("idcap console log is disabled"),
extDisableIdcapConsoleLog=!a.enable_log):m(e,a);else{if(0<b.length&&b[0].command_id===d){c(n("command_id = "+d+" received, ",a));if(h){if(b[0].onSuccess)try{b[0].onSuccess(a)}catch(f){c(n("exception : onSuccess : "+f))}}else if(b[0].onFailure)try{b[0].onFailure(a)}catch(f){c(n("exception : onFailure : "+f))}b.splice(0,1)}else{var r=0,u=!1;k.forEach(function(f){null!==f&&void 0!==f.command&&f.command===e&&(r=k.indexOf(f),u=!0)});if(u){if(k[r].onSuccess)try{k[r].onSuccess(a)}catch(f){c(n("exception : onSuccess : "+
f))}}else c(n("invalid response from server ",a))}q=!1;w()}},l.onclose=function(){c("websocket : onclose");q=p=v=!1;D+=1;c("retry-count",D);!z&&D<idcap.isMaxCount?setTimeout(w,50):c("Max retry - stop websocket connection try")},"onbeforeunload"===extRegisterIDCAPCloseHandler?window.onbeforeunload=function(){c("close idcap websocket in onbeforeunload handler");l.onclose=function(){};l.close()}:"onunload"===extRegisterIDCAPCloseHandler&&(window.onunload=function(){c("close idcap websocket in onunload handler");
l.onclose=function(){};l.close()}))};w=function(){c("start_cmd_loop");if(!q){q=!0;if(p){if(0<b.length){c("webSocket sending");c(n("command_id = "+b[0].command_id+" sent, ",JSON.parse(b[0].param_text)));l.send(b[0].param_text);return}}else A();q=!1}};E=function(a,d){if(null===a||""===a||void 0===a)c("[ERROR] Command should not be null.");else if(!1===a.toLowerCase().startsWith("idcap://"))c("[ERROR] wrong IDCAP command.");else if(Array.isArray(d))c("[ERROR] Command Parameter Error. param is Array type or not defined.");
else{B&&c("[ERROR] IDCAP is unavailable on this browser.");g=1024<g?0:g+1;var e=g.toString(),h="";d.command_id=e;d.command=a;h=JSON.stringify(d,null);c("param_text : "+h);for(name in d)"subscribe"===name&&d.hasOwnProperty("subscribe")&&!0===d.subscribe&&(C=!0);if(C)k.forEach(function(f){null!==f&&void 0!==f.command&&f.command===d.command?c("already registered. : "+a):k[k.length]={command:a,param_text:h,onSuccess:d.onSuccess,onFailure:d.onFailure}}),C=!1;else{var r=0,u=!1;k.forEach(function(f){null!==
f&&void 0!==f.command&&f.command===d.command&&(r=k.indexOf(f),u=!0)});u?k.splice(r,1):!1}b[b.length]={command_id:e,param_text:h,onSuccess:d.onSuccess,onFailure:d.onFailure};c(n("command_id = "+e+" added, ",b[b.length-1]));w()}};B||setTimeout(w,200);idcap.remoteInitialize=function(a){c("remoteInitialize");x=a;c("remote = "+x);idcap.remote_ip=x;A();idcap.isRemoteInitialize=!0};idcap.remoteFinalize=function(){c("remoteFinalize");l.close();l=null;idcap.remote_ip="";idcap.isRemoteInitialize=!1};idcap.request=
{};idcap.request=function(a,d){a=a.toLowerCase();E(a,d)}})();else{var ev=require("events").EventEmitter;class c extends ev{constructor(m){super();let g=this;this.service=m;this.service.Request=function(b,k){"/"!=b.charAt(b.length-1)&&(b+="/");b+=k.method;var l={};!0===k.hasOwnProperty("parameters")&&(l=k.parameters);var p={},v=function(q){!0===q.payload.returnValue?(p=q.payload,k.onSuccess(p)):(p.returnValue=!1,p.errorCode=q.payload.errorCode,p.errorText=q.payload.errorText,k.onFailure(p))};g.service&&
g.service.call(b,l,v)};this.subsForEvents=this.service.subscribe("luna://com.webos.service.idcapmw.mwcommand/getAllEvents",{subscribe:!0});this.subsForEvents.on("response",function(b){b=b.payload;if("event"===b.command_id)g.on_event_received(b.command,b)})}on_event_received(m,g){this.emit(m,g)}request(m,g){null!==m&&""!==m&&void 0!==m&&(Array.isArray(g)||void 0===g||g.onSuccess&&"function"===typeof g.onSuccess&&g.onFailure&&"function"===typeof g.onFailure&&this.service.Request("luna://com.webos.service.idcapmw.mwcommand",
{method:"/callidcap",parameters:{command:m,parameters:g.parameters},onSuccess:function(b){b.result?(delete b.returnValue,delete b.result,delete b.command_id,g.onSuccess(b)):(delete b.returnValue,delete b.result,delete b.command_id,g.onFailure(b))},onFailure:function(b){delete b.returnValue;delete b.result;delete b.command_id;g.onFailure(b)}}))}}module.exports=c};
