import { getEnvVar } from "../utils/utils";
import { STB } from "./apis/stb";
import { SHA1 } from "../utils/hash";
import { EVENTS } from "../utils/eventsConst";
import { object } from "../utils/polyfills/object";
import { Logger } from "../utils/logger";
let CACHE: Record<string, any> = {};
const MAX_CACHE_ENTRIES = 50;
const cleanup = (): void => {
    if (object.entries(CACHE).length > MAX_CACHE_ENTRIES) CACHE = object.fromEntries(object.entries(CACHE).slice(1)); //Keys
};

const QUERY_TIMEOUT: number = 15000; //15s

function fetchWrapper(url: string, options: object, timeout: number, propagateError: boolean) {
    return new Promise((resolve, reject) => {
        fetch(url, options).then(resolve, reject);
        if (propagateError) {
            if (timeout) {
                const e = new Error("Connection timed out");
                setTimeout(reject, timeout, e);
            }
        }
    });
}

const Query = ({
    query,
    onResponse,
    onError,
    cache,
    propagateError = true,
}: {
    query: string;
    propagateError?: boolean | undefined;
    onResponse: (res: any) => void;
    onError?: (e: any) => void;
    cache?: boolean;
}) => {
    const cacheKey = cache && SHA1(query);
    if (cache && CACHE[cacheKey as string]) return onResponse?.(CACHE[cacheKey as string]);
    const token = sessionStorage.getItem("token");
    const GraphqlQuery = {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        body: JSON.stringify({
            query: query,
        }),
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
        } as Record<string, string>,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    } satisfies RequestInit;

    if (token) GraphqlQuery.headers["Authorization"] = `Bearer ${token}`;

    fetchWrapper(`${getEnvVar("API")}tv-graphql`, GraphqlQuery, QUERY_TIMEOUT, propagateError)
        .then((res) => (res as any).json())
        .then((res) => {
            if (cache) {
                CACHE[cacheKey as string] = res;
                cleanup();
            }
            if (onResponse) onResponse(res);
        })
        .catch((error) => {
            console.log(error);
            Logger.remoteLog(`Query error: ${error.message} query: ${query}`);
            if (onError) {
                onError?.(error);
                return;
            }
            if (error.message === "Connection timed out") {
                let e = new CustomEvent(EVENTS.type.CONNECTION, {
                    detail: EVENTS.CONNECTION.TIMEOUT,
                });
                document.dispatchEvent(e);
            }
            if (propagateError) {
                console.log("threw new error");
                let e = new CustomEvent(EVENTS.type.CONNECTION, {
                    detail: EVENTS.CONNECTION.ERROR,
                });
                document.dispatchEvent(e);
            }

            //tv must be reloaded because there is a new TV App version
            if (sessionStorage.getItem("tvAppOutdated")) {
                Logger.remoteLog(`TV is outdated => reload`);
                sessionStorage.removeItem("tvAppOutdated");
                STB.reload();
            }
        });
};

export default Query;

let VENDURE_CACHE: Record<string, any> = {};
let VENDURE_KEY_TIMESTAMP: Record<string, number> = {};
const MAX_VENDURE_CACHE_ENTRIES = 50;
export const vendureCacheCleanup = () => {
    if (object.entries(VENDURE_CACHE).length > MAX_VENDURE_CACHE_ENTRIES)
        CACHE = object.fromEntries(object.entries(VENDURE_CACHE).slice(1)); //Keys
};

export const resetVendureCache = () => {
    VENDURE_CACHE = {};
};
export const resetAllQueryCache = () => {
    VENDURE_CACHE = {};
    CACHE = {};
};

const isCacheValid = (key: string): boolean => {
    const minutesOfValidity = 30;
    const keyTimestamp = VENDURE_KEY_TIMESTAMP[key];
    if (keyTimestamp && (new Date().getTime() - keyTimestamp) / (1000 * 60) > minutesOfValidity) {
        return false;
    }
    return true;
};

export const vendureQuery = ({
    body,
    headers = {},
    cache,
    onResponse,
    onError,
    apiType = "admin",
}: {
    body: string;
    headers?: Record<string, string>;
    cache?: boolean;
    onResponse: (res: any) => void;
    onError?: (e: any) => void;
    apiType?: "admin" | "shop";
}) => {
    const cacheKey = SHA1(body + ";" + headers["vendure-token"] || "");
    const isCached = cache && VENDURE_CACHE[cacheKey] && isCacheValid(cacheKey);
    if (isCached) return onResponse?.(VENDURE_CACHE[cacheKey]);
    fetch(`${getEnvVar("VENDURE")}${apiType}-api?languageCode=${localStorage.getItem("lang")}`, {
        method: "POST",
        body: JSON.stringify({ query: body }),
        headers: <HeadersInit>{
            "Content-Type": "application/json",
            ...headers,
            Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`,
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (cache) {
                VENDURE_CACHE[cacheKey] = res;
                VENDURE_KEY_TIMESTAMP[cacheKey] = new Date().getTime();
                vendureCacheCleanup();
            }
            onResponse?.(res);
        })
        .catch((error) => {
            Logger.remoteLog(`Vendure Query error: ${error.message}`);
            onError?.(error);
        });
};

export const promisedVendureQuery = ({
    body,
    headers = {},
    cache,
    apiType = "admin",
}: {
    body: string;
    headers?: Record<string, string>;
    cache?: boolean;
    apiType?: "admin" | "shop";
}) => {
    const cacheKey = SHA1(body + ";" + headers["vendure-token"] || "");
    const isCached = cache && VENDURE_CACHE[cacheKey] && isCacheValid(cacheKey);
    if (isCached) return VENDURE_CACHE[cacheKey];
    return fetch(`${getEnvVar("VENDURE")}${apiType}-api?languageCode=${localStorage.getItem("lang")}`, {
        method: "POST",
        body: JSON.stringify({ query: body }),
        headers: {
            "Content-Type": "application/json",
            ...headers,
            Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`,
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (cache) {
                VENDURE_CACHE[cacheKey] = res;
                VENDURE_KEY_TIMESTAMP[cacheKey] = new Date().getTime();
                vendureCacheCleanup();
            }
            return res;
        })
        .catch((error) => {
            Logger.remoteLog(`Vendure Query error: ${error.message}`);
            return null;
        });
};
