import React, { useEffect, useRef, useState } from "react";
import { Media } from "../../../hooks/apis/media";
import { figmapx2vh, formatSeconds } from "../../../utils/utils";
import ChannelLangSelector from "../../tv/channelLangSelector";
import ChannelSubtitleSelector from "../../tv/channelSubtitleSelector";

const PlayerBar = ({ movie }) => {
    const isAdult = movie?.isAdult;

    const updateInterval = useRef();
    const lastStreamPos = useRef(0);

    const [forceUpdate, setForceUpdate] = useState();
    useEffect(() => {
        updateInterval.current = setInterval(() => {
            setForceUpdate(new Date().getTime());
        }, 1000);

        return () => {
            clearInterval(updateInterval.current);
        };
    }, []);

    const getCurrentPercentage = () => {
        const cStreampos = parseInt(Media.getStreamPos()) || lastStreamPos.current;
        lastStreamPos.current = cStreampos;
        const percen = parseFloat((cStreampos * 100) / (movie.length * 60));
        return percen < 98.8 ? percen : 98.8;
    };

    return (
        <div id="moviePlayerBar" className={"float-left ml-2"} style={{ width: "80vw" }}>
            {!isAdult ? (
                <div
                    className={"relative table right-0"}
                    style={{ marginLeft: "auto", left: "2vw", marginBottom: "2vh" }}
                >
                    <div className={"float-left"}>
                        <ChannelLangSelector />
                    </div>
                    {/*<div className={"float-left"}>
                        <ChannelSubtitleSelector />
                    </div>*/}
                </div>
            ) : null}
            <div className={"relative"}>
                <div className={"w-full mb-4 bg-gray-400"} style={{ height: figmapx2vh(8) }}></div>
                <div className={"absolute top-0 w-full"}>
                    <div
                        className={"mb-4 bg-gray-200 float-left "}
                        style={{ height: figmapx2vh(8), width: `${getCurrentPercentage()}%` }}
                    ></div>
                    <div
                        className={"mb-4 bg-gray-200 top-0 float-left "}
                        style={{
                            height: figmapx2vh(18),
                            width: figmapx2vh(18),
                            borderRadius: "50%",
                            marginTop: "-0.5vh",
                        }}
                    ></div>
                </div>
            </div>
            <div>{`${formatSeconds(parseInt(Media.getStreamPos()) || lastStreamPos.current)} / ${formatSeconds(
                movie.length * 60,
            )}`}</div>
        </div>
    );
};

export default PlayerBar;
