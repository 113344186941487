import { useEffect, useRef } from "react";
import Button from "../../components/common/button";
import VerticalCenter from "../../components/common/verticalCenter";
import { CHANNELS } from "../../utils/constants";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { KEYS } from "../../utils/keys";
import { figmapx2vh, figmapx2vw, getEnvVar } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useMemo } from "preact/hooks";
import { STB } from "../../hooks/apis/stb";

const ChannelGroup = ({
    group,
    groupPos,
    numGroups,
    filter,
    setPlayingChannel,
    tvState,
    setTvState,
    singleGroup,
    verticalSteps,
    setVerticalSteps,
    scrollToFocus,
    showContinue,
    maxGroupItems,
    fullGroup,
    showFullGroup,
    focusOnFGClose,
    resetChannelInfoTimeout,
}) => {
    const texts = useSelector((state) => state.ui.texts);

    const itemsPerRow = singleGroup ? 7 : 8;
    const itemsVisibleOnFocus = 5;
    const maxVerticalSteps = Math.floor((group?.items?.length + 1) / itemsPerRow) - 2;
    const horizontalOffset = 11.97;
    const horizontalSteps = useRef(0);
    const firstFocus = useRef(true);
    const verticalStepsRef = useRef();
    verticalStepsRef.current = verticalSteps;
    const isFakeGroup = group?.id == 100;

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (typeof resetChannelInfoTimeout === "function") {
            resetChannelInfoTimeout();
        }

        if (keyData) {
            let cChannelNum, row, cGroupPos;
            switch (keyData.value) {
                case KEYS.back:
                    if (fullGroup) {
                        showFullGroup(null);
                        preventDefault = true;
                    }

                    break;
                case KEYS.down:
                    cChannelNum = getFocusedChannelNum();
                    if (!cChannelNum) {
                        break;
                    }
                    if (singleGroup) {
                        row = Math.floor(cChannelNum / 7) + 1;
                        if (row > 1 && (row - 1 <= maxVerticalSteps || (fullGroup && row - 2 <= maxVerticalSteps))) {
                            setVerticalSteps(verticalStepsRef.current + 1);
                        }
                    } else {
                        cGroupPos = document.activeElement.parentElement?.getAttribute("group-pos");
                        if (
                            cGroupPos &&
                            parseInt(cGroupPos) > 1 &&
                            verticalStepsRef.current < numGroups - (showContinue ? 2 : 3)
                        ) {
                            setVerticalSteps(verticalStepsRef.current + 1);
                        }
                    }
                    break;

                case KEYS.up:
                    cChannelNum = getFocusedChannelNum();
                    if (!cChannelNum) {
                        break;
                    }
                    cGroupPos = document.activeElement.parentElement?.getAttribute("group-pos");
                    if (
                        (!singleGroup || cChannelNum < itemsPerRow) &&
                        cGroupPos &&
                        parseInt(cGroupPos) === 1 &&
                        document.getElementById("btn-continue")
                    ) {
                        focus.value.replace("btn-continue");
                        preventDefault = true;
                        break;
                    }
                    if (verticalStepsRef.current) {
                        setVerticalSteps(verticalStepsRef.current - 1);
                    }

                    break;

                case KEYS.right:
                    if (singleGroup) {
                        if (!document.activeElement?.nextSibling) {
                            preventDefault = true;
                        }
                        break;
                    }
                    if (!singleGroup && !document.activeElement?.nextSibling) {
                        preventDefault = true;
                        break;
                    }
                    cChannelNum = getFocusedChannelNum();

                    if (cChannelNum > horizontalSteps.current + itemsVisibleOnFocus) {
                        focus.value.replace(document.activeElement?.nextSibling.id);
                        horizontalSteps.current = horizontalSteps.current + 1;
                        ensureHorizontal();
                        preventDefault = true;
                    } else {
                        focus.value.replace(document.activeElement?.nextSibling.id);
                        preventDefault = true;
                    }
                    break;

                case KEYS.left:
                    if (singleGroup) {
                        break;
                    }
                    if (!singleGroup && !document.activeElement?.previousSibling) {
                        preventDefault = true;
                        break;
                    }
                    cChannelNum = getFocusedChannelNum();
                    if (cChannelNum == horizontalSteps.current + 1) {
                        focus.value.replace(document.activeElement?.previousSibling.id);
                        horizontalSteps.current = horizontalSteps.current - 1;
                        ensureHorizontal();
                        preventDefault = true;
                    } else {
                        focus.value.replace(document.activeElement?.previousSibling.id);
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const ensureHorizontal = () => {
        if (singleGroup) {
            return;
        }
        const groupDiv = document.getElementById(`group-${groupPos}`);
        if (groupDiv) {
            groupDiv.children[1].style.marginLeft = `-${
                parseFloat(horizontalSteps.current) * parseFloat(horizontalOffset)
            }vw`;
        }
    };

    useEffect(() => {
        if (singleGroup && verticalSteps > maxVerticalSteps) {
            setVerticalSteps(maxVerticalSteps);
        }
    }, [verticalSteps]);

    useEffect(() => {
        focus.subscribe((focus) => {
            if (scrollToFocus && firstFocus.current && focus?.current?.indexOf("btn-channel") > -1) {
                horizontalSteps.current = getFocusedChannelNum() - 1;
                ensureHorizontal();
                firstFocus.current = false;
            }
            if (singleGroup) {
                const invisibleLeyends = document.getElementsByClassName("showHiddenLegend");

                for (var i = 0; i < invisibleLeyends.length; i++) {
                    invisibleLeyends[i].classList.remove("showHiddenLegend");
                }
                let cPos = getFocusedChannelNum();
                cPos = itemsPerRow - (cPos % itemsPerRow);
                let initialItem = document.activeElement;

                for (let index = 0; index < cPos; index++) {
                    initialItem = initialItem?.nextSibling;
                }
                if (initialItem && cPos < itemsPerRow) {
                    initialItem.classList.add("showHiddenLegend");
                }
            }
        });
    });

    const getFocusedChannelNum = () => {
        return focus.value.current?.indexOf("btn-channel") > -1
            ? document.activeElement.getAttribute("customattribute")
            : null;
    };

    const focusElem = document.getElementById(focus.value.current);
    const focusChannelPos = parseInt(focusElem?.getAttribute("customattribute") || 0);

    return useMemo(() => {
        return (
            <div
                id={`group-${groupPos}`}
                className={`channelGroup ${singleGroup ? "verticalGroup" : "horizontalGroup"}`}
                onKeyDown={(e) => {
                    onkeydown(e);
                }}
                key={group.name}
                style={{
                    fontSize: `${figmapx2vw(36)}`,
                    fontWeight: 700,
                    marginBottom: `${figmapx2vh(tvState === CHANNELS.TV_STATES.CHANNEL_INFO ? 24 : 56)}`,
                }}
            >
                {!singleGroup || fullGroup ? (
                    <div style={{ marginBottom: `${figmapx2vh(24)}` }}>{group.name}</div>
                ) : null}
                <div
                    group-pos={groupPos}
                    className={`${STB.supportAnimations && "animateMarginLeft"} ${!singleGroup ? "inline-flex" : ""}`}
                    style={{
                        height: !singleGroup ? `${figmapx2vh(163)}` : null,
                        width: !singleGroup ? "max-content" : "100vw",
                        overflow: "hidden",
                    }}
                >
                    {group.items.map((channel, index) => {
                        if (!singleGroup && maxGroupItems && index === maxGroupItems) {
                            return (
                                <Button
                                    key={channel.num}
                                    data={{
                                        id: `channel_${channel.num}`,
                                        disabled: isFakeGroup,
                                        customClass: `left-bounding ${index < group.items.length - 1 && "float-left"}`,
                                        customAttribute: index + 1,
                                        bodyClass: !singleGroup ? "inline-flex" : null,
                                        focusClass: "channel-focus",
                                        customStyle: {
                                            width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                            marginRight: `${figmapx2vw(singleGroup ? 16 : 24)}`,
                                            marginBottom: `${figmapx2vh(singleGroup ? 40 : 0)}`,
                                            borderRadius: `${figmapx2vw(4)}`,
                                        },
                                        removeButtonClass: true,
                                        onClick() {
                                            focusOnFGClose.current = focus.value.current;
                                            showFullGroup(group.id);
                                        },
                                        body: (
                                            <div
                                                className={`w-full h-full rounded remark`}
                                                style={{
                                                    width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                                    height: `${figmapx2vh(singleGroup ? 192 : 160)}`,
                                                    backgroundColor: "#252626",
                                                }}
                                            >
                                                <VerticalCenter
                                                    text={
                                                        <div className={"content"}>
                                                            <div
                                                                className="nowrap mx-auto text-ellipsis overflow-hidden"
                                                                style={{
                                                                    fontSize: `${figmapx2vw(24)}`,
                                                                    width: `${figmapx2vw(180)}`,
                                                                }}
                                                            >
                                                                {texts["Show all"]}
                                                            </div>
                                                        </div>
                                                    }
                                                    cClass={`white text-5xl`}
                                                />
                                            </div>
                                        ),
                                    }}
                                ></Button>
                            );
                        }
                        if (!singleGroup && maxGroupItems && index > maxGroupItems) {
                            return;
                        }
                        return (
                            <Button
                                key={channel.num}
                                data={{
                                    id: `channel_${channel.num}`,
                                    disabled: isFakeGroup,
                                    customClass: `left-bounding ${index < group.items.length - 1 && "float-left"}`,
                                    customAttribute: index + 1,
                                    bodyClass: !singleGroup ? "inline-flex" : null,
                                    focusClass: "channel-focus",
                                    customStyle: {
                                        width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                        marginRight: `${figmapx2vw(singleGroup ? 16 : 24)}`,
                                        marginBottom: `${figmapx2vh(singleGroup ? 40 : 0)}`,
                                        borderRadius: `${figmapx2vw(4)}`,
                                    },

                                    removeButtonClass: true,
                                    onClick() {
                                        setPlayingChannel(channel);
                                        setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
                                    },
                                    body: (
                                        <>
                                            {channel.logofile ? (
                                                <div
                                                    className={`w-full h-full bg-center rounded bg-contain bg-no-repeat remark `}
                                                    style={{
                                                        width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                                        height: `${figmapx2vh(singleGroup ? 192 : 160)}`,
                                                        backgroundColor: "#252626",
                                                        borderRadius: `${figmapx2vw(4)}`,
                                                        backgroundImage: `url('${
                                                            channel.logoURL ||
                                                            `${getEnvVar("API")}ext/${
                                                                CHANNELS.logoPath?.pRef
                                                            }/gfx_base/contents/tv/${
                                                                channel.logofile
                                                            }?center_crop=120x120`
                                                        }')`,
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    className={`w-full h-full rounded remark`}
                                                    style={{
                                                        width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                                        height: `${figmapx2vh(singleGroup ? 192 : 160)}`,
                                                        backgroundColor: "#252626",
                                                    }}
                                                >
                                                    <VerticalCenter
                                                        text={
                                                            <div className={"content"}>
                                                                <div className={`icon-channels`} />
                                                                <div
                                                                    className="nowrap mx-auto text-ellipsis overflow-hidden"
                                                                    style={{
                                                                        fontSize: `${figmapx2vw(24)}`,
                                                                        width: `${figmapx2vw(180)}`,
                                                                        marginTop: `${figmapx2vh(16)}`,
                                                                    }}
                                                                >
                                                                    {channel.name}
                                                                </div>
                                                            </div>
                                                        }
                                                        cClass={`white text-5xl`}
                                                    />
                                                </div>
                                            )}

                                            {/*vertical channel focus info*/}
                                            {singleGroup ? (
                                                <div
                                                    style={{
                                                        width: `${figmapx2vw(singleGroup ? 240 : 206)}`,
                                                    }}
                                                    className="channelFocusLeyend"
                                                >
                                                    <div
                                                        className={"float-left"}
                                                        style={{ width: channel.num < 10 ? "20%" : "35%" }}
                                                    >{`${channel.num} - `}</div>
                                                    <div
                                                        className={"float-left"}
                                                        style={{
                                                            width: channel.num < 10 ? "80%" : "65%",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            webkitLineClamp: 2,
                                                            webkitBoxOrient: "vertical",
                                                        }}
                                                    >{`${channel.name}`}</div>
                                                </div>
                                            ) : (
                                                /*horizontal channel focus info*/
                                                <div className="channelFocusLeyend_horizontal">
                                                    <div
                                                        className={"float-left"}
                                                        style={{ width: channel.num < 10 ? "15%" : "25%" }}
                                                    >{`${channel.num} - `}</div>
                                                    <div
                                                        className={"float-left"}
                                                        style={{
                                                            width: channel.num < 10 ? "85%" : "75%",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            webkitLineClamp: 2,
                                                            webkitBoxOrient: "vertical",
                                                        }}
                                                    >{`${channel.name}`}</div>
                                                </div>
                                            )}
                                        </>
                                    ),
                                }}
                            ></Button>
                        );
                    })}
                </div>
            </div>
        );
    }, []);
};

export default ChannelGroup;
