import { useSelector } from "react-redux";
import { Texts } from "../../utils/texts";

const RadioChannelDisplay = ({ playingChannel }) => {
    const texts = useSelector((state) => state.ui.texts);

    return (
        <>
            {playingChannel?.isradio ? (
                <div
                    className="fixed text-center w-full h-full top-0 left-0 table bg-black fg-white"
                    style={{
                        zIndex: "99",
                        visibility: "visible",
                    }}
                >
                    <div className="vertical-middle " style={{ fontSize: "5.5vw", marginTop: "34vh" }}>
                        <div className="icon-audio-channels-movies"></div>
                        <div style={{ fontSize: "2.5vw" }}>{Texts.capitalize(texts["audio"])}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default RadioChannelDisplay;
