import { makeKeyboardEvent } from "../../../utils/events";


export default (({id, signal, className, style = {}, disabled}: {id?: string, signal: React.Signal<boolean>, disabled?: boolean, className?: string, style?: Omit<React.CSSProperties, 'position'>})=>{
    const onInput = makeKeyboardEvent(["enter"], ()=>{
        signal.value = !signal.value;
        return {stopPropagation: true, preventDefault: true};
    });

    return (
        <label onKeyDown={onInput} tabIndex={0} style={{...style, position: 'relative'}} id={`${id}-label`} className={className}>
            <input disabled={disabled} type="checkbox" checked={signal} id={id} defaultChecked={signal.peek()} style={{opacity: 0}}/>
            {signal.value ? <div className={'icon-tick-right'} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: style.fontSize ?? 'inherit',
                color: style.color ?? 'inherit'
            }}></div> : null}
        </label>
    );
});