import { useEffect, useState } from "preact/hooks";
import Button from "../../../components/common/button";
import { Texts } from "../../../utils/texts";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp } from "../../../actions/uiActions";
import { figmapx2vw, inPreview } from "../../../utils/utils";
import { STB } from "../../../hooks/apis/stb";
import { TV_MODELS } from "../../../utils/constants";

const TrailerButton = ({ item, playTrailer }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const [trailerRdy, setTrailerRdy] = useState(false);

    const checkTrailer = () => {
        if (STB.model === TV_MODELS.GOOGLE_TV) {
            setTrailerRdy(true);
        } else {
            var request = new XMLHttpRequest();
            request.open("GET", item.trailerUrl, true);
            request.send();
            request.onload = () => {
                setTrailerRdy(true);
            };
        }
    };

    useEffect(() => {
        checkTrailer();
    }, []);

    if (!item.isAdult && trailerRdy) {
        return (
            <Button
                insideModal={true}
                data={{
                    id: "trailer",
                    name: Texts.translateCap(texts, "watch-trailer"),
                    customClass: `py-2 px-2 w-auto text-2xl my-auto text-gray-100 rounded text-center`,
                    customStyle: {
                        minWidth: figmapx2vw(208),
                        color: "#F5F6F8",
                        marginRight: figmapx2vw(40),

                        border: `${figmapx2vw(4)} solid #F5F6F8`,
                        borderColor: "#F5F6F8",
                    },
                    focusClass: "grid-btn-back-focus rounded",
                    onClick() {
                        if (inPreview()) {
                            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
                            return;
                        }
                        // watch trailer
                        playTrailer();
                    },
                }}
            />
        );
    }
    return null;
};

export default TrailerButton;
