import React, { useLayoutEffect } from "react";
import Button from "../../../components/common/button";
import { figmapx2vh, figmapx2vw } from "../../../utils/utils";
import focus from "../../../utils/focus";
import { setPopUp } from "../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { procentric } from "./procentric";

/** - usb app options for LG - **/

/** - COMPONENT JUST FOR LG - **/

const UsbSelectContent = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    const width = "100%";
    const height = "100%";
    const butonInputCustomClass = "text-center mx-auto py-2 text-2xl";
    const buttonInputCustomStyle = {
        width: figmapx2vw(208),
        height: figmapx2vh(208),
        backgroundColor: "#F5F6F8",
        color: "#2E3843",
    };

    const photoandvideo = procentric.appList.find((app) => app.id === procentric.APP_PHOTOVIDEO_ID);
    const music = procentric.appList.find((app) => app.id === procentric.APP_MUSIC_ID);

    useLayoutEffect(() => {
        if (document.getElementById(`btn-1`)) {
            focus.value.replace(`btn-1`);
        }
    }, []);

    const openAppById = (id) => {
        dispatch(setPopUp(null));
        procentric.appOpenById(id);
    };

    return (
        <div
            className={`fixed  z-900 top-0 left-0`}
            style={{
                width: `${document.body.offsetWidth || window.innerWidth}px`,
                height: `${document.body.offsetHeight || window.innerHeight}px`,
                visibility: "visible",
            }}
        >
            <div id="pop-up-zone" className="table h-full mx-auto" style={{ width: width || "32rem" }}>
                <div
                    className={"white "}
                    style={{
                        marginTop: "30vh",
                        position: "relative",
                        height: "70vh",
                        background:
                            "linear-gradient(0deg, rgba(55, 61, 66, 0.90) 45.02%, rgba(55, 61, 66, 0.71) 66.74%, rgba(55, 61, 66, 0.08) 90.9%, rgba(55, 61, 66, 0.00) 99.3%)",
                    }}
                >
                    <div class={"table h-full w-full mx-auto"}>
                        <div className="table-cell vertical-middle">
                            <div className={"text-center pb-8 text-4xl"} style={{ marginTop: "32vh" }}>
                                {texts["select-content"]}
                            </div>
                            <div class="mx-auto table pt-2" style="width: fit-content">
                                <div className={"numberRow"}>
                                    <Button
                                        data={{
                                            id: 1,
                                            outline: true,
                                            focusClass: "underSlashFocus",
                                            wrapperClass: "table float-left px-8",
                                            name: photoandvideo.title,
                                            faIcon: { lib: "fa", name: "photo-video" },
                                            iconSize: "text-5xl",
                                            rounded: true,
                                            customClass: butonInputCustomClass,
                                            customStyle: buttonInputCustomStyle,
                                            onClick: () => openAppById(procentric.APP_PHOTOVIDEO_ID),
                                        }}
                                    />
                                    <Button
                                        data={{
                                            id: 2,
                                            outline: true,
                                            focusClass: "underSlashFocus",
                                            wrapperClass: "table float-left px-8",
                                            name: music.title,
                                            faIcon: { lib: "fa", name: "music" },
                                            iconSize: "text-5xl",
                                            rounded: true,
                                            customClass: butonInputCustomClass,
                                            customStyle: buttonInputCustomStyle,
                                            onClick: () => openAppById(procentric.APP_MUSIC_ID),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsbSelectContent;
