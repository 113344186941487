import "./style";
import "./style/icomoon.css";
import "jspolyfill-array.prototype.find";
import "regenerator-runtime/runtime.js";
import "new-event-polyfill";
import "polyfill-array-includes";
import "core-js/stable/promise/index";
import "core-js/stable/array/index";
import "core-js/stable/set/index";
import "core-js/stable/string/replace-all";
import "core-js/stable/string/starts-with";
import "core-js/stable/map/index";
import "core-js/stable/string/pad-start";
import "core-js/web/index";
import "es6-symbol/implement";

import App from "./app";

export default App;
