import { isJsonString } from "./utils";

export default {
    get<T extends keyof LocalStorage>(key: T, defaultValue: LocalStorage[T]): LocalStorage[T] |string{
        const storageVal = localStorage.getItem(key);
        if(storageVal && isJsonString(storageVal)){
            return JSON.parse(<string>localStorage.getItem(key)) as LocalStorage[T];
        }
        return localStorage.getItem(key) || defaultValue;
    },
    set<T extends keyof LocalStorage>(key: T, value: LocalStorage[T]){
        localStorage.setItem(key, JSON.stringify(value));
    },
    del<T extends keyof LocalStorage>(key: T){
        localStorage.removeItem(key);
    }

}