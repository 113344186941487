import { useEffect, useRef, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { Texts } from "../utils/texts";
import VerticalCenter from "./common/verticalCenter";
import { Theme } from "../utils/theme";

const Loading = ({ text, interval = 500, style, inModal }) => {
    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const [dots, setDots] = useState("");
    const dotsRef = useRef();
    dotsRef.current = dots;

    useEffect(() => {
        const setI = setInterval(() => {
            if (dotsRef.current !== "...") {
                dotsRef.current = dotsRef.current + ".";
            } else {
                dotsRef.current = "";
            }
            setDots(dotsRef.current);
        }, interval);

        return () => {
            clearInterval(setI);
        };
    }, []);

    return (
        <VerticalCenter
            text={Texts.capitalize(`${text || texts["loading"] + dots} `)}
            cClass={`text-xl ${inModal ? (Theme.light ? "text-gray-800" : "text-gray-100") : ""}`}
            cStyle={style}
        />
    );
};

export default Loading;
