import { memo, useEffect } from "preact/compat";
import { useDispatch, useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import { Theme } from "../../utils/theme";
import Button from "../../components/common/button";
import { setPopUp } from "../../actions/uiActions";
import focus from "../../utils/focus";

const AlarmOFF = () => {
    const dispatch = useDispatch();

    //Store data
    const texts = useSelector((state) => state.ui.texts);

    //listeners
    useEffect(() => {
        focus.value.replace("btn-new-alarm");
    }, []);

    return (
        <div className="text-center">
            <div
                className="table mx-auto "
                style={{
                    marginBottom: "5vh",
                    marginTop: "3vh",
                    fontSize: "8vh",
                }}
            >
                <div className="icon icon-alarm float-left pr-4"></div>
                <div className="icon icon-no-color absolute float-left pr-4" style={{ opacity: "0.6" }}></div>
            </div>

            <Button
                data={{
                    id: "new-alarm",
                    name: Texts.capitalize(texts["set a new alarm"]),
                    border: true,
                    customClass: "py-2  w-full text-lg text-center rounded",
                    customStyle: {
                        borderColor: Theme.colors.default.gray["800"],
                        backgroundColor: "white",
                        color: Theme.colors.default.gray["800"],
                    },
                    onClick: function () {
                        dispatch(setPopUp("alarm"));
                    },
                }}
            />
        </div>
    );
};

export default memo(AlarmOFF);
