import { useSelector } from "react-redux";
import { screenUtils } from "../../../utils/screenUtils";
import { useMemo } from "preact/compat";

const MessageItem = (message) => {
    //Store data
    const tags = useSelector((state) => state.status.locationData.tags);

    //Functions
    const isCritical = (msg) => {
        if (!msg) {
            return null;
        }
        return ["PRIORITY", "CRITICAL"].indexOf(msg.priority) > -1;
    };
    const parseTime = (time) => {
        if (!time) {
            return "";
        }
        const myDate = new Date(time);
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        return myDate < today
            ? myDate.toLocaleDateString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
              })
            : myDate.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
              });
    };

    return useMemo(() => {
        return (
            <div
                className={`px-2 py-2 border rounded border-gray-300 ${
                    message.isRead || String(message.id) === sessionStorage.getItem("messageShownId")
                        ? ""
                        : "bg-gray-200"
                }`}
            >
                <div className={`${message.isRead ? "text-xl font-500" : "text-2xl bold"} pb-2`}>
                    {isCritical(message.message) ? (
                        <span class="icon icon-message-important msgUrgentIcon pr-2"></span>
                    ) : null}
                    {screenUtils.replaceSpecialTags(message.message.title, tags)}
                </div>
                <div
                    class="text-base overflow-y-hidden pre-line"
                    style="height:6vh;
                    overflow: hidden;
                    line-height: 2vh;
                    height: 4vh;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;"
                >
                    {screenUtils.replaceSpecialTags(message.message.body, tags)}
                </div>
                <div class="text-base mt-3 text-right">{parseTime(message.message.sentTime)}</div>
            </div>
        );
    }, [sessionStorage.getItem("messageShownId")]);
};

export default MessageItem;
