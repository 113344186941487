/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "preact/compat";

import { useDispatch, useSelector } from "react-redux";
import { setMediaEvent } from "../actions/eventsActions";
import { setVideoPos } from "../actions/uiActions";
import { Media } from "../hooks/apis/media";
import { MESSAGES_STYLES } from "../utils/constants";
import { KEYS } from "../utils/keys";
import {
    completeDasURL,
    completeVOD_URL,
    printCenteredMessage,
    setVideoOnBackground,
    setVideoOnForeground,
} from "../utils/utils";
import Loading from "./loading";
import { eventHandler } from "../utils/eventHandler";
import Limbo from "./screen/limbo";
import focus from "../utils/focus";
import { EVENTS } from "../utils/eventsConst";

const LibraryContent = ({ asset }) => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);

    const [isVideo] = useState(asset && asset.indexOf("video-") > -1);
    const [isImage] = useState(asset && asset.indexOf("video-") === -1);
    const [showVideoThumb, setShowVideoThumb] = useState(false);
    const [loadingResource, setLoadingResource] = useState(isImage);
    const [badResource, setBadResource] = useState(false);

    useEffect(() => {
        Media.stop();
        if (isVideo) {
            playVideo();
        } else if (isImage) {
            let imageObj = new Image();

            imageObj.onload = function () {
                setLoadingResource(false);
            }.bind(setLoadingResource);
            imageObj.onerror = function () {
                setBadResource(true);
            };
            imageObj.src = completeDasURL(asset, { width: "full" });
        }
        focus.value.replace("limbo");
        return () => {
            setVideoOnForeground();
        };
    }, []);

    useEffect(() => {
        if (reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM) {
            setShowVideoThumb(true);
            dispatch(setMediaEvent(null));
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
        }
    }, [reducerMediaEvent]);

    const playVideo = () => {
        setShowVideoThumb(null);
        setVideoOnBackground();
        Media.playHLSVideo(completeVOD_URL(location.pathname.split("/")?.[2]));
        setTimeout(() => {
            dispatch(setVideoPos("fullscreen"));
        }, 100);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = true;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.home:
                case KEYS.back:
                    preventDefault = false;
                    break;
                case KEYS.enter:
                    if (keyData.value == KEYS.enter && isVideo && !Media.playingMedia) {
                        playVideo();
                    }
                    break;
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo />

            {badResource ? (
                printCenteredMessage(texts["file not found"], MESSAGES_STYLES.OVERLAY)
            ) : loadingResource ? (
                <Loading />
            ) : isImage ? (
                <div
                    className={`fixed top-0 left-0 w-full h-full bg-no-repeat bg-center bg-contain`}
                    style={{
                        backgroundImage: `url(${completeDasURL(asset ? asset : "", { width: "full" })})`,
                    }}
                />
            ) : isVideo && showVideoThumb ? (
                <div
                    className="fixed table top-0 left-0 w-full h-full bg-no-repeat bg-center bg-contain"
                    style={{
                        visibility: "visible",
                        backgroundImage: `url(${completeDasURL(`thumb/${asset}-${localStorage.getItem("lang")}`, {
                            width: "full",
                        })})`,
                    }}
                >
                    <div
                        className="table-cell fa fa-play-circle text-center vertical-middle "
                        style={{ fontSize: "8vw" }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default memo(LibraryContent);
