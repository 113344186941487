import { prepareDeviceKeyMap } from "../../../utils/utils";
import { keyMap } from "./keyMap";

/* eslint-disable no-undef */
export const STB = {
    model: "STB",
    ref: ``,
    needCanvas: true,

    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
        console.log("Init STB api");
    },
    getMac(onSuccess) {
        let cMac = localStorage.getItem("mac");
        if (!cMac) {
            cMac = Android.getMAC();
            localStorage.setItem("mac", cMac);
        }

        this.mac = cMac;
        this.ref = `${this.model}-${this.mac}`;
        if (onSuccess) {
            onSuccess();
        }
    },
    updatePowerState() {
        Android.isTvOn();
    },
};
