import { Theme } from "../../utils/theme";

const InfoLabel = ({ text, vAlign = "bottom", hAlign = "right" }) => {
    let hStyle = "mleft-auto";
    let vStyle = "vertical-bottom";
    switch (hAlign) {
        case "center":
            hStyle = "mx-auto";
            break;
        case "right":
            hStyle = "mleft-auto";
            break;
        case "left":
            hStyle = "mright-auto";
            break;
        default:
            hStyle = "mleft-auto";
            break;
    }
    switch (vAlign) {
        case "center":
            vStyle = "vertical-middle";
            break;
        case "top":
            vStyle = "vertical-top";
            break;
        case "bottom":
        default:
            vStyle = "vertical-bottom";
            break;
    }
    return (
        <div className="absolute  z-100  w-full h-full">
            <div className={`table  h-full ${hStyle}`}>
                <div className={`table-cell ${vStyle}`}>
                    <div
                        className="button py-3 px-4 m-4"
                        style={{
                            color: "white",
                            backgroundColor: Theme.colors.default.infoLabel,
                        }}
                    >
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoLabel;
