import { useSelector } from "react-redux";
import Button from "../../components/common/button";
import {
    figmaHeightPx2Px,
    figmapx2vh,
    figmapx2vw,
    figmaWidthPx2Px,
    getDasExternalUrl,
    getEnvVar,
    printPrice,
} from "../../utils/utils";
import focus from "../../utils/focus";
import VerticalCenter from "../../components/common/verticalCenter";
import { useEffect, useMemo, useState } from "preact/hooks";
import { printMovieLength } from "../../utils/movies";
import { navigate } from "wouter/use-location";
import ReactHtmlParser from "react-html-parser";
import { GENDER } from "../../utils/constants";
import { Texts } from "../../utils/texts";

function Movie({ item = null, singleGroup = false, image = "coverUrl", detail = null, noRentedRibbon = false }) {
    const texts = useSelector((state) => state.ui.texts);
    const CURRENCY_CODE = useSelector((state) => state.status.locationData.projectCurrency);

    const noCoverItem = () => (
        <div
            className={`w-full h-full rounded remark`}
            style={{
                width: figmapx2vw(192),
                height: figmapx2vh(288),
                backgroundColor: "#252626",
                marginTop: `-${figmapx2vh(288)}`,
            }}
        >
            <VerticalCenter
                text={
                    <div className={"content"}>
                        <div className={item.icon} />
                        <div
                            className="nowrap mx-auto text-ellipsis overflow-hidden"
                            style={{
                                fontSize: figmapx2vw(24),
                                width: figmapx2vw(180),
                                marginTop: figmapx2vh(16),
                            }}
                        >
                            {ReactHtmlParser(item.name)}
                        </div>
                    </div>
                }
                cClass={`text-gray-100 text-5xl`}
            />
        </div>
    );

    return (
        <Button
            key={item.id}
            data={{
                id: `${item.groupId}_${item.type}_${item.id}`,
                customClass: `left-bounding  ${"float-left"}`,
                bodyClass: `${singleGroup ? "table pb-5" : "inline-flex"} pt-1 pl-1`,
                customAttribute: item.id,
                focusClass: "grid-focus",
                customStyle: {
                    width: figmapx2vw(192),
                    marginRight: figmapx2vw(24),
                    marginBottom: figmapx2vh(0),
                    borderRadius: figmapx2vw(4),
                },

                removeButtonClass: true,
                onClick() {
                    detail(item);
                },
                body: (
                    <>
                        <div
                            style={{
                                position: "relative",
                                width: figmapx2vw(192),
                                height: figmapx2vh(288),
                                borderRadius: figmapx2vw(4),
                            }}
                        >
                            {item.rented && !item.free2guest && !noRentedRibbon ? (
                                <div className="ribbon-wrapper-rented z-101">
                                    <div
                                        className="ribbon-rented"
                                        style={{ fontSize: figmapx2vw(23), fontWeight: 400 }}
                                    >
                                        {Texts.translateCap(
                                            texts,
                                            item.genderType === GENDER.MALE ? "rented" : "rented f",
                                        )}
                                    </div>
                                </div>
                            ) : (
                                singleGroup &&
                                !item.free2guest &&
                                !item.rented && (
                                    <div className="sigleGroupPriceWrapper">
                                        <VerticalCenter
                                            text={
                                                <div
                                                    className={"text-left pl-2"}
                                                    style={{ fontSize: figmapx2vw(32), fontWeight: 400 }}
                                                >
                                                    {printPrice(item.priceToShow, CURRENCY_CODE)}
                                                </div>
                                            }
                                        />
                                    </div>
                                )
                            )}
                            <div
                                id="coverDiv"
                                className={`w-full h-full bg-center rounded bg-contain bg-no-repeat remark `}
                                style={{
                                    width: figmapx2vw(192),
                                    height: figmapx2vh(288),
                                    backgroundColor: "#252626",
                                }}
                            >
                                <img
                                    id={`coverImg-${item.groupId}-${item.name}`}
                                    alt={"no-cover"}
                                    onError={function () {
                                        document.getElementById(`coverImg-${item.groupId}-${item.name}`).style.display =
                                            "none";
                                    }}
                                    className={`relative rounded remark `}
                                    src={getDasExternalUrl(item[image], {
                                        w: figmaWidthPx2Px(192),
                                        h: figmaHeightPx2Px(288),
                                    })}
                                    style={{
                                        width: figmapx2vw(192),
                                        height: figmapx2vh(288),
                                        backgroundColor: "#252626",
                                        borderRadius: figmapx2vw(4),
                                    }}
                                />
                            </div>
                            {noCoverItem()}
                        </div>

                        {/*vertical channel focus info*/}
                        {singleGroup && focus.value.current !== item.focusId && (
                            <div
                                className="channelFocusLeyend"
                                style={{
                                    width: figmapx2vw(192),
                                    height: "13vh",
                                }}
                            ></div>
                        )}
                        {singleGroup && focus.value.current === item.focusId ? (
                            <div
                                className="channelFocusLeyend"
                                style={{
                                    width: figmapx2vw(192),
                                    display: "block",
                                }}
                            >
                                <div
                                    className={"float-left"}
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        wordBreak: "break-word",
                                        webkitLineClamp: 2,
                                        webkitBoxOrient: "vertical",
                                    }}
                                >
                                    {ReactHtmlParser(`${item.name}`)}
                                </div>
                            </div>
                        ) : (
                            /*horizontal channel focus info*/
                            <div
                                className="channelFocusLeyend_horizontal ml-1 "
                                style={{ height: "auto", fontSize: figmapx2vw(32) }}
                            >
                                <div
                                    id={`${item.groupId}-${item.type}-${item.id}-name`}
                                    className={"float-left"}
                                    style={{
                                        width: "100%",
                                        height: figmapx2vh(75),
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        webkitLineClamp: 2,
                                        webkitBoxOrient: "vertical",
                                    }}
                                >
                                    {ReactHtmlParser(`${item.name}`)}
                                </div>

                                <div
                                    id={`${item.groupId}-${item.type}-${item.id}-year-length`}
                                    style={{
                                        fontSize: figmapx2vw(24),
                                        marginTop: "10vh",
                                    }}
                                >
                                    {`${item.year ? `${item.year} -` : ""} ${printMovieLength(item.length)}`}
                                </div>
                                <div id={`${item.groupId}-${item.type}-${item.id}-price`} style={{ marginTop: "6vh" }}>
                                    {!item.free2guest && !item.rented && printPrice(item.priceToShow, CURRENCY_CODE)}
                                </div>
                            </div>
                        )}
                    </>
                ),
            }}
        ></Button>
    );
}

export default Movie;
