import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import ReactHtmlParser from "react-html-parser";
import { showLoading } from "../../actions/uiActions";
import { STB } from "../../hooks/apis/stb";

const Reload = () => {
    const texts = useSelector((state) => state.ui.texts);

    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(function () {
            dispatch(showLoading(true));
            STB.reload();
        }, 5000);
    }, []);

    return <div className="text-center">{ReactHtmlParser(Texts.capitalize(texts["reload-info"]))}</div>;
};

export default Reload;
