import { vendureQuery } from "../../../hooks/query";
import { getEnvVar } from "../../../utils/utils";

type QueryResult = {
    "data": {
        "collections": {
            "items": {
                "id": string,
                "breadcrumbs": {name: string, id: string}[],
                "translations": {name: string, description: string, languageCode: string}[],
                "name": string,
                "slug": string,
                customFields: {
                    image_url?: string
                    enabled: boolean
                },
                "featuredAsset": {
                    source: string
                }
            }[]
        }
    }
}

export default (channel: string): Promise<QueryResult["data"]["collections"]["items"]>=>new Promise((resolve, reject)=>vendureQuery({
    apiType: "shop",
    cache: true,
    headers: {'vendure-token': channel},
    body: `{
  collections {
    items {
      id,
      breadcrumbs{
        name
        id
      }
      translations{
        name,
        description,
        languageCode
       }
       name,
       slug,
       customFields{
        image_url
        enabled
       }
       featuredAsset {
         source
      }
    }
  }
}`,
    onResponse: (x: QueryResult)=>resolve((x.data.collections.items).filter((x)=>x.breadcrumbs.length === 2).filter(x=> x.customFields.enabled).map(x => ({...x, customFields: {...x.customFields, image_url: x.customFields?.image_url?.startsWith?.('/') ? `${getEnvVar('VENDURE')}${x.customFields.image_url?.slice(1)}` : x.customFields?.image_url}}))),
    onError: e=>reject(e)
}))