import { useRef } from "preact/hooks";
import { useEffect, type FC } from "react";
import type { JSX } from "preact";
import { CSSProperties } from "preact/compat";
import scrollPolyfill from "scroll-polyfill";
import focus from "../../utils/focus";
import { makeKeyboardEvent } from "../../utils/events";

const isOutOfViewport = (elem: Element, direction: string): boolean => {
    const bounding = elem.getBoundingClientRect();
    const parentbounding = elem?.parentElement?.parentElement?.getBoundingClientRect();
    if (direction === "down") {
        return bounding.bottom > (parentbounding?.bottom || document.documentElement.clientHeight) || bounding.top < 0;
    } else if (direction === "up") {
        return bounding.top < (parentbounding?.top || 0) || bounding.top < 0;
    }
    return false;
};
const hightOfElement = (elm: Element) => {
    let elmHeight, elmMargin;
    if (elm && document.all) {
        // IE
        // @ts-ignore
        elmHeight = elm?.currentStyle?.height;
        // @ts-ignore
        elmMargin = parseInt(elm?.currentStyle.marginTop, 10) + parseInt(elm?.currentStyle.marginBottom, 10);
    } else if (elm) {
        // Mozilla
        elmHeight = elm.getBoundingClientRect().height;
        elmMargin =
            parseInt(document?.defaultView?.getComputedStyle(elm, "")?.getPropertyValue("margin-top") || "0") +
            parseInt(document?.defaultView?.getComputedStyle(elm, "")?.getPropertyValue("margin-bottom") || "0");
    }

    return elmHeight + elmMargin;
};

const VerticalList: FC<{
    noDataElement?: JSX.Element;
    id?: string;
    style?: CSSProperties;
    itemsIds: Array<string>;
}> = ({ noDataElement, children, id = "list", style = {}, itemsIds = [] }) => {
    scrollPolyfill({ force: true });
    const listEl = useRef<HTMLDivElement>(null);

    const isListExhaustedBottom = (): boolean => {
        if (itemsIds.length > 0) {
            return (
                itemsIds.indexOf(document.activeElement?.id || "no-item") > -1 &&
                itemsIds?.[itemsIds.indexOf(document.activeElement?.id || "no-item") + 1] === undefined
            );
        } else {
            return (
                (Array.isArray(children) && children.length === 0) ||
                document.activeElement === listEl.current!.lastChild
            );
        }
    };
    const isListExhaustedTop = (): boolean => {
        if (itemsIds.length > 0) {
            return (
                itemsIds.indexOf(document.activeElement?.id || "no-item") > -1 &&
                itemsIds.indexOf(document.activeElement?.id || "no-item") === 0
            );
        } else {
            return (
                (Array.isArray(children) && children.length === 0) ||
                document.activeElement === listEl.current!.firstChild
            );
        }
    };
    const getNextElementId = (itemFrom: string = "no-item"): string | null => {
        if (itemsIds.length > 0) {
            const _actual = itemsIds.indexOf(itemFrom);
            if (
                _actual > -1 &&
                typeof itemsIds[_actual + 1] !== "undefined" &&
                document.getElementById(itemsIds[_actual + 1] || "no-item")
            ) {
                return itemsIds[_actual + 1] as string;
            } else {
                return "submit-button";
            }
        } else if (document!.activeElement!.nextElementSibling) {
            return document!.activeElement!.nextElementSibling!.getAttribute("id") as string;
        }
        return null;
    };
    const getXXNextElement = (itemNext: string) => {
        if (itemsIds.length > 0) {
            const _actualNext = itemsIds.indexOf(itemNext || "no-item");
            if (
                _actualNext > -1 &&
                typeof itemsIds[_actualNext + 1] !== "undefined" &&
                document.getElementById(itemsIds[_actualNext + 1] || "no-item")
            ) {
                return document.getElementById(itemsIds[_actualNext + 1] as string);
            } else if (_actualNext + 1 < itemsIds.length) {
                getXXNextElement(itemsIds[_actualNext + 1] || "no-item");
            }
        } else if (document!.activeElement!.nextElementSibling) {
            return document!.activeElement!.nextElementSibling!.nextElementSibling;
        }
        return null;
    };

    const getPreviousElementId = () => {
        if (itemsIds.length > 0) {
            const _actual = itemsIds.indexOf(document.activeElement?.id || "no-item");
            if (_actual > -1) {
                return itemsIds[_actual - 1];
            }
        } else if (document!.activeElement!.previousElementSibling) {
            return document!.activeElement!.previousElementSibling!.getAttribute("id") as string;
        }
        return null;
    };
    const getXXPreviousElement = () => {
        if (itemsIds.length > 0) {
            const _actual = itemsIds.indexOf(document.activeElement?.id || "no-item");
            if (_actual > -1 && typeof itemsIds[_actual - 2] !== "undefined") {
                return document.getElementById(itemsIds[_actual - 2] as string);
            }
        } else if (document!.activeElement!.previousElementSibling) {
            return document!.activeElement!.previousElementSibling!.previousElementSibling;
        }
        return null;
    };

    const innerHandler = makeKeyboardEvent(["up", "down", "enter"], (key) => {
        if (key === "down") {
            const nextElementId = getNextElementId(document.activeElement?.id);
            let xxNextElement = null;
            if (nextElementId) {
                xxNextElement = getXXNextElement(nextElementId);
            }

            if (!xxNextElement || isListExhaustedBottom()) {
                listEl.current!.parentElement?.scrollTo({ top: listEl.current!.scrollHeight, behavior: "smooth" });
            } else if (xxNextElement && isOutOfViewport(xxNextElement, key)) {
                listEl.current!.parentElement?.scrollBy({ top: hightOfElement(xxNextElement), behavior: "smooth" });
            }

            if (nextElementId) {
                focus.value.replace(nextElementId);
            } else if (isListExhaustedBottom()) {
                return;
            }
            return { stopPropagation: true, preventDefault: true };
        } else if (key === "up") {
            const previuosElementId = getPreviousElementId();
            const xxPreviousElement = getXXPreviousElement();

            if (!xxPreviousElement || isListExhaustedTop()) {
                listEl.current!.parentElement?.scrollTo({ top: 0, behavior: "smooth" });
            } else if (xxPreviousElement && isOutOfViewport(xxPreviousElement, key)) {
                listEl.current!.parentElement?.scrollBy({
                    top: -hightOfElement(xxPreviousElement),
                    behavior: "smooth",
                });
            }
            if (previuosElementId) {
                focus.value.replace(previuosElementId);
            } else if (isListExhaustedTop()) {
                return;
            }
            return { stopPropagation: true, preventDefault: true };
        } else if (key === "enter") {
            //@ts-ignore
            document.activeElement!.click();
            //@ts-ignore
            return { stopPropagation: true };
        }
    });
    useEffect(() => {
        if (listEl.current) {
            listEl.current.addEventListener("keydown", innerHandler);
            listEl.current.scrollTo({ top: 0 });
        }
        return () => {
            listEl.current && listEl.current.removeEventListener("keydown", innerHandler);
        };
    }, [listEl]);

    return !Array.isArray(children) || children.length === 0 ? (
        noDataElement ?? null
    ) : (
        <div style={{ ...style, scrollBehavior: "smooth" }} id={id} ref={listEl}>
            {children}
        </div>
    );
};

export default VerticalList;
