import { vendureQuery } from "../../../hooks/query";

type QueryResult = {data: {extras:{
    id: number,
    available: boolean,
    options: {
        max: number,
        min: number,
        priceWithoutTax: number,
        choices: {
            id: string,
            available: boolean,
            translations: {name: string, languageCode: string}[],
            priceWithoutTax: number,
            order: number
        }[]
    },
    translations: {name: string, language: string}[]
}[]}}

export default (productId: number, channelToken: string): Promise<QueryResult["data"]["extras"]>=>new Promise((resolve, reject)=>vendureQuery({apiType: "shop", headers: {'vendure-token': channelToken}, body: `
    {
  extras(productId: ${productId}){
    id
    available
    options{
      max
      priceWithoutTax
      priceWithTax
      choices{
        id
        available
        translations{
          name
          languageCode
        }
        priceWithoutTax
        priceWithTax
        order
      }
      min
    }
    translations{
      name
      languageCode
    }
  }
}`,
    onResponse: (x: QueryResult)=>{
        resolve(x.data.extras);
    },
    onError: (e)=>reject(e)
}));