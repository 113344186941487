import React, { useLayoutEffect } from "react";
import Limbo from "../screen/limbo";
import focus from "../../utils/focus";

const InputNotAvailable = ({ title }) => {
    const width = "100%";
    const height = "100%";
    useLayoutEffect(() => {
        focus.value.replace("inputNotAvailableLimbo");
    }, []);

    return (
        <div
            className={`fixed  z-900 top-0 left-0`}
            style={{
                width: `${document.body.offsetWidth || window.innerWidth}px`,
                height: `${document.body.offsetHeight || window.innerHeight}px`,
                visibility: "visible",
            }}
        >
            <Limbo id={"inputNotAvailableLimbo"} />
            <div id="pop-up-zone" className="table h-full mx-auto" style={{ width: width || "32rem" }}>
                <div
                    className={"white "}
                    style={{
                        marginTop: "30vh",
                        position: "relative",
                        height: "70vh",
                        background:
                            "linear-gradient(0deg, rgba(55, 61, 66, 0.90) 45.02%, rgba(55, 61, 66, 0.71) 66.74%, rgba(55, 61, 66, 0.08) 90.9%, rgba(55, 61, 66, 0.00) 99.3%)",
                    }}
                >
                    <div class={"table h-full w-full mx-auto"}>
                        <div className="table-cell vertical-middle">
                            <div className={"text-center pb-8 text-4xl"} style={{ marginTop: "10vh" }}>
                                {title}
                            </div>
                            <div class="mx-auto table pt-2" style="width: fit-content">
                                <div className={"numberRow"}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputNotAvailable;
