import { useDispatch, useSelector } from "react-redux";
import {
    connectionError,
    setAppInitialized,
    setServerOnline,
    setLibrariesReady,
    updateStatus,
    updateStatusViaMQTT,
    updatePowerState,
} from "../actions/statusActions";
import IdentifyDevice from "./GraphqlCalls/Identification/identifyDevice";
import InitializeAPI from "./apis/initializeAPI";
import KeyControl from "./keycontrol";
import { useEffect } from "preact/hooks";
import { displayErrorPopUp, navigateTo, setPopUp, showLoading } from "../actions/uiActions";
import { setMediaEvent } from "../actions/eventsActions";
import { Logger } from "../utils/logger";
import { Media } from "./apis/media";
import { PATHS, HOME, CHECK_ONLINE_PATH, POWER } from "../utils/constants";
import { EVENTS } from "../utils/eventsConst";
import { includeLibraries } from "../utils/utils";
import Query from "./query";
import { STB } from "./apis/stb";

const InitializeApp = () => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);
    const initialized = useSelector((state) => state.status.initialized);
    const sessionData = useSelector((state) => state.status.sessionData);

    const onLibrariesLoaded = () => {
        dispatch(setLibrariesReady(true));
    };
    const onLibrariesError = () => {
        dispatch(connectionError());
    };

    useEffect(() => {
        addPolyfills();
        includeLibraries(onLibrariesLoaded, onLibrariesError);
        document.addEventListener(EVENTS.type.CONNECTION, dispatchConnectionEvent, false);
        document.addEventListener(EVENTS.type.GLOBAL, dispatchGlobalEvent, false);
        document.addEventListener(EVENTS.type.NAVIGATION, dispatchNavigationEvent, false);
        document.addEventListener(EVENTS.type.POPUP, dispatchPopupEvent, false);
        document.addEventListener(EVENTS.type.POWER, dispatchPowerEvent, false);
    }, []);

    //keycontrol must be out of if clause, otherwise in KeyControl, store data focusedBtnId is always null
    KeyControl();

    if (!initialized) {
        InitializeAPI();
        IdentifyDevice();
        dispatch(setAppInitialized());

        Logger.init();

        //clean session data
        if (typeof sessionStorage !== "undefined") {
            sessionStorage.removeItem("channels");
        }
    }

    const dispatchMediaEvent = (e) => {
        if (e.detail) {
            switch (e.detail) {
                case EVENTS.MEDIA.STATUS_END_OF_STREAM:
                    Media.playingMedia = false;
                    break;
                case EVENTS.MEDIA.REPLAY:
                    Media.replayLastVideo();
                    break;

                default:
                    break;
            }
            dispatch(setMediaEvent(e.detail));
        }
    };

    const swapToHA = () => {
        dispatch(showLoading(false));
        dispatch(setServerOnline(false));
    };

    const dispatchConnectionEvent = (e) => {
        if (e.detail) {
            switch (e.detail) {
                case EVENTS.CONNECTION.ERROR:
                    var checkServerImage = document.createElement("img");
                    checkServerImage.onload = function () {
                        // check status of tv-server
                        Query({
                            query: `
                                mutation{identifyDevice (deviceRef:"${STB.ref}")
                                    {
                                        error { code msg }
                                        ok
                                        token
                                        stayToken
                                    }
                                }
                          `,
                            onResponse(res) {
                                if (res?.data?.identifyDevice?.ok) {
                                    // tv-server is ON must be query error
                                    dispatch(setServerOnline(true));
                                    dispatch(showLoading(false));
                                    dispatch(connectionError());
                                } else {
                                    console.log("check identifyDevice responded but failed");
                                    swapToHA();
                                }
                            },
                            onError() {
                                console.log("check identifyDevice failed");
                                swapToHA();
                            },
                            cache: false,
                            propagateError: false,
                        });
                    };
                    checkServerImage.onerror = function () {
                        // Drive not online
                        swapToHA();
                    };
                    checkServerImage.src = CHECK_ONLINE_PATH + "?t=" + new Date().getTime();
                    break;
                case EVENTS.CONNECTION.TIMEOUT:
                    swapToHA();
                    break;
                default:
                    break;
            }
        }
    };

    const dispatchNavigationEvent = (e) => {
        if (e.detail) {
            switch (e.detail.layout) {
                case HOME:
                    dispatch(
                        navigateTo({
                            layout: PATHS.HOME,
                        }),
                    );
                    break;
                default:
                    break;
            }
        }
    };

    const dispatchGlobalEvent = (e) => {
        if (e.detail) {
            switch (e.detail) {
                case EVENTS.GLOBAL.CHECK_SERVER_INFO:
                    dispatch(updateStatus());
                    break;
                case EVENTS.GLOBAL.CHECK_STATUS_VIA_MQTT:
                    dispatch(updateStatusViaMQTT());
                    break;
                default:
                    break;
            }
        }
    };

    const dispatchPowerEvent = (e) => {
        if (e.detail) {
            switch (e.detail) {
                case EVENTS.POWER.OFF:
                    dispatch(updatePowerState(POWER.OFF));
                    break;
                case EVENTS.POWER.ON:
                    dispatch(updatePowerState(POWER.ON));
                    break;
                default:
                    break;
            }
        }
    };

    const dispatchPopupEvent = (e) => {
        if (e.detail) {
            switch (e.detail.type) {
                case EVENTS.POPUP.ZAFIRO:
                    dispatch(setPopUp("zafiroMenu"));
                    break;
                case EVENTS.POPUP.NOT_AVAILABLE:
                    dispatch(displayErrorPopUp({ text: texts["not-available"], timeout: 3000 }));
                    break;
                case EVENTS.POPUP.CUSTOM_TEXT:
                    dispatch(displayErrorPopUp({ text: e.detail.text, timeout: 3000 }));
                    break;
                default:
                    break;
            }
        }
    };

    const addPolyfills = () => {
        if (!Object.values) Object.values = (o) => Object.keys(o).map((k) => o[k]);
        if (!Object.entries)
            Object.entries = function (obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
    };

    //Actions on App initialized
    useEffect(() => {
        if (sessionData && sessionData.stayguest) {
            //Add event listener
            document.addEventListener(EVENTS.type.MEDIA, dispatchMediaEvent, false);
            document.addEventListener(EVENTS.type.NAVIGATION, dispatchNavigationEvent, false);

            if (localStorage.getItem("channel_to_restore")) {
                setTimeout(() => {
                    dispatch(
                        navigateTo({
                            layout: PATHS.TV,
                        }),
                    );
                }, 2000);
            }
            if (localStorage.getItem("reset_credentials_success")) {
                localStorage.removeItem("reset_credentials_success");
                setTimeout(() => {
                    dispatch(setPopUp("resetCredentials-success"));
                }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionData && sessionData.stayguest]);
};

export default InitializeApp;
