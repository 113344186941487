/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "preact/hooks";
import { STB as STB_base } from "../../stb";
import { STB } from "./stb";
import { Media as Media_base } from "../../media";
import { Media } from "./media";

export const InitializeOrsay = () => {
    useEffect(() => {
        Object.keys(STB).forEach((key) => {
            STB_base[key] = STB[key];
        });
        //Extend base media.js
        Object.keys(Media).forEach((key) => {
            Media_base[key] = Media[key];
        });

        STB_base.init();
        Media_base.init();
    }, []);
};
