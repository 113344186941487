import type { Extra } from "sales-layout-library/tv/src/types";
import { match } from "ts-pattern";
import { MulticomboboxExtra } from "sales-layout-library/src/zod/extras";

type ExtraTypes = 'normal' | 'numeric' | 'combobox' | 'multicombobox';

type Config<T extends ExtraTypes> = T extends 'normal'
    ? true
    : T extends 'numeric'
    ? number //Cantidad
    : T extends 'combobox'
    ? string[] //ChoiceId[]
    : T extends 'multicombobox'
    ? Record<string, number> //ChoiceId->amount
    : never;

const validator = (extra: Extra, config: Config<ExtraTypes>): boolean => match(extra)
    .with({ type: 'normal' }, () => true)
    .with({ type: 'numeric' }, () => {
        //@ts-ignore
        const min = extra.options?.min ?? -Infinity;
        //@ts-ignore
        const max = extra.options?.max > 0 ? extra.options?.max : Infinity;
        if (typeof config !== "number")
            return false;
        return (config > min && config < max);
    })
    .with({ type: 'combobox' }, () => {
        if (!Array.isArray(config) || !config.every(x => typeof x === 'string'))
            return false;
        //@ts-ignore
        return config.every(x => extra.options.choices.some((y: { id: string }) => y.id === x));
    })
    .with({ type: 'multicombobox' }, (extra: MulticomboboxExtra) => {
        const choicesIds = Object.keys(config);
        const possibleIds = extra.options.choices.map(x => x.id);
        return choicesIds.every(x => possibleIds.includes(x));
        // TODO comprobar también que la cantidad de cada choice está entre min y max
    })
    .otherwise(() => false);

export default class ExtraConfigurator {
    constructor(private extras: any = {}) { }
    public addExtra<T extends ExtraTypes>(extra: Extra, config: Config<T>) {
        const isValid = validator(extra, config);
        if (!isValid)
            throw new Error("Invalid extra configuration");
        this.extras[extra.id] = config;
    }
    public isValidConfig<T extends ExtraTypes>(extra: Extra, config: Config<T>) {
        return validator(extra, config);
    }
    public removeExtra(id: Extra["id"]) {
        delete this.extras[id];
    }
    public toJson() {
        return this.extras;
    }
    public fromJson(json: Record<string, Config<any>>) {
        this.extras = json;
    }
}