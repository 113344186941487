import { PERMISSION, STORAGE_KEY } from "../../utils/constants";
import { EVENTS } from "../../utils/eventsConst";
import { Logger } from "../../utils/logger";
import { SESSION } from "../../utils/session";
import { Media } from "./media";

export const STB = {
    alarmVolume: 16,
    supportBlur: true,
    supportHA: true,
    supportBluetooth: true,
    supportAnimations: true,
    builtInCC: false,
    forceMovieLanguage: false,
    model: "",
    ref: null,
    init() {
        console.log("Die template");
    },
    getMac(onSuccess) {
        console.log("Die template");
        if (onSuccess) {
            onSuccess();
        }
    },
    renewToken(newToken) {
        console.log("Die template");
    },
    reload() {
        window.location = window.location.origin + window.location.search;
    },
    powerOn() {
        console.log("Power ON");
    },
    powerOff() {
        console.log("Power OFF");
    },
    updatePowerState() {
        console.log("Check power state");
    },
    powerKey() {
        console.log("Power key press");
    },
    gotoOfflineMode() {
        Logger.remoteLog("Load offline mode");
        this._gotoOfflineMode();
    },
    _gotoOfflineMode() {
        console.log("Die template");
    },
    setVolume(volume) {
        console.log(`Set volume to ${volume}`);
    },
    changeToInput(input) {
        console.log(`Change to input ${input}`);
    },
    backToInterface() {
        console.log("Load interface");
    },
    ensureZafiroInterface() {
        if (sessionStorage.getItem("outOfInterface") === "true") {
            sessionStorage.removeItem("outOfInterface");
            this.backToInterface();
        }
    },
    checkout() {
        localStorage.removeItem("lastStayGuestId");
        this._checkout();
    },
    _checkout() {
        console.log("Checkout actions");
    },
    launchApp(appName) {
        if (SESSION.hasPermission(PERMISSION.APPS)) {
            Media.stop();
            this._launchApp(appName);
        }
    },
    _launchApp(appName) {
        console.log(`Launch app ${appName}`);
    },
    pong() {
        console.log("Still alive");
    },
    checkServerInfo() {
        let e = new CustomEvent(EVENTS.type.GLOBAL, {
            detail: EVENTS.GLOBAL.CHECK_SERVER_INFO,
        });
        document.dispatchEvent(e);
    },
    checkServerInfoViaMQTT() {
        let e = new CustomEvent(EVENTS.type.GLOBAL, {
            detail: EVENTS.GLOBAL.CHECK_STATUS_VIA_MQTT,
        });
        document.dispatchEvent(e);
    },
    getDeviceStatus() {
        console.log("Die template");
    },
    sendCurrentLayoutInfo(cLayout) {
        console.log("Current layout: " + cLayout);
    },
    onKeyReceived(e, simulateKeyPress) {
        console.log("Die template");
    },
    saveDataForHA(localKey, dataToStore) {
        console.log("Die template");
    },
    checkIn() {
        console.log("Die template");
    },
    ensurePMSSession(currentGuestId) {
        console.log("Die template");
    },
    changeTVName(name) {
        console.log("Die template");
    },
    checkOnLine() {
        console.log("Die template");
    },
    launchUSB() {
        console.log("Die template");
    },
    launchBluetooth() {
        console.log("Die template");
    },
    initializeCastServices(castingGatewayIP, airserverAccountCode) {
        console.log("Die template");
    },
    resetCredentials() {
        console.log("Die template");
    },
    _resetCredentialsOnPowerOff() {
        console.log("Die template");
    },
    showCC() {
        console.log("Die template");
    },
};
