import { promisedVendureQuery, vendureQuery } from "../../../hooks/query";
import storage from "../../../utils/storage";
import memize from "memize";
import { getEnvVar } from "../../../utils/utils";

type ExtrasResponse = {
    data: {
        extras: {
            id: string;
            available: boolean;
            type: string;
            options: {
                max: number;
                priceWithoutTax: number;
                priceWithTax: number;
                choices: any;
                min: number;
            };
            translations: {
                name: string;
                languageCode: string;
            }[];
        }[];
    };
};

const extrasOf = async (productId: number | string, channelToken: string) => {
    const languageCode = storage.get("lang", "en");
    const extras: ExtrasResponse = await promisedVendureQuery({
        apiType: "shop",
        cache: true,
        headers: {
            "vendure-token": channelToken,
        },
        body: `
    {
      extras(productId: ${productId}){
        id
        type
        available
        options{
          max
          priceWithoutTax
          priceWithTax
          choices{
            id
            available
            translations{
              name
              languageCode
            }
            priceWithoutTax
            priceWithTax
            order
          }
          min
        }
        translations{
          name
          languageCode
        }
      }
    }`,
    });
    const result: (ExtrasResponse["data"]["extras"][number] & { name: string })[] = extras.data.extras
        .filter((x) => x.available)
        .map((x) => ({
            ...x,
            name:
                x.translations.find((x) => x.languageCode === languageCode)?.name ||
                x.translations.find((x) => x.languageCode === "en")?.name ||
                x.translations?.[0]?.name ||
                "Unnamed extra",
        }));
    return result;
};

type ReturnType = {
    id: number;
    name: string;
    description: string;
    tags: string[];
    productId: number;
    assets: string[];
    extras: (ExtrasResponse["data"]["extras"][number] & { name: string })[];
    featuredAsset?: string;
    price: {
        currencyCode: string;
        withTax: number;
        withoutTax: number;
    };
    quantity: number;
    timestamp: string;
    allergens: { code: keyof Sales.Allergens; text: string }[];
    translations: {
        languageCode: string;
        name: string;
        description: string;
    }[];
    maxUnits: number;
};
export default memize(
    (variantId: number | string, channelToken: string, quantity: number, timestamp: string): Promise<ReturnType> =>
        new Promise((resolve, reject) =>
            vendureQuery({
                apiType: "admin",
                body: `
    {
    productVariant(id: ${variantId}){
    id
    product {
      translations{
        description,
        languageCode,
        name
      }
      facetValues{
      code
      translations{
        languageCode
        name
      }
      facet{
        id
        code
        translations {
          languageCode
          name
        }
      }
    }
      assets {
        source
      }
      featuredAsset{
        source
      }
    }
    price
    priceWithTax
    productId
    currencyCode
    assets{
      source
    }
    featuredAsset{
      source
    }
    facetValues{
      translations{
        languageCode
        name
      }
      facet{
        id
        translations {
          languageCode
          name
        }
      }
    }
    customFields{
        max_units_per_order
        max_units_per_order_enabled
    }
  }}`,
                headers: {
                    "vendure-token": channelToken,
                },
                onResponse: async (x: { data: { productVariant: Sales.Variant } }) => {
                    const extras = await extrasOf(x.data.productVariant.productId, channelToken);
                    const languageCode = localStorage.getItem("lang") || "en";
                    const featuredAsset =
                        x.data.productVariant.featuredAsset?.source ||
                        x.data.productVariant.product?.featuredAsset?.source ||
                        x.data.productVariant.assets?.[0]?.source ||
                        x.data.productVariant.product.assets?.[0]?.source;
                    const variant: ReturnType = {
                        allergens: x.data.productVariant.product.facetValues
                            .filter((x) => x.facet.code.startsWith("zafiro_allergens"))
                            .map((x) => {
                                return {
                                    code: x.code as keyof Sales.Allergens,
                                    text:
                                        x.translations.find((x) => x.languageCode === languageCode && x.name != "")
                                            ?.name ??
                                        x.translations.find((x) => x.languageCode === "en")?.name ??
                                        x.translations[0]!.name,
                                };
                            }),
                        id: x.data.productVariant.id,
                        productId: x.data.productVariant.productId,
                        price: {
                            currencyCode: x.data.productVariant.currencyCode,
                            withTax: x.data.productVariant.priceWithTax,
                            withoutTax: x.data.productVariant.price,
                        },
                        extras,
                        quantity,
                        timestamp,
                        featuredAsset: featuredAsset ? `${getEnvVar("VENDURE")}${featuredAsset}` : undefined,
                        assets:
                            x.data.productVariant.product.assets?.map((x) => `${getEnvVar("VENDURE")}${x.source}`) ||
                            [],
                        tags: x.data.productVariant.product.facetValues
                            .filter((x) => x.facet.code.startsWith("zafiro_labels"))
                            .map(
                                (x) =>
                                    x.translations.find((x) => x.languageCode === languageCode && x.name != "")?.name ??
                                    x.translations.find((x) => x.languageCode === "en")?.name ??
                                    x.translations[0]!.name,
                            ),
                        name:
                            x.data.productVariant.product.translations.find(
                                (x) => x.languageCode.toLowerCase() === languageCode,
                            )?.name ??
                            x.data.productVariant.product.translations[0]?.name ??
                            "Unnamed product",
                        translations: x.data.productVariant.product.translations,
                        description:
                            x.data.productVariant.product.translations.find(
                                (x) => x.languageCode.toLowerCase() === languageCode,
                            )?.description ??
                            x.data.productVariant.product.translations[0]?.description ??
                            "",
                        maxUnits: x.data.productVariant.customFields?.max_units_per_order_enabled
                            ? x.data.productVariant.customFields?.max_units_per_order || 99
                            : 99,
                    };
                    resolve(variant);
                },
                onError: (e) => reject(e),
            }),
        ),
);
