import { useSelector } from "react-redux";
import { cloneObject, figmapx2vh, figmapx2vw, getEnvVar } from "../../utils/utils";
import Button from "../../components/common/button";
import { useEffect, useRef, useState } from "react";
import focus from "../../utils/focus";
import { CHANNELS } from "../../utils/constants";
import ChannelGroup from "./channelGroup";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { Texts } from "../../utils/texts";
import { SESSION } from "../../utils/session";
import { STB } from "../../hooks/apis/stb";

const TvGroups = ({
    groups,
    setGroups,
    filter,
    setFilter,
    playingChannel,
    setPlayingChannel,
    tvState,
    setTvState,
    GROUP_FILTERS,
    showContinue,
}) => {
    const texts = useSelector((state) => state.ui.texts);
    const stateFavouriteChannelIDs = useSelector((state) => state.status.locationData.favouriteChannelIDs);
    const [favouriteChannelIDs, setFavouriteChannelIDs] = useState(stateFavouriteChannelIDs);

    let hasChannels = false;
    const [verticalSteps, setVerticalSteps] = useState(0);
    const [FGVerticalSteps, setFGVerticalSteps] = useState(0);
    const [fullGroup, showFullGroup] = useState(0);

    const singleGroup = groups?.find((group) => group.id === filter)?.items?.length === 1;
    const numGroups = groups?.find((group) => group.id === filter)?.items?.length;
    let groupPos = 0;

    let focusOnFGClose = useRef();

    useEffect(() => {
        if (SESSION.isTVOnCheckout()) {
            const favouriteIds = localStorage.getItem("favOnCheckout");
            console.log(JSON.parse(favouriteIds));
            setFavouriteChannelIDs(JSON.parse(favouriteIds));
        }
    }, [stateFavouriteChannelIDs]);

    useEffect(() => {
        let firstChannel = groups.find((group) => group.id === filter)?.items?.[0].items?.[0];
        if (!playingChannel && firstChannel) {
            //RESTORE CHANNEL
            if (localStorage.getItem("channel_to_restore")) {
                const chToRestore = groups
                    .find((group) => group.id === filter)
                    ?.items?.[0].items?.find((ch) => ch.id === parseInt(localStorage.getItem("channel_to_restore")));
                if (chToRestore) {
                    firstChannel = chToRestore;
                    setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
                }
            }

            setPlayingChannel(firstChannel);
        }
        if (showContinue) {
            document.querySelector("#btn-channel_1") && focus.value.replace("btn-channel_1");
        } else if (firstChannel && document.getElementById(`btn-channel_${firstChannel.num}`)) {
            focus.value.replace(`btn-channel_${firstChannel.num}`);
        } else if (filter !== "none") {
            focus.value.replace("btn-all");
        }
        setVerticalSteps(0);
    }, [filter]);

    useEffect(() => {
        ensureVerticalScroll();
    }, [verticalSteps, FGVerticalSteps]);

    //compose my-list group
    useEffect(() => {
        const favGroup = groups.find((group) => group.id === CHANNELS.FILTERS.MY_LIST);
        const tmpGroups = cloneObject(groups);

        let favChannels = [];
        groups
            .find((group) => group.id === CHANNELS.FILTERS.ALL_CHANNELS)
            ?.items.forEach((channel) => {
                favouriteChannelIDs?.includes(channel.id) && favChannels.push(channel);
            });
        favChannels.forEach((ch) => (ch.category = CHANNELS.FILTERS.MY_LIST));
        const newMyListData = {
            id: CHANNELS.FILTERS.MY_LIST,
            name: texts["my list"],
            items: [{ id: 1, name: texts["my list"], items: favChannels }],
        };
        favGroup ? (tmpGroups[0] = newMyListData) : tmpGroups.unshift(newMyListData);
        setGroups(tmpGroups);
    }, [favouriteChannelIDs]);

    const onFilterkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    if (showContinue) {
                        playingChannel?.category && setFilter(playingChannel.category);
                        setTvState(CHANNELS.TV_STATES.CHANNEL_INFO);
                        preventDefault = true;
                    }
                    break;
                case KEYS.left:
                    if (focus.value.current === "btn-all") {
                        preventDefault = true;
                    }
                    break;
                case KEYS.right:
                    if (!document.activeElement?.parentElement?.nextSibling) {
                        preventDefault = true;
                    }
                    break;
                case KEYS.down:
                    if (showContinue) {
                        focus.value.replace("btn-continue");
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    useEffect(() => {
        ensureVerticalScroll();
        if (!fullGroup && focusOnFGClose.current) {
            focus.value.replace(focusOnFGClose.current);
            setFGVerticalSteps(0);
        } else if (fullGroup) {
            focus.value.replace(focus.value.current);
        }
    }, [fullGroup]);

    const ensureVerticalScroll = () => {
        const channelListDiv = document.getElementById("channelList");
        if (channelListDiv) {
            if (fullGroup) {
                channelListDiv.style.marginTop = `-${
                    (FGVerticalSteps > 0 ? FGVerticalSteps : 0) * (singleGroup ? 21.5 : 26)
                }vh`;
            } else {
                channelListDiv.style.marginTop = `-${
                    (verticalSteps > 0 ? verticalSteps : 0) * (singleGroup ? 21.5 : 26)
                }vh`;
            }
        }
    };

    const fullGroupData = groups?.find((group) => group.id === filter)?.items?.find((group) => group.id === fullGroup);

    return (
        <div id="channelList" className={`${STB.supportAnimations && "animateMarginTop"}`}>
            {
                //FILTERS Section
            }
            <div
                className="filters"
                style={{
                    height: `${figmapx2vh(200)}`,
                    fontSize: `${figmapx2vw(36)}`,
                    display: fullGroup ? "none" : "",
                    width: "100vw",
                }}
            >
                <div
                    className="mx-auto table text-center"
                    onKeyDown={(e) => {
                        onFilterkeydown(e);
                    }}
                >
                    {GROUP_FILTERS.map((gFilter) => {
                        if (!gFilter) {
                            return;
                        }
                        return (
                            <div key={gFilter.id} className="float-left">
                                <Button
                                    data={{
                                        id: gFilter.id,
                                        customClass: `filter ${
                                            gFilter.id === CHANNELS.FILTERS.COUNTRY && "countryFilter"
                                        }`,
                                        focusClass: "channelList-focus",
                                        removeButtonClass: true,
                                        name: gFilter.name,
                                        onClick() {
                                            setFilter("none");
                                            setTimeout(function () {
                                                setFilter(gFilter.id);
                                            }, 1);
                                        },
                                    }}
                                />
                                {filter === gFilter.id && focus.value.current !== `btn-${gFilter.id}` ? (
                                    <div className={"border mx-auto"} style={{ width: "31%" }}></div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>

            {fullGroup && filter && fullGroupData ? (
                <Button
                    data={{
                        id: "exit",
                        icon: "tv-back-remote",
                        iconSize: "text-2xl",
                        name: Texts.capitalize(texts["back"]),
                        customClass: `py-2 px-2 w-auto text-2xl mb-8 m-4 rounded`,
                        removeButtonClass: true,
                        focusClass: "channelList-focus",
                        customStyle: { marginLeft: "1vw" },
                        onClick() {
                            showFullGroup(null);
                        },
                    }}
                />
            ) : null}

            {
                //Continue watching
            }
            {showContinue ? (
                <div
                    className="bar"
                    style={{
                        marginBottom: `${figmapx2vh(40)}`,
                        marginLeft: `${figmapx2vw(72)}`,
                        height: `${figmapx2vh(80)}`,
                        width: `94vw`,
                    }}
                >
                    <div
                        id="btn-continue"
                        className={"navigable float-left rounded"}
                        onClick={() => {
                            playingChannel?.category && setFilter(playingChannel.category);
                            setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
                        }}
                        focusclass={"continue-watching-focus"}
                        tabIndex={0}
                        style={{
                            width: `${figmapx2vw(96)}`,
                            height: `${figmapx2vh(80)}`,
                        }}
                    >
                        {playingChannel?.logofile ? (
                            <div
                                className={`bg-center bg-contain bg-no-repeat float-left w-full h-full`}
                                style={{
                                    borderRadius: `${figmapx2vw(4)}`,
                                    backgroundColor: "#252626",
                                    backgroundImage: `url('${
                                        playingChannel.logoURL ||
                                        `${getEnvVar("API")}ext/${CHANNELS.logoPath?.pRef}/gfx_base/contents/tv/${
                                            playingChannel.logofile
                                        }?center_crop=120x120`
                                    }')`,
                                }}
                            />
                        ) : (
                            <div
                                className={`white w-full h-full icon-channels text-center  float-left`}
                                style={{
                                    fontSize: "2.5vw",
                                    borderRadius: `${figmapx2vw(4)}`,
                                    backgroundColor: "#252626",
                                    lineHeight: `${figmapx2vh(80)}`,
                                }}
                            ></div>
                        )}
                    </div>
                    <div
                        className={"float-left nowrap text-ellipsis overflow-hidden"}
                        style={{
                            paddingLeft: `${figmapx2vw(40)}`,
                            lineHeight: `${figmapx2vh(80)}`,
                            fontSize: `${figmapx2vw(32)}`,
                            width: `64%`,
                        }}
                    >
                        {`${texts["Continue watching"]}  ( ${playingChannel?.name} )`}
                    </div>
                </div>
            ) : null}

            {
                //Groups Section
            }
            <div
                style={{
                    paddingLeft: `${figmapx2vw(72)}`,
                }}
            >
                {
                    //full group view
                }
                {fullGroup && filter && fullGroupData ? (
                    <ChannelGroup
                        group={fullGroupData}
                        filter={filter}
                        setPlayingChannel={setPlayingChannel}
                        tvState={tvState}
                        setTvState={setTvState}
                        singleGroup={true}
                        verticalSteps={FGVerticalSteps}
                        setVerticalSteps={setFGVerticalSteps}
                        showContinue={showContinue}
                        fullGroup={fullGroup}
                        showFullGroup={showFullGroup}
                    />
                ) : null}

                {
                    //ALL group view
                }
                <div style={{ display: fullGroup ? "none" : "" }}>
                    {filter &&
                        groups
                            ?.find((group) => group.id === filter)
                            ?.items?.map((group) => {
                                if (!group.items?.length) {
                                    return;
                                }
                                groupPos++;
                                hasChannels = true;
                                return (
                                    <ChannelGroup
                                        group={group}
                                        groupPos={groupPos}
                                        numGroups={numGroups}
                                        filter={filter}
                                        setPlayingChannel={setPlayingChannel}
                                        tvState={tvState}
                                        setTvState={setTvState}
                                        singleGroup={singleGroup}
                                        verticalSteps={verticalSteps}
                                        setVerticalSteps={setVerticalSteps}
                                        showContinue={showContinue}
                                        maxGroupItems={14}
                                        fullGroup={fullGroup}
                                        showFullGroup={showFullGroup}
                                        focusOnFGClose={focusOnFGClose}
                                    />
                                );
                            })}
                </div>
            </div>
            {filter != "none" && !hasChannels && (
                <div
                    className="text-center"
                    style={{
                        height: `${figmapx2vh(124)}`,
                        lineHeight: `${figmapx2vh(124)}`,
                        fontSize: `${figmapx2vw(32)}`,
                    }}
                >
                    {texts["no-channels-yet"].replace(
                        "{{group}}",
                        GROUP_FILTERS.filter((item) => item?.id === filter)?.[0]?.name,
                    )}
                </div>
            )}
        </div>
    );
};

export default TvGroups;
