import { useSelector } from "react-redux";
import VerticalCenter from "../../components/common/verticalCenter";
import Limbo from "../../components/screen/limbo";
import { eventHandler } from "../../utils/eventHandler";
import { Texts } from "../../utils/texts";
import Categories from "./Categories";
import Groups from "./Groups";
import { STB } from "../../hooks/apis/stb";
import backgroundImage from "../../components/reusable/backgroundImage";
import InfoOfFocus from "./InfoOfFocus";
import { useEffect, useLayoutEffect, useState } from "preact/hooks";
import { MOVIES } from "../../utils/constants";
import BackgroundBanner from "./BackgroundBanner";
import Group from "./Group";
import { useRef } from "preact/hooks";
import { focusFirstItemOfCategory } from "../../utils/gridUtils";
import ItemDetail from "./ItemDetail";
import { KEYS } from "../../utils/keys";
import { Media } from "../../hooks/apis/media";
import { parseRentedMovies } from "../../utils/movies";
import { figmapx2vh } from "../../utils/utils";
import focus from "../../utils/focus";

const Grid = ({
    gridData,
    loadingData = false,
    firstFilter,
    itemHeight,
    options = {
        backgroundBanner: false,
        infoOfFocus: false,
        noGradient: false,
        singleGroupItemsPerRow: 8,
        specialButton: false,
    },
}) => {
    const { backgroundBanner, infoOfFocus, noGradient, singleGroupItemsPerRow, specialButton } = options;

    const texts = useSelector((state) => state.ui.texts);
    const isVideoFullScreen = useSelector((state) => state.ui.isVideoFullScreen);

    const [activeCategory, setActiveCategory] = useState(firstFilter);
    const [activeSingleGroup, setActiveSingleGroup] = useState(null);
    const [activeItemDetail, setActiveItemDetail] = useState(null);
    /*forceFocus - uses:
    {
        groupId: group.id,
        elementId: document.activeElement.id,
    }*/
    const forceFocus = useRef(null);
    const forceFocusSingleGroup = useRef(null);
    const forceRefreshCategories = useRef(null);

    useEffect(() => {
        return () => {
            forceFocus.current = null;
        };
    }, []);

    useEffect(() => {
        focusFirstItemOfCategory(gridData.filter((category) => category.id === activeCategory)[0]);
    }, [activeCategory]);

    const onGridKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            if (keyData.type === "numeric") {
                eventHandler.stopPropagation(e);
                return;
            }
            switch (keyData.value) {
                // case KEYS.enter:
                //     break;

                case KEYS.back:
                    if (activeItemDetail) {
                        //BACK FROM ITEM DETAIL
                        setActiveItemDetail(null);
                        preventDefault = true;
                    }

                    break;

                // case KEYS.up:
                //     break;

                // case KEYS.down:
                //     break;

                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const showAllGroup = (_group, btnId) => {
        setActiveSingleGroup(_group);
        focus.value.stack(btnId);
    };

    const showDetailofItem = (item) => {
        if (activeSingleGroup) {
            forceFocusSingleGroup.current = {
                groupId: item.groupId,
                elementId: item.focusId,
            };
        }
        focus.value.stack(item.focusId);
        setActiveItemDetail(item);
    };

    useEffect(() => {
        updateGridItem({ ...activeItemDetail });
    }, [activeItemDetail]);

    useLayoutEffect(() => {
        if (
            !activeItemDetail &&
            !activeSingleGroup &&
            document.getElementById("movie_catalog").style.display === "block"
        ) {
            forceFocusSingleGroup.current = null;
            focus.value.unstack();
        }
    }, [activeItemDetail, activeSingleGroup]);

    const updateGridItem = (itemUpdated, attrbs) => {
        if (!itemUpdated) {
            return;
        }
        if (!attrbs) {
            return;
        }

        gridData.forEach((category) => {
            category.items.forEach((group) => {
                group.items.forEach((item) => {
                    if (item.id === itemUpdated.id) {
                        attrbs.forEach((attribute) => {
                            item[attribute] = itemUpdated[attribute];
                        });
                    }
                });
            });
        });

        // specific in movies grid
        if (attrbs.includes("rented")) {
            let rentedId = [];
            gridData.forEach((category) => {
                category.items.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.rented) {
                            rentedId.push({ extId: item.id, createdAt: item.createdAt });
                        }
                    });
                });
            });
            parseRentedMovies(gridData, rentedId);
            forceRefreshCategories.current = new Date().getTime();
        }
    };

    return (
        <>
            <div
                id="grid"
                style={{
                    visibility: "visible",
                }}
                onKeyDown={(e) => {
                    onGridKeydown(e);
                }}
            >
                <Limbo id="grid-limbo" noBlocker={true} />
                <div
                    className={`channelList w-full block relative ${
                        (STB.isTizen || noGradient) && "channelList_no_gradient"
                    }`}
                    style={noGradient && { background: "none" }}
                >
                    {gridData?.length > 0 ? (
                        <>
                            <div className={"w-full h-full"} style={{ zIndex: 9 }}>
                                {activeItemDetail ? (
                                    <ItemDetail element={activeItemDetail} updateElement={updateGridItem} />
                                ) : activeSingleGroup ? (
                                    <Group
                                        group={activeSingleGroup}
                                        setActiveSingleGroup={showAllGroup}
                                        itemHeight={itemHeight}
                                        itemsPerRow={singleGroupItemsPerRow}
                                        itemDetail={showDetailofItem}
                                        forceFocus={forceFocusSingleGroup.current}
                                    />
                                ) : null}
                                <div
                                    id="movie_catalog"
                                    style={{
                                        position: "absolute",
                                        display: activeItemDetail || activeSingleGroup ? "none" : "block",
                                        width: "100vw",
                                        height: "100vh",
                                    }}
                                >
                                    <Categories
                                        gridData={gridData}
                                        activeCategory={activeCategory}
                                        setActiveCategory={setActiveCategory}
                                        specialButton={specialButton ? () => specialButton() : null}
                                    />
                                    {infoOfFocus &&
                                        gridData.filter((category) => category.id === activeCategory)[0].items.length >
                                            1 && (
                                            <InfoOfFocus
                                                activeCategory={
                                                    gridData.filter((category) => category.id === activeCategory)[0]
                                                }
                                                name={"name"}
                                                description={"plot"}
                                            />
                                        )}
                                    <Groups
                                        category={gridData.filter((category) => category.id === activeCategory)[0]}
                                        itemHeight={itemHeight}
                                        setActiveSingleGroup={showAllGroup}
                                        forceFocus={forceFocus.current}
                                        itemDetail={showDetailofItem}
                                        itemsPerRow={singleGroupItemsPerRow}
                                        forceRefresh={forceRefreshCategories.current}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <VerticalCenter
                            text={Texts.capitalize(
                                loadingData ? `${texts["loading"]}...` : texts["no data available"],
                            ).replace("{{data}}", texts["channels"])}
                            cClass={`white text-4xl`}
                        />
                    )}
                </div>
            </div>

            {backgroundBanner &&
            !activeSingleGroup &&
            !isVideoFullScreen &&
            !activeItemDetail &&
            gridData.filter((category) => category.id === activeCategory)[0].items.length > 1 ? (
                <BackgroundBanner activeCategory={gridData.filter((category) => category.id === activeCategory)[0]} />
            ) : !isVideoFullScreen ? (
                <div
                    id="blackBlackground"
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "absolute",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "black",
                        // background:
                        //     "linear-gradient(360deg, #292929 0%, rgba(41, 41, 41, 0.8) 61%, rgba(41, 41, 41, 0) 100%)",
                    }}
                >
                    <div
                        id="gradientBg"
                        style={{
                            width: "100vw",
                            height: figmapx2vh(910),
                            position: "absolute",
                            top: figmapx2vh(180),
                            zIndex: 2,
                            background:
                                "linear-gradient(360deg, #292929 0%, rgba(41, 41, 41, 0.8) 61%, rgba(41, 41, 41, 0) 100%)",
                        }}
                    ></div>
                </div>
            ) : null}
        </>
    );
};

export default Grid;
