/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setNumpadCode } from "../actions/eventsActions";
import { STB } from "../hooks/apis/stb";
import Query from "../hooks/query";
import Button from "./common/button";
import Calculator from "./common/calculator";
import Modal from "./common/modal";
import VerticalList from "./common/verticalList";
import { printCenteredMessage } from "../utils/utils";
import ReactHtmlParser from "react-html-parser";
import focus from "../utils/focus";
import { DEVICE_FUNCTION, PERMISSION } from "../utils/constants";
import { Texts } from "../utils/texts";
import { SESSION } from "../utils/session";

const Installation = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    //Store data
    const numpadCode = useSelector((state) => state.events.numpadCode);

    //States
    const [step, setStep] = useState(STB.installFromManager ? -1 : 1);
    const [installCode, setInstallCode] = useState(1);
    const [installError, setInstallError] = useState();
    const [roomTVs, setRoomTVs] = useState();
    const [roomTVsData, setRoomTVsData] = useState([]);
    const [deviceFunction, setDeviceFunction] = useState(DEVICE_FUNCTION.TV.id);
    const [roomNumber, setRoomNumber] = useState();

    const DEVICE_FUNCTION_OPTIONS = [
        {
            id: DEVICE_FUNCTION.TV.id,
            innerHTML: `<div class="text-center text-2xl pl-4"><div>${DEVICE_FUNCTION.TV.name}</div>`,
        },
        {
            id: DEVICE_FUNCTION.TVCAST.id,
            innerHTML: `<div class="text-center text-2xl pl-4"><div>${DEVICE_FUNCTION.TVCAST.name}</div>`,
        },
    ];
    ////////////////////////
    //Listeners
    ///////////////////////

    //Init numpadCode to null
    useEffect(() => {
        if (step === -1) {
            focus.value.replace("noFocus");
        }
        dispatch(setNumpadCode(null));
    }, []);

    //remove error
    useEffect(() => {
        dispatch(setNumpadCode(null));
    }, []);

    //If numpadCode => show room selection (step 2)
    //If numpadCode && step2 => call endpoint to install device
    useEffect(() => {
        if (numpadCode && step === 1) {
            setInstallCode(numpadCode);
            Query({
                query: `
                    { 
                        verifyInstallationCode (installationCode:${numpadCode}) 
                        {
                            error 
                                {
                                    code 
                                    msg
                                } 
                            ok 
                        }
                    }
                `,
                onResponse(res) {
                    if (res?.data?.verifyInstallationCode?.ok) {
                        setStep(2);
                        setInstallError(null);
                    } else {
                        setInstallError("Wrong password!");
                    }
                    dispatch(setNumpadCode(null));
                },
            });
        } else if (numpadCode && step === 2) {
            Query({
                query: `
                    { 
                        verifyRoomNumber (installationCode:${installCode}, roomNumber:${numpadCode}) 
                        {
                            error 
                                {
                                    code 
                                    msg
                                } 
                            ok
                            permissions
                            roomTVs{
                                id
                                name
                                machines{
                                mac
                                type
                                mac
                                }
                            }
                        }
                    }
                `,
                onResponse(res) {
                    if (res?.data?.verifyRoomNumber?.ok) {
                        setRoomTVs(prepareRoomTVsList(res.data.verifyRoomNumber.roomTVs));
                        setRoomTVsData(res.data.verifyRoomNumber.roomTVs);
                        setInstallError(null);
                        setRoomNumber(numpadCode);
                        SESSION._composePermissions(res.data.verifyRoomNumber.permissions);

                        setStep(
                            SESSION.hasPermission(PERMISSION.PRODUCT_CAST) && localStorage.getItem("builtInCC")
                                ? 21
                                : 3,
                        );
                    } else {
                        setInstallError("Room entered doesn't exist");
                    }
                    dispatch(setNumpadCode(null));
                },
            });
        }
    }, [numpadCode]);

    //Functions

    //Start installation
    const startInstallation = () => {
        setStep(1);
        setInstallError(null);
        dispatch(setNumpadCode(null));
    };

    //On roomTV selected => query to install device
    const roomTVSelected = (roomTVId) => {
        const rtData = roomTVsData.filter((rt) => Number(rt.id) === Number(roomTVId))[0];

        if (rtData?.machines && rtData.machines.length > 0) {
            setInstallError(
                "This device can not be installed on the selected point. Select an empty point to install.",
            );
            return;
        }
        //IMPORTANT: Si se cambia el nombre del dispositivo será necesario cambiar la función ensureMachineName del manager service
        const deviceName = `${sessionStorage.getItem("namePrefix") || STB.model} ${Texts.capitalize(
            rtData.name,
        )} , Room ${roomNumber}`;

        Query({
            query: `
                    mutation{ 
                        installTV (
                            installationCode:${installCode}, 
                            roomTVID:${roomTVId} , 
                            deviceType:"${STB.model}" ,
                            deviceMAC:"${STB.mac}", 
                            deviceRef:"${STB.ref}"
                            function:${deviceFunction}
                            ${localStorage.getItem("tvModel") ? `tvModel:"${localStorage.getItem("tvModel")}"` : ""}
                            ${
                                localStorage.getItem("firmwareVersion")
                                    ? `firmwareVersion:"${localStorage.getItem("firmwareVersion")}"`
                                    : ""
                            }
                           name: "${deviceName}" 
                    ) 
                        {
                            error 
                                {
                                    code 
                                    msg
                                } 
                            ok 
                        }
                    }
            `,
            onResponse(res) {
                if (res?.data?.installTV?.ok) {
                    setStep(4);
                    if (STB.isTizen) {
                        STB.createAccesToken();
                    }
                    setTimeout(() => {
                        STB.reload();
                    }, 10000);
                } else if (res?.data?.installTV?.error) {
                    localStorage.removeItem("mac");
                    setInstallError("This device can not be installed in the selected point.");
                } else {
                    localStorage.removeItem("mac");
                    setInstallError("Installation failed! Please try again.");
                }
            },
        });
    };

    const DeviceFunctionSelected = (functionId) => {
        setDeviceFunction(functionId);
        setStep(3);
    };

    //Prepare room tv list
    const prepareRoomTVsList = (roomTVs) => {
        const rTvList = [];

        roomTVs.map((rTV) => {
            const item = { id: rTV.id };
            item.innerHTML = `<div class="text-left text-2xl pl-4"><div>${rTV.name}</div>`;
            if (rTV.machines && rTV.machines.length > 0) {
                item.innerHTML = `${item.innerHTML}<div class="text-base">Occupied by device ${rTV.machines[0].type} ${rTV.machines[0].mac}</div>`;
            }
            item.innerHTML = `${item.innerHTML}</div>`;
            rTvList.push(item);
        });
        return rTvList;
    };

    return (
        <>
            {step === -1 &&
                printCenteredMessage(ReactHtmlParser(texts["install-from-manager"]), null, "black", "white")}

            {step === 1 && (
                <Modal
                    title={"Install room"}
                    subtitle={
                        <div>
                            Enter your installation code
                            <div style="opacity:0.5;font-size:1.2vw">(You will find this code in ZAFIRO Manager)</div>
                        </div>
                    }
                    body={
                        <Calculator
                            digits={6}
                            minDigits={6}
                            confirmText={"Continue"}
                            confirmIcon="lock-open"
                            encrypt={true}
                            error={installError}
                            setError={setInstallError}
                        />
                    }
                />
            )}
            {step === 2 && (
                <Modal
                    title={"Install room"}
                    subtitle={"Enter the room number"}
                    body={
                        <Calculator
                            digits={5}
                            confirmText={"Room number"}
                            confirmIcon="room"
                            error={installError}
                            setError={setInstallError}
                        />
                    }
                />
            )}
            {step === 21 && (
                <Modal
                    title={"Select the device function"}
                    body={
                        <>
                            <div className="p-4">
                                <VerticalList
                                    insideModal={true}
                                    data={{
                                        list: DEVICE_FUNCTION_OPTIONS,
                                        customClass: "h-auto",
                                        itemBorder: true,
                                        onClick: DeviceFunctionSelected,
                                    }}
                                />
                                <div className="pt-8">
                                    <Button
                                        insideModal={true}
                                        data={{
                                            name: "Cancel",
                                            customClass: "text-center py-4 w-full text-lg",
                                            border: true,
                                            icon: "back",
                                            onClick: startInstallation,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
            {step === 3 &&
                (!installError ? (
                    <Modal
                        title={"Select your TV to install"}
                        body={
                            <>
                                <div className="p-4">
                                    <VerticalList
                                        insideModal={true}
                                        data={{
                                            list: roomTVs,
                                            customClass: "h-auto",
                                            itemBorder: true,
                                            onClick: roomTVSelected,
                                        }}
                                    />
                                    <div className="pt-8">
                                        <Button
                                            insideModal={true}
                                            data={{
                                                name: "Cancel",
                                                customClass: "text-center py-4 w-full text-lg",
                                                border: true,
                                                icon: "back",
                                                onClick: startInstallation,
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                ) : (
                    <Modal
                        title={"Install room"}
                        firstFocus={"btn-modal-install-cancel"}
                        body={
                            <>
                                <div className="p-4 text-xl  text-red-100 ">{installError}</div>
                                <div className="pt-8 text-center">
                                    <Button
                                        insideModal={true}
                                        data={{
                                            id: "modal-install-cancel",
                                            name: "Cancel",
                                            customClass: "w-full py-4 text-2xl",
                                            border: true,
                                            icon: "back",
                                            onClick: startInstallation,
                                        }}
                                    />
                                </div>
                            </>
                        }
                    />
                ))}
            {step === 4 && (
                <Modal
                    title={
                        <div>
                            Installation successful!
                            <div className="pt-2 text-xl">The TV will reload in 10 seconds.</div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default Installation;
