import  { type FunctionComponent, cloneElement } from "preact";
import { makeKeyboardEvent } from "../../utils/events";
import { Children, PropsWithChildren } from "preact/compat";
import focus from "../../utils/focus";
import { moveFocus } from "../../utils/movement";
import type { VNode } from "preact";
import { useEffect, useRef } from "react";

export function navigable<T extends FunctionComponent<any>>(Component: T, navMap: Partial<{up: string, right: string, down: string, left: string}>){
    const onNavigation = makeKeyboardEvent(['up', "right", "down", "left"], (key)=>{
        const destination = navMap[key];
        if (typeof destination === 'string') {
            focus.value.replace(destination);
            return {stopPropagation: true};
        }
    });
    return function (props: Parameters<T>[0]) {
        return <Component {...props} onKeydown={onNavigation} />
    }
}

export function Navigable({ navMap, children, scrollIntoView, autoscroll, confine }: PropsWithChildren<{navMap: Partial<{up: string, right: string, down: string, left: string}>, autoscroll?: {y?: number, x?: number}, confine?: boolean, scrollIntoView?: ScrollIntoViewOptions}>){
    const ref = useRef<HTMLElement>(null);
    const onNavigation = makeKeyboardEvent(['up', "right", "down", "left"], (key)=>{
        const source = document.getElementById(focus.value.current ?? '');
        const destination = navMap[key];
        if(destination === 'stopPropagation'){
            return {stopPropagation: true, preventDefault: true};
        }
        else if (destination === 'searchNext'){
            const itemId = moveFocus(focus.value.current, key);
            if (itemId) {
                focus.value.replace(itemId);
            }
            return {stopPropagation: true, preventDefault: true};
        }
        else if (typeof destination === 'string') {
            focus.value.replace(destination);
            if (autoscroll){
                const targetElement: HTMLElement = document.getElementById(destination) as HTMLElement;
                if (source === targetElement.previousElementSibling || source === targetElement.nextElementSibling) { //Check that we are coming from another list element and not from outside the list
                    const yAxis = ['up', 'down'].includes(key) ? (autoscroll.y ?? 0) * (key === 'up' ? -1 : 1) : undefined;
                    const xAxis = ['right', 'left'].includes(key) ? (autoscroll.x ?? 0) * (key === 'right' ? -1 : 1) : undefined;
                    console.log({ top: yAxis, left: xAxis, behavior: 'smooth' });
                    targetElement?.parentElement?.scrollBy({ top: yAxis, left: xAxis, behavior: 'smooth' });
                }
            }
            else if (scrollIntoView)
                document.getElementById(destination)?.scrollIntoView(scrollIntoView);
            return {stopPropagation: true, preventDefault: true};
        }
        if (confine){
            return {stopPropagation: true, preventDefault: true};
        }
    });
    useEffect(()=>{
        //@ts-expect-error
        ref?.current?.base && ref.current.base.addEventListener('keydown', onNavigation);
        //@ts-expect-error
        return ()=>ref?.current?.base?.remove('keydown', onNavigation);
    }, [ref]);
    return (
        <>
            {
                Children.map(children, (child) => {
                    return cloneElement(child as any, {
                        ref
                    })
                })
            }
        </>) satisfies VNode;
}