import stores from "./shops";
import memize from "memize";

const not_found_channel = {
    id: "0",
    createdAt: "",
    updatedAt: "",
    code: "NOTFOUND",
    currencyCode: "",
    token: "NOTFOUND",
    name: "NOTFOUND",
    pricesIncludeTax: true,
    customFields: {
        is_active: true,
        max_products_per_order: 0,
        is_order_cancellation_allowed: false,
        is_max_product_per_order_active: false,
        allowScheduledDelivery: false,
        cover_url: "",
        delivery_schedule: "",
        shipping_config: {
            pickup: {
                price: {
                    base: 0,
                    includesTax: false,
                    taxRate: 0,
                },
                enabled: true,
            },
            delivery: {
                price: {
                    base: 0,
                    includesTax: false,
                    taxRate: 0,
                },
                enabled: true,
            },
        },
    },
};

const fn = async (token: string): Promise<Sales.Channel & { name: string }> => {
    return stores().then((shops) => {
        const found = shops.find((x: Sales.Channel) => x.token === token);
        if (!found) {
            return not_found_channel;
        }
        return found;
    });
};

export default memize(fn) as typeof fn;
