/* eslint-disable no-undef */

export const Media = {
    _playChannel(ip, port) {
        Android.playChannel(`${ip}:${port}`);
    },

    _stop() {
        Android.stopPlayer();
    },

    setVideoSize(x, y, width, height) {
        Android.setVideoSize(true, width, height, x, y);
    },

    getAudioList(onSuccess) {
        let info = JSON.parse(Android.getAudioTracks());
        let tracks = info ? info.audio.tracks : null;
        if (!tracks) return;
        let audioList = [];
        for (let i = 0; i < tracks.length; i++) {
            audioList.push({
                id: tracks[i].PID,
                name: tracks[i].lang,
            });
        }
        onSuccess(audioList);
    },

    getSubtitleList(onSuccess) {
        let info = JSON.parse(Android.getSubtitleTracks());
        let tracks = info ? info.subtitles.tracks : null;
        if (!tracks) return;
        let subtitleList = [];
        for (let i = 0; i < tracks.length; i++) {
            subtitleList.push({
                id: tracks[i].PID,
                name: tracks[i].lang,
            });
        }
        onSuccess(subtitleList);
    },

    changeAudio(ind) {
        Android.setAudioPID(ind);
    },

    changeSubtitle(ind) {
        Android.setSubtitlesPID(ind);
    },

    disableSubtitles() {
        Android.disableSubtitles();
    },
};
