/* eslint-disable no-undef */

import { EVENTS } from "../../../utils/eventsConst";
import { Media } from "../media";
import { STB } from "../stb";
import { philips } from "./philips";

let wixpObject = typeof document !== "undefined" ? document.createElement("object") : null;
if (wixpObject) {
    wixpObject.type = "application/jswebixp";
    wixpObject.style.position = "absolute";
    wixpObject.style.top = "-9999px";
    wixpObject.style.left = "-9999px";
}

export const wixp = {
    cookie: 1,
    requestsMap: {},
    init() {
        try {
            let bdy = document.getElementsByTagName("body")[0];
            bdy.appendChild(wixpObject);
            wixpObject.WebIXPOnReceive = wixp.receive;
        } catch (e) {
            console.error("Unable to initialize wixp!", e);
        }
        console.debug("wixp initialized! :D");
    },
    receive(frm) {
        let re = "[\x00-\x1F\x80-\xFF]";
        let jsonclean = frm.replace(re, "");
        try {
            if (jsonclean?.toLowerCase().indexOf("error") > -1) {
                //console.log(`RECEIVED ${jsonclean}`);
            }

            let resp = JSON.parse(jsonclean);
            parseResponse(resp);
        } catch (e) {
            console.warn("Error parsing wixp response...", frm);
        }
    },
    request(fun, callbacks, rd) {
        let req = {
            Svc: "WIXP",
            Cookie: wixp.cookie++,
            SvcVer: "1.0",
            CmdType: "Request",
            Fun: fun,
            CommandDetails: {},
        };
        if (typeof rd != "undefined") {
            req.CommandDetails = rd;
        }
        wixp.send(req, callbacks);
    },
    change(fun, callbacks, rd) {
        let req = {
            Svc: "WIXP",
            Cookie: wixp.cookie++,
            SvcVer: "1.0",
            CmdType: "Change",
            Fun: fun,
            CommandDetails: {},
        };
        if (typeof rd != "undefined") {
            req.CommandDetails = rd;
        }
        wixp.send(req, callbacks);
    },
    send(req, callbacks) {
        if (typeof callbacks != "undefined") {
            let cs = {};
            if (typeof callbacks.error == "undefined") {
                cs.success = callbacks;
                cs.error = function (e) {
                    console.error(`wixp call failed: ${e.message}`, e);
                };
            } else {
                cs = callbacks;
            }

            wixp.requestsMap[req.Fun] = cs;
        }
        wixpObject.WebIxpSend(JSON.stringify(req));
    },
};

const existAutonomousApps = (resp) => {
    if (resp.CommandDetails?.ActiveApplications) {
        let existAutonomousApps = false;
        for (let i = resp.CommandDetails.ActiveApplications.length - 1; i >= 0; i--) {
            let app = resp.CommandDetails?.ActiveApplications[i];

            if (app.ApplicationName == "AutonomousApps") {
                return true;
            }
        }
    }
    return false;
};

const outOfInterface = (resp) => {
    const foregroundAppName = resp.CommandDetails?.ActiveApplications?.[0]?.ApplicationName;
    if (foregroundAppName && !(foregroundAppName === "CustomDashboard" || foregroundAppName === "VolumeControl")) {
        return true;
    }
    return false;
};
const inInterface = (resp) => {
    const foregroundAppName = resp.CommandDetails?.ActiveApplications?.[0]?.ApplicationName;
    return foregroundAppName === "CustomDashboard";
};
const noAppActive = (resp) => {
    return resp?.CommandDetails?.ActiveApplications?.length === 0;
};

const parseResponse = (resp) => {
    if (noAppActive(resp)) {
        sessionStorage.removeItem("outOfInterface");
        philips.goToInterface();
    } else if (
        sessionStorage.getItem("outOfInterface") &&
        resp.CommandDetails?.ActiveApplications &&
        inInterface(resp)
    ) {
        sessionStorage.removeItem("outOfInterface");
        STB.backToInterface();
        return;
    } else if (resp.CommandDetails?.ActiveApplications && outOfInterface(resp)) {
        sessionStorage.setItem("outOfInterface", true);
    }
    if (resp.CommandDetails.CECBreakinRequest) {
        setTimeout(function () {
            STB.changeToInput(resp.CommandDetails.CECBreakinRequest);
        }, 600);
    }

    if (
        resp.CommandDetails.ChannelSelectionStatus &&
        (resp.CommandDetails.ChannelSelectionStatus == "Failure" ||
            (resp.CommandDetails.ChannelSelectionStatus == "Successful" &&
                resp.CommandDetails.ChannelPlayingStatus == "Error"))
    ) {
        dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_ERROR);
        philips.startChannelStatusInterval();
    }
    if (
        resp.CommandDetails.ChannelSelectionStatus &&
        resp.CommandDetails.ChannelSelectionStatus == "Successful" &&
        resp.CommandDetails.ChannelPlayingStatus == "Playing"
    ) {
        clearInterval(philips.channelStatusInterval);
        dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_SUCCESS);
    }
    if (resp.CommandDetails.ChannelSelectionStatus && resp.CommandDetails.ChannelSelectionStatus == "Stopped") {
        dispatchNewMediaEvent(EVENTS.MEDIA.STOPPED);
    }

    if (resp.CmdType == "Response") {
        if (typeof resp.Fun != "undefined" && typeof wixp.requestsMap[resp.Fun] != "undefined") {
            try {
                wixp.requestsMap[resp.Fun].success(resp.CommandDetails);
            } catch (e) {
                console.warn(`Error calling wixp response success callback: ${e.message}`, e);
            }
        }
    } else {
        console.debug("Wixp event received: ", resp);
    }
};
