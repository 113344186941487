import Query from "../../query";
import { SESSION } from "../../../utils/session";
import { Texts } from "../../../utils/texts";
import { figmaHeightPx2Px, figmaWidthPx2Px } from "../../../utils/utils";
import Movie from "../../../layouts/movies/movie";
import MovieDetail from "../../../layouts/movies/movieDetail";
import { MOVIES, PERMISSION, TVGW_PATH } from "../../../utils/constants";
import { AudioLanguages, Gender, movie, movies_Category, movies_Group } from "../../../utils/movies";
import { getIso6392, getLanguageEnglishNameFromIso6392 } from "../../../utils/iso639/ISO639";

export const getMoviesData = (adult: boolean, onSuccess?: (movies: any | null) => void, extraInfo?: any) => {
    // let movies = sessionStorage.getItem("movies");
    // if (movies) {
    //     if (onSuccess) {
    //         onSuccess(JSON.parse(movies));
    //     }
    //     return;
    // }

    if (SESSION.hasPermission(PERMISSION.NEW_MOVIES)) {
    } else {
        Query({
            query: `{
            oldMovies(adult: ${adult}){
                categories{
                    name
                    items{
                        actors
                        coverfile
                        director
                        id
                        isAdult
                        languages{
                            id
                            isMultilanguage
                            name
                            suffix
                            fileSuffix
                            iso6391
                            iso6392
                        }
                        length
                        name
                        plot
                        price
                        priceWithTax
                        subtitles{
                            file
                            fileExists
                            fileSuffix
                            id
                            iso6391
                            iso6392
                            name
                        }
                    }
                }
                cloudURL
                pricesWithTaxes
            }
        }`,
            onResponse(res) {
                if (res?.data?.oldMovies?.categories) {
                    const movies = composeMoviesGroups(res.data, extraInfo, adult);
                    // TODO: descomentar para cache
                    // sessionStorage.setItem("movies", JSON.stringify(movies));
                    if (onSuccess) {
                        onSuccess(movies);
                    }
                } else if (res && (!res.data || (res.data && !res.data.oldMovies))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
            onError() {
                console.log("ERROORR  onError");
            },
        });
    }
};

const composeMoviesGroups = (moviesData: any, extraInfo: any, adult: boolean) => {
    const moviesGroups = moviesData.oldMovies.categories;
    const FREE2GUEST = adult ? false : SESSION.hasPermission(PERMISSION.FREE_TO_GUEST);

    const langCode = localStorage.getItem("lang") || extraInfo?.projectLanguageCode;

    let byLang: movies_Group[] = [];
    let allMovies: movie[] = [];

    moviesGroups.forEach((group: movies_Group) => {
        let _moviesLang: movie[] = [];
        group.name = Texts.translateCap(extraInfo.texts, group.name.toLowerCase());
        group.items.forEach((movie: movie) => {
            // movie specific data
            movie.type = "movie";
            movie.genderType = Gender.Female;
            movie.icon = "icon-movies";
            movie.trailerUrl = `${TVGW_PATH}:8080/trailer-id${movie.id}.m3u8`;
            movie.url = `${TVGW_PATH}:8080/movie-id-${movie.id}-lang-en.m3u8`;
            movie.forceAudioUrl = `${TVGW_PATH}:8080/movie-id-${movie.id}-lang-{{lang}}-forceaudio.m3u8`;

            if (movie.coverfile) {
                // coverFile
                movie.coverUrl = `${moviesData.oldMovies.cloudURL}gfx_base/contents/movies/${encodeURIComponent(
                    movie.coverfile,
                )}?center_crop=${figmaWidthPx2Px(192)}x${figmaHeightPx2Px(288)}`;
                // background
                movie.backgroundUrl = `${moviesData.oldMovies.cloudURL}gfx_base/contents/movies_bg/${encodeURIComponent(
                    movie.coverfile,
                )}?`;
                movie.bgAsBannerUrl = `${moviesData.oldMovies.cloudURL}gfx_base/contents/movies_bg/${encodeURIComponent(
                    movie.coverfile,
                )}?center_crop=${window.innerWidth}`;
                // banner
                movie.bannerUrl = `${moviesData.oldMovies.cloudURL}gfx_base/contents/movies_bg/${encodeURIComponent(
                    movie.coverfile.replace(".jpg", "_banner.jpg"),
                )}?center_crop=${window.innerWidth}`;
            }
            movie.priceToShow = moviesData.oldMovies.pricesWithTaxes ? movie.priceWithTax : movie.price;
            movie.pricesWithTaxes = moviesData.oldMovies.pricesWithTaxes;
            movie.rented = false; // later will parse rented movies
            movie.free2guest = FREE2GUEST;

            // filter movies in categories
            const _lang = movie.languages.filter((language: AudioLanguages) => language.iso6391 === langCode);
            if (_lang.length > 0) {
                _moviesLang.push({ ...movie });
            }
            if (allMovies.filter((_movie) => _movie.id === movie.id)?.length === 0) {
                allMovies.push({ ...movie });
            }
        });
        if (_moviesLang.length > 0) {
            byLang.push({ ...group, items: _moviesLang });
        }
    });

    if (adult) {
        if (moviesGroups?.[0].name === Texts.translateCap(extraInfo.texts, "new in")) {
            moviesGroups.shift();
        }
        moviesGroups.sort((a: movies_Group, b: movies_Group) => b.items.length - a.items.length);
    }
    // create categories for grid
    let categories = [
        {
            id: MOVIES.FILTERS.ALL_MOVIES,
            name: Texts.translateCap(extraInfo.texts, "all-movies"),
            items: moviesGroups,
        },
    ];
    if (!adult) {
        categories.push({
            id: MOVIES.FILTERS.LANGUAGE,
            name: Texts.translateCap(extraInfo.texts, "movies-in-lang").replace(
                "{{lang}}",
                getLanguageEnglishNameFromIso6392(getIso6392(langCode)),
            ),
            items: byLang,
        });
    }
    categories.push({
        id: MOVIES.FILTERS.RENTED,
        name: Texts.translateCap(extraInfo.texts, FREE2GUEST ? "watched-list" : "rented-movies"),
        items: [{ id: 0, name: "rented", items: [] }],
    });

    //create movie component
    categories.forEach((category: movies_Category) => {
        category.items.forEach((group: movies_Group, index: number) => {
            group.id = index;
            group.items.forEach((movie: movie) => {
                movie.groupId = index;
                movie.groupName = group.name;
                movie.focusId = `btn-${index}_${movie.type}_${movie.id}`;
                movie.component = <Movie />;
                movie.detailComponent = <MovieDetail />;
            });
        });
    });

    return categories;
};
