import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MOVIES, PERMISSION } from "../../utils/constants";
import { setNumberPress } from "../../actions/eventsActions";
import { eventHandler } from "../../utils/eventHandler";
import Limbo from "../../components/screen/limbo";
import VerticalCenter from "../../components/common/verticalCenter";
import { Texts } from "../../utils/texts";
import { STB } from "../../hooks/apis/stb";
import focus from "../../utils/focus";
import Grid from "../grid/Grid";
import { getMoviesData } from "../../hooks/GraphqlCalls/movies/moviesData";
import { figmapx2vh, figmapx2vw, getStayGuestRoomId, px2vh, vh2px, isAdultEnabled } from "../../utils/utils";
import { getRentedMovies } from "../../hooks/GraphqlCalls/movies/getRentedMovies";
import { SESSION } from "../../utils/session";
import { parseRentedMovies } from "../../utils/movies";
import Button from "../../components/common/button";
import { navigate } from "wouter/use-location";
import { setPopUp } from "../../actions/uiActions";

const Movies = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const FREE2GUEST = SESSION.hasPermission(PERMISSION.FREE_TO_GUEST);
    const ADULT_CONTENT_PERMISSION = SESSION.hasPermission(PERMISSION.ADULT_CONTENT);
    const { sessionData } = useSelector((state) => state.status);
    const guestCountry = useSelector(
        (state) => state.status.locationData.guestInfo.country || state.status.locationData.projectCountryRef,
    );
    const projectLanguageCode = useSelector(
        (state) => state.status.locationData.projectLangs.find((x) => x.isDefault === true).languageRef,
    );

    const stayGuestRoomId = getStayGuestRoomId(sessionData);
    const isAdultEnabledValue = ADULT_CONTENT_PERMISSION && isAdultEnabled(sessionData);

    const [loadingMovies, setLoadingMovies] = useState(true);
    const [moviesData, setMoviesData] = useState(null);
    const [rentedData, setRentedData] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [filter, setFilter] = useState(MOVIES.FILTERS.ALL_MOVIES);

    const langCode = localStorage.getItem("lang") || projectLanguageCode;

    useEffect(() => {
        // TODO: añadir cache y tiempo de cache de getMoviesData
        getMoviesData(false, setMoviesData, { guestCountry, texts, projectLanguageCode });
        // TODO: getMoviesRented sin cache
        getRentedMovies({ stayGuestRoomId, last24h: !FREE2GUEST }, setRentedData);
    }, []);

    useEffect(() => {
        if (moviesData && rentedData) {
            setLoadingMovies(false);
            console.log(parseRentedMovies(moviesData, rentedData));
            setParsedData(parseRentedMovies(moviesData, rentedData));
        } else {
            // timeout for error in query or null data
            setTimeout(() => {
                setLoadingMovies(false);
            }, 6000);
        }
    }, [moviesData, rentedData]);

    useEffect(() => {
        if (moviesData?.length > 0 && moviesData[0]?.items.length > 0) {
            // FOCUS first movie of first group from first category
            focus.value.replace(`btn-${moviesData[0].items[0].id}_movie_${moviesData[0].items[0].items[0].id}`);
        }
    }, [moviesData]);

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const adultMoviesButton = () =>
        isAdultEnabledValue && (
            <Button
                insideModal={true}
                data={{
                    id: "specialCategory",
                    icon: "adult-movies",
                    name: Texts.translateCap(texts, "adults"),
                    iconSize: "vertical-middle text-4xl",
                    customClass: `text-gray-100 rounded-4vw text-center`,
                    customStyle: {
                        width: figmapx2vw(243),
                        height: figmapx2vh(70),
                        color: "#F5F6F8",
                        fontSize: figmapx2vw(40),
                    },
                    focusClass: "grid-btn-back-focus rounded font-700",
                    onClick() {
                        // show pop up adult content confirm
                        dispatch(setPopUp("adultContentConfirm"));
                    },
                }}
            />
        );

    return (
        <div
            id="movies"
            className={"w-full h-full"}
            style={{
                visibility: "visible",
            }}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo id="movies-limbo" noBlocker={true} />

            {parsedData && parsedData.length > 0 ? (
                <Grid
                    gridData={parsedData}
                    loadingData={loadingMovies}
                    firstFilter={parsedData[0].id}
                    itemHeight={305} //px from figma
                    options={{
                        backgroundBanner: true,
                        infoOfFocus: true,
                        noGradient: true,
                        focusType: "_movie_",
                        singleGroupItemsPerRow: 8,
                        specialButton: adultMoviesButton,
                    }}
                />
            ) : (
                <VerticalCenter
                    text={Texts.capitalize(
                        loadingMovies ? `${texts["loading"]}...` : texts["no data available"],
                    ).replace("{{data}}", texts["movies"])}
                    cClass={`white text-4xl`}
                />
            )}
        </div>
    );
};
export default Movies;
