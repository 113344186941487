const MSG_TYPES = {
    PRIORITY: "PRIORITY",
    CRITICAL: "CRITICAL",
    IMPORTAN: "IMPORTANT",
};

export const isCritical = (msg) => {
    if (!msg) {
        return null;
    }
    return [MSG_TYPES.PRIORITY, MSG_TYPES.CRITICAL].indexOf(msg.priority) > -1;
};

export const isImportant = (msg) => {
    if (!msg) {
        return null;
    }
    return [MSG_TYPES.IMPORTAN].indexOf(msg.priority) > -1;
};
