import { useEffect, useRef, useState } from "react";
import { Theme } from "../../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp } from "../../actions/uiActions";

const PopupError = () => {
    const dispatch = useDispatch();
    let errorTimeout = useRef();
    const [show, setShow] = useState(false);
    const { text, onClose, timeout = 5000 } = useSelector((state) => state.ui.popUpError);

    useEffect(() => {
        if (text) {
            setShow(true);
        }
    }, [text]);

    useEffect(() => {
        if (show) {
            errorTimeout.current = setTimeout(function () {
                dispatch(displayErrorPopUp({ text: null }));
                setShow(false);
                if (onClose) {
                    onClose();
                }
            }, timeout);
        }
    }, [show]);

    useEffect(() => {
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);

    return (
        <div
            className={"absolute bottom-0 text-center w-full heightAnimation"}
            style={{
                height: show ? "11.5vh" : 0,
                lineHeight: "11.5vh",
                backgroundColor: Theme.colors.default.gray["900"],
                color: Theme.colors.default.gray["100"],
                zIndex: 10000,
            }}
        >
            {text}
        </div>
    );
};

export default PopupError;
