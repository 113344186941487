import { useEffect, useState } from "preact/hooks";
import focus from "../../utils/focus";
import { STORAGE_KEY } from "../../utils/constants";
import { getDasExternalUrl, vh2px, vw2px } from "../../utils/utils";
import { getGridItemFocusedData } from "../../utils/gridUtils";

const BackgroundBanner = ({ activeCategory }) => {
    const [backgrounUrl, setBackgroundUrl] = useState(null);

    useEffect(() => {
        const gridItemFocused = getGridItemFocusedData(activeCategory);
        if (gridItemFocused) {
            const bannerPath = getDasExternalUrl(gridItemFocused?.bannerUrl, { w: vw2px(100), h: vh2px(55) });
            const bannerImage = new Image();
            bannerImage.onload = () => {
                setBackgroundUrl(bannerPath);
            };
            bannerImage.onerror = () => {
                setBackgroundUrl(getDasExternalUrl(gridItemFocused?.bgAsBannerUrl, { w: vw2px(100), h: vh2px(55) }));
            };
            bannerImage.src = bannerPath;
        }
    }, [focus.value.current]);

    return (
        <>
            <div
                id="gradient"
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    top: 0,
                    left: 0,
                    backgroundColor: "#292929",
                    background: `linear-gradient(360deg, #292929 0%, rgba(41, 41, 41, 0.8) 61%, rgba(41, 41, 41, 0) 100%)`,
                    zIndex: 5,
                }}
            ></div>
            <div
                id="color-overlay"
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "45vh",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#292929",
                    zIndex: 5,
                }}
            ></div>
            <div
                id="BackgroundBanner"
                className={"backgroundImageTransition"}
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "55vh",
                    top: 0,
                    left: 0,
                    backgroundColor: "black",
                    backgroundImage: `url(${backgrounUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    zIndex: 2,
                }}
            ></div>
        </>
    );
};

export default BackgroundBanner;
