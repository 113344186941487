import { useEffect } from "preact/hooks";
import { useSelector } from "react-redux";
import Button from "./button";
import { cleanWhiteChars } from "../../utils/utils";
import { Texts } from "../../utils/texts";
import focus from "../../utils/focus";
import MessageItem from "./list/messageItem";
import { memo } from "preact/compat";

const VerticalList = ({ data, insideModal, preventFirstFocus, activeItemId }) => {
    let {
        list,
        itemType,
        itemCustomClass,
        itemCustomStyle,
        itemRounded,
        itemBorder,
        onClick,
        customClass,
        title,
        maxHeight,
        noDataText,
    } = data;

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const _itemTypeText = itemType ? `${itemType}-` : "";

    /////////////////////
    //Listeners
    //On load, focus list first element
    useEffect(() => {
        if (preventFirstFocus) {
            return;
        }
        if (list?.[0]) {
            focus.value.replace(cleanWhiteChars(`btn-${_itemTypeText}${list[0].id}`));
        } else if (document.getElementById("btn-exit")) {
            //insede modal
            focus.value.replace("btn-exit");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickAction = (value) => {
        if (onClick) {
            onClick(value);
        }
    };

    return (
        <div
            style={{ maxHeight: maxHeight || "32rem" }}
            className={`overflow-y-auto verticalList 
              ${customClass ? customClass : ""} `}
        >
            {list.map((item, index) => {
                const btnId = `${_itemTypeText}${item.id}`;
                return (
                    <Button
                        key={btnId}
                        insideModal={insideModal}
                        data={{
                            id: btnId,
                            name: item.name,
                            body: !itemType ? item.body : null,
                            innerHTML: item.innerHTML,
                            rounded: itemRounded,
                            border: itemBorder,
                            customClass: item.customClass || itemCustomClass,
                            active: activeItemId && btnId === activeItemId,
                            customStyle: {
                                ...itemCustomStyle,
                            },
                            value: item.id,
                            onClick: onClickAction,
                        }}
                        component={itemType === "message" ? MessageItem(item) : null}
                    />
                );
            })}
            {!list || list.length === 0 ? (
                <div className="mb-12">
                    {Texts.capitalize(texts[noDataText || "no data available"]).replace(
                        "{{data}}",
                        texts[title] || texts["data"],
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default memo(VerticalList);
