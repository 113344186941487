import Button from "../common/button";
import { carrito } from "../sales/signals/carrito";
import { useDispatch, useSelector } from "react-redux";
import { setPopUp } from "../../actions/uiActions";
import storage from "../../utils/storage";
import Modal from "../common/modal";
import ReactHtmlParser from "react-html-parser";
import { navigate } from "wouter/use-location";
import { eventHandler } from "../../utils/eventHandler";

const CartItemModal = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    const executeProductDeletion = (val) => {
        const itemToDelete = document.getElementById(sessionStorage.getItem("itemClick"));
        if (itemToDelete) {
            const variantId = parseInt(itemToDelete.getAttribute("data-variantid"));
            const timestamp = itemToDelete.getAttribute("data-varianttimestamp");
            const itemPos = itemToDelete.getAttribute("data-pos");

            //get item to focus after delete element
            const prevElem =
                parseInt(itemPos) > 0
                    ? document.getElementById(itemToDelete.id.replace(`-${itemPos}`, `-${parseInt(itemPos) - 1}`))
                    : null;
            if (prevElem) {
                sessionStorage.setItem("forceFocus", prevElem.id);
            } else {
                const nextElem = document.getElementById(
                    itemToDelete.id.replace(`-${itemPos}`, `-${parseInt(itemPos) + 1}`),
                );
                nextElem && sessionStorage.setItem("forceFocus", itemToDelete.id);
                !nextElem && sessionStorage.setItem("forceFocus", "noFocus");
            }

            //remove item from cart
            let posToDelete = null;
            carrito.value[storage.get("orderToken")]?.forEach((item, pos) => {
                if (parseInt(item.variant) === variantId && item.timestamp === timestamp) {
                    posToDelete = pos;
                }
            });
            carrito.value[storage.get("orderToken")]?.splice(posToDelete, 1);
            dispatch(setPopUp(null));

            //esta línea está porque el persistent signal no está actualizando el storage
            localStorage.setItem("cart", JSON.stringify(carrito.value));
        }
    };

    return (
        <Modal
            backBtn={true}
            firstFocus={"btn-edit-product"}
            body={
                <>
                    <div className="p-4" style={{ minHeight: "22vh" }}>
                        {document.getElementById(sessionStorage.getItem("itemClick")) && (
                            <>
                                <div style="font-weight: bold; font-size: 1.56087vw;">
                                    {document.getElementById(sessionStorage.getItem("itemClick"))
                                        ? ReactHtmlParser(
                                              Array.from(
                                                  document.getElementById(sessionStorage.getItem("itemClick"))
                                                      ?.children,
                                              ).find((x) => x.id === `${sessionStorage.getItem("itemClick")}-desc`)
                                                  ?.firstChild.innerHTML,
                                          )
                                        : null}
                                </div>
                                <div style="font-size: 1.45682vw; margin-left: 2.60146vw;">
                                    {Array.from(document.getElementById(sessionStorage.getItem("itemClick"))?.children)
                                        .find((x) => x.id === `${sessionStorage.getItem("itemClick")}-desc`)
                                        .children[1].innerHTML.split(", ")
                                        .map((extra) => (
                                            <div>{extra}</div>
                                        ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="pt-8">
                        <Button
                            insideModal={true}
                            data={{
                                id: "edit-product",
                                border: true,
                                name: texts["edit"],
                                customClass: "text-center py-2 w-full text-2xl",

                                onClick: () => {
                                    const cartItem = document.getElementById(sessionStorage.getItem("itemClick"));
                                    if (cartItem) {
                                        const productId = parseInt(cartItem.getAttribute("data-productid"));
                                        const variantId = parseInt(cartItem.getAttribute("data-variantid"));
                                        const timestamp = cartItem.getAttribute("data-varianttimestamp");
                                        dispatch(setPopUp(null));
                                        eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
                                        navigate(
                                            `/roomshops/${storage.get(
                                                "orderToken",
                                            )}/products/${productId}/variant/${variantId}/${timestamp}`,
                                            { replace: true },
                                        );
                                    }
                                },
                            }}
                        />
                    </div>
                    <div className="pt-4 mb-8 pb-2">
                        <Button
                            insideModal={true}
                            data={{
                                id: "delete-product",
                                border: true,
                                name: texts["delete-product"],
                                customClass: "text-center py-2 w-full text-2xl",

                                onClick: () => {
                                    executeProductDeletion();
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    );
};

export default CartItemModal;
