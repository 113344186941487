import { Theme } from "../../../utils/theme";

const DeliveryItem = (item, index) => {
    return (
        <div className="float-left" style={{ padding: "1.2vw" }}>
            <div className={`mx-auto nowrap text-ellipsis overflow-hidden text-2xl text-center pt-2 `}>{item.name}</div>
        </div>
    );
};
export default DeliveryItem;
