import Query from "../query";

const getCCInstructions = (callBackOnResponse) => {
    Query({
        query: `{
            castScreen {
                id
                contentStyle
                contentWidgets{
                    ID
                    contentWidgets{
                        ID
                        actions
                        data
                        pos
                        type
                        widgetStyle
                        fonts{
                            externalURL
                            libraryRef
                            name
                        }
                    }
                    data
                    pos
                    type
                    actions
                    widgetStyle
                    fonts{
                        externalURL
                        libraryRef
                        name
                    }
                }
                devices{
                    grid{
                        cols
                        rows
                    }
                    type
                }
                pos

        }
    }`,
        onResponse(res) {
            if (res && res.data && res.data.castScreen) {
                callBackOnResponse(res.data.castScreen);
            }
        },
    });
};

export default getCCInstructions;
