export const Media = {
    init() {
        this.VIDEOPANE = document.createElement("div");
        this.VIDEOPANE.style.position = "fixed";
        this.VIDEOPANE.style.top = "0";
        this.VIDEOPANE.style.left = "0";
        this.VIDEOPANE.style.backgroundColor = "rgb(237, 216, 240)";
        this.VIDEOPANE.style.zIndex = "50";
        this.VIDEOPANE.style.visibility = "hidden";
        this.VIDEOPANE.id = "regularVideoPane";
        document.body.appendChild(this.VIDEOPANE);
    },

    _stop() {
        this.playingMedia = false;
        this.VIDEOPANE.style.visibility = "hidden";
    },

    setVideoSize(x, y, width, height) {
        this.VIDEOPANE.style.visibility = "";
        this.VIDEOPANE.style.marginTop = `${y}vh`;
        this.VIDEOPANE.style.marginLeft = `${x}vw`;
        this.VIDEOPANE.style.width = `${width}vw`;
        this.VIDEOPANE.style.height = `${height}vh`;
    },

    getAudioList(onSuccess) {
        onSuccess([]);
    },
    getSubtitleList(onSuccess) {
        onSuccess([]);
    },
};
