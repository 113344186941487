import { useEffect, useRef } from "preact/hooks";
import { Theme } from "../../../utils/theme";
import { getEnvVar } from "../../../utils/utils";
import focus from "../../../utils/focus";

const ChannelSelectorItem = (channel, index, logoPath) => {
    let logosSucess = useRef();
    logosSucess.current = [];

    useEffect(() => {
        // first
        loadLogo();
    }, [channel]);

    const loadLogo = () => {
        var imageObj = new Image();
        imageObj.onload = function (e) {
            logosSucess.current.push(channel.tvchannelid);
            const elem = document.querySelector(`#ch-select-${channel.id}`);
            elem.parentNode.style.backgroundImage = `url('${imageObj.src}')`;
            elem.classList.remove("icon", "icon-channels");
            elem.nextSibling.innerHTML = "&nbsp;";
        };
        imageObj.onerror = function (e) {
            const elem = document.querySelector(`#ch-select-${channel.id}`);
            elem.parentNode.style.backgroundImage = `url('${imageObj.src}')`;
            elem.classList.add("icon", "icon-channels");
            elem.nextSibling.innerHTML = channel.name;
        };

        imageObj.src =
            channel.logoURL ||
            `${getEnvVar("API")}ext/${logoPath.pRef}/gfx_base/contents/tv/${channel.logofile}?center_crop=120x120`;
    };

    return (
        <div className={"relative"}>
            <div
                className="float-left rounded bg-center bg-contain bg-no-repeat "
                style={{
                    width: "10.2vw",
                    padding: "1.2vw",
                    backgroundColor: "#252626",
                    color: Theme.colors.default.gray["100"],
                }}
            >
                <div
                    id={`ch-select-${channel.id}`}
                    className={` mx-auto icon icon-channels text-4xl`}
                    style={{
                        width: "4vh",
                        height: "4vh",
                    }}
                ></div>
                <div className={`mx-auto nowrap text-ellipsis overflow-hidden text-base pt-2 `}>{channel.name}</div>
            </div>
            {focus.value.current === `btn-channelSelector-${channel.id}` ? (
                <div
                    style={{
                        position: "absolute",
                        top: "13vh",
                        textAlign: "center",
                        width: "16vw",
                        left: "-3vw",
                    }}
                >
                    {channel.name}
                </div>
            ) : null}
        </div>
    );
};
export default ChannelSelectorItem;
