import { Entries } from 'type-fest';

type ObjectFromEntries<Entries extends [keyof any, any][]> = {
    [K in Entries[number][0]]: Extract<Entries[number], [K, any]>[1]
}
export const fromEntries = <T extends [keyof any, any][]>(iterable: T): ObjectFromEntries<T>=>{
    return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
    }, {} as any) as ObjectFromEntries<T>;
}


const entries = <T extends {}>(obj: T): Entries<T>=>{
    const ownProps = Object.keys(obj) as (keyof T)[];
    let i: number = ownProps.length;
    const resArray = new Array<[keyof T, T[keyof T]]>(i);
    while (i--)
        resArray[i] = [ownProps[i] as keyof T, obj[ownProps[i] as keyof T]];

    return resArray as Entries<T>;
};
export const object = {
    entries,
    fromEntries
} as const;