import { completeDasURL } from "./utils";

export const Theme = {
    focus: {
        size: null,
    },
    colors: {
        defaultBackground: null,
        defaultForeground: null,

        activeBackground: null,
        activeForeground: null,

        focusBackground: null,
        focusForeground: null,
    },
    paragraph: {
        size: null,
    },
    init(locationData) {
        this.locationData = locationData;
        this.config = locationData.theme?.config;
        this.heading = locationData.theme?.config?.fontStyles.heading;
        this.paragraph = locationData.theme?.config?.fontStyles.paragraph;
        this.colors = locationData.theme?.config?.colors || {};
        this.colors.default = this.getDefaultStyles();
        this.focus = {
            background: this.colors.focusBackground || "rgb(91,181,218)",
            foreground: this.colors.focusForeground || "white",
            size: `${this.colors.focusBorderSize || "5"}px`,
        };
        this.logos = locationData.theme?.themeLogos || [];

        this.includeBodyFonts();
        this.addThemeStyles();
    },

    getDefaultStyles() {
        return {
            gray: {
                100: "#F5F6F8",
                200: "#E9EDF0",
                300: "#D3DAE1",
                400: "#BDC8D3",
                600: "#91A3B5",
                700: "#76889B",
                800: "#576575",
                900: "#2E3843",
            },
            blue: {
                400: "#0F63BD",
            },
            success: "#36B37E",
            error: "#DC4E2F",
            overlay: "rgba(0, 0, 0, 0.4)",
            infoLabel: "rgba(6, 55, 107, 0.5)",
        };
    },

    addThemeStyles(useDefault) {
        // prettier-ignore
        //default styles
        let css = `
        .focusOutline { box-shadow: 0 0 0 5px rgb(91,181,218);}
        .focusBorder { border: 5px solid rgb(91,181,218);}
        .focusBorderColor { border-color: rgb(91,181,218) !important;}
        .focusBgColor { background-color: rgb(91,181,218);}
        .focusFgColor {color: white;}      
        .borderColor {border-color: white;}      
        .button {background-color: gray; color: white;}
        .btnFocused { background-color: rgb(91,181,218) !important;color: white !important;}
        `;

        if (!useDefault) {
            // prettier-ignore
            css = `.focusOutline { box-shadow: 0 0 0 ${this.focus.size} ${this.focus.background};}
                .focusBorder { border: ${this.focus.size} solid ${this.focus.background};}
                .focusBorderColor { border-color: ${this.focus.background} !important;}
                .focusBgColor {background-color: ${this.focus.background};}
                .focusFgColor {color: ${this.focus.foreground} !important;}        
                .borderColor {border-color: ${this.colors.defaultForeground};}                  
                .button {background-color: ${this.colors.defaultBackground || 'gray'}; color: ${this.colors.defaultForeground  || 'white'};}
                .btnFocused { background-color: ${this.focus.background} !important;color: ${this.focus.foreground} !important;}   
                .btnFocused ::-webkit-scrollbar-thumb{background: ${this.focus.foreground};}
                .button.active{background-color: ${this.colors.activeBackground || 'gray'} !important; color: ${this.colors.activeForeground  || 'white'} !important;}
                   `;
        }

        const focusLight = this.focus?.background
            ? this.focusIsLight(this.focus.background || "rgba(91,181,218,1)")
            : false;
        this.light = !focusLight;
        this.dark = focusLight;
        this.modalActiveItemColor = this.dark ? "#91A3B5" : "#E9EDF0";
        if (!this.colors?.default) {
            this.colors.default = this.getDefaultStyles();
        }
        this.appendStyleToHead(css);
    },

    addMenuStyles(colors) {
        if (!colors || !colors.colors) {
            return;
        }
        let menuColors = colors.colors;
        let css = `.menuBtnActive { background-color: ${menuColors.activeBackground}  !important;color:${menuColors.activeForeground};}   
                   .menuBtnActive .icon { color:${menuColors.iconActiveColor}!important;}   
                   .menuBtnFocused { background-color: ${menuColors.focusBackground}  !important;color:${menuColors.focusForeground}!important;}  
                   .menuBtnFocused .icon { color:${menuColors.iconFocusColor}!important;}   
                   .menuOutline { box-shadow: 0 0 0 ${this.focus.size} ${menuColors.focusBackground};}
                   `;
        this.appendStyleToHead(css);
    },

    focusIsLight(focusColor) {
        let rgba = focusColor.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

        const c_r = Number(rgba[0]);
        const c_g = Number(rgba[1]);
        const c_b = Number(rgba[2]);
        const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
        return brightness > 155 || parseFloat(rgba[3]) <= 0.3;
    },

    getBodyStyle() {
        if (!this.locationData || !this.locationData.theme || !this.locationData.theme.config) {
            return {};
        }
        const theme = this.locationData.theme.config;

        return {
            position: "absolute",
            width: "100%",
            height: "100%",
            color: theme?.colors.defaultForeground,
            fontSize: theme?.fontStyles?.paragraph.size ? `${theme?.fontStyles?.paragraph.size}vw` : "",
            fontFamily: `${theme?.fontStyles?.paragraph?.name || ""}`,
        };
    },

    includeBodyFonts() {
        if (this.heading?.name) {
            this.includeFonts(
                this.heading.font.externalUrl ||
                    completeDasURL(this.heading.font.libraryRef ? `${this.heading.font.libraryRef}/?format=css` : ""),
            );
        }
        if (this.paragraph?.name) {
            this.includeFonts(
                this.paragraph.font.externalUrl ||
                    completeDasURL(
                        this.paragraph.font.libraryRef ? `${this.paragraph.font.libraryRef}/?format=css` : "",
                    ),
            );
        }
    },

    includeFonts(fontRef) {
        if (!fontRef) {
            return;
        }
        let link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = fontRef;
        if (document.getElementsByTagName("HEAD")[0]?.children) {
            const headElements = document.getElementsByTagName("HEAD")[0].children;

            let element_found = false;
            for (let i = 0; i < headElements.length; i++) {
                element_found = headElements[i].href && headElements[i].href === link.href;
            }

            if (!element_found) {
                document.getElementsByTagName("HEAD")[0].appendChild(link);
            }
        }
    },

    waitForWebfonts(fonts, callback) {
        let loadedFonts = 0;
        for (let i = 0, l = fonts.length; i < l; ++i) {
            (function (font) {
                let node = document.createElement("span");
                // Characters that vary significantly among different fonts
                node.innerHTML = "giItT1WQy@!-/#";
                // Visible - so we can measure it - but not on the screen
                node.style.position = "absolute";
                node.style.left = "-10000px";
                node.style.top = "-10000px";
                // Large font size makes even subtle changes obvious
                node.style.fontSize = "300px";
                // Reset any font properties
                node.style.fontFamily = "sans-serif";
                node.style.fontVariant = "normal";
                node.style.fontStyle = "normal";
                node.style.fontWeight = "normal";
                node.style.letterSpacing = "0";
                document.body.appendChild(node);

                // Remember width with no applied web font
                let width = node.offsetWidth;

                node.style.fontFamily = font;

                let interval;
                function checkFont() {
                    // Compare current width with original width
                    if (node && node.offsetWidth != width) {
                        ++loadedFonts;
                        node.parentNode.removeChild(node);
                        node = null;
                    }

                    // If all fonts have been loaded
                    if (loadedFonts >= fonts.length) {
                        if (interval) {
                            clearInterval(interval);
                        }
                        if (loadedFonts == fonts.length) {
                            callback();
                            return true;
                        }
                    }
                }

                if (!checkFont()) {
                    interval = setInterval(checkFont, 50);
                }
            })(fonts[i]);
        }
    },
    appendStyleToHead(css) {
        const head = document.head || document.getElementsByTagName("head")[0];
        const style = document.createElement("style");

        head.appendChild(style);

        style.type = "text/css";
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    },
};
