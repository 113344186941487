import { promisedVendureQuery } from "../../../hooks/query";
import { getEnvVar } from "../../../utils/utils";

type QueryResult = {
    data: {
        collections: {
            items: {
                breadcrumbs: {
                    name: string | '__root_collection__',
                    id: string,
                    slug: string
                }[],
                children: {
                    name: string,
                    id: string,
                    slug: string
                    productVariants: {
                        items: {
                            product:        {
                                id:            string;
                                assets:        {
                                    id:     string;
                                    source: string;
                                }[];
                                featuredAsset: {
                                    id:     string;
                                    source: string;
                                };
                                translations: {
                                  languageCode: string,
                                  name: string,
                                  description: string
                                };
                                facetValues: {
                                    code: string
                                    translations: {
                                        languageCode: string
                                        name: string
                                    }[]
                                    facet: {
                                        id: number
                                        code: string
                                        translations: {
                                            languageCode: string
                                            name: string
                                        }[]
                                    }
                                }[];
                            };
                            assets:         {source: string}[];
                            featuredAsset:  {source: string};
                            id:             string;
                            name:           string;
                            price:          number;
                            taxRateApplied: {
                                id: string;
                            };
                            customFields:   {
                                max_units_per_order?: number;
                            };
                        }[];
                    };
                }[]
                customFields:{
                    enabled: boolean
                }
                id:              string;
                translations:    {
                    name:         string;
                    description:  string;
                    languageCode: string;
                }[];
                productVariants: {
                    items: {
                        product:        {
                            id:            string;
                            assets:        {
                                id:     string;
                                source: string;
                            }[];
                            featuredAsset: {
                                id:     string;
                                source: string;
                            };
                            facetValues: {
                                code: string
                                translations: {
                                    languageCode: string
                                    name: string
                                }[]
                                facet: {
                                    id: number
                                    code: string
                                    translations: {
                                        languageCode: string
                                        name: string
                                    }[]
                                }
                            }[];
                        };
                        assets:         {source: string}[];
                        featuredAsset:  {source: string};
                        id:             string;
                        name:           string;
                        price:          number;
                        taxRateApplied: {
                            id: string;
                        };
                        customFields:   {
                            max_units_per_order?: number;
                        };
                    }[];
                };
                name:            string;
                slug:            string;
                featuredAsset?:   {source: string};
            }[];
        };
    };
}

const makeQuery = (filter: {id?: {eq?: string | number, in?: string[]}} = {}): string=>`{
        collections(options: {filter: {id: {${filter?.id?.eq ? `eq: "${filter.id.eq}"` : `in: ${JSON.stringify(filter.id?.in)}`}}}}) {
            items{
                id,
              breadcrumbs{
                name
                id
                slug
              }
              customFields{
                enabled
               }
              children {                
                id
                breadcrumbs{
                    name
                    id
                    slug
                }
                customFields{
                    enabled
                }
                translations{
                    name,
                    description,
                    languageCode
                }
                productVariants {
                    items{
                        product {
                            id
                            translations {
                                languageCode
                                name
                                description
                            }
                            assets {
                                id
                                source
                            }
                            featuredAsset {
                                id
                                source
                            }
                            facetValues{
                                translations{
                                  languageCode
                                  name
                                }
                                facet{
                                  id
                                  code
                                  translations {
                                    languageCode
                                    name
                                  }
                                }
                            }
                        }
                        assets{
                            id,
                                source
                        }
                        featuredAsset {
                            id
                            source
                        }
                        id
                        name,
                        price
                        priceWithTax
                        taxRateApplied{
                            id
                        }
                        customFields{
                            max_units_per_order
                        }
                        translations{
                            name,
                            languageCode
                        }
                    }
                }               
              }
                translations{
                    name,
                    description,
                    languageCode
                },
                productVariants {
                    items{
                        product {
                            id
                            translations {
                                languageCode
                                name
                                description
                            }
                            assets {
                                id
                                source
                            }
                            featuredAsset {
                                id
                                source
                            }
                            facetValues{
                                translations{
                                  languageCode
                                  name
                                }
                                facet{
                                  id
                                  code
                                  translations {
                                    languageCode
                                    name
                                  }
                                }
                            }
                        }
                        assets{
                            id,
                                source
                        }
                        featuredAsset {
                            id
                            source
                        }
                        id
                        name,
                        price
                        priceWithTax
                        taxRateApplied{
                            id
                        }
                        customFields{
                            max_units_per_order
                        }
                        translations{
                            name,
                            languageCode
                        }
                    }
                },
                name,
                    slug,
                    featuredAsset {
                    source
                }
            }
        }}`;

const itemsMapper = (item : NonNullable<QueryResult['data']["collections"]["items"][number]>)=>{
    const languageCode = localStorage.getItem("lang") || "en";
    return {
        ...item,
        featuredAsset: item.featuredAsset ? {source: `${getEnvVar('VENDURE')}${item.featuredAsset.source}`}: undefined,
        children: item.children.map(item => ({ ...item, productVariants: {...item.productVariants, items: item.productVariants.items.map(
        variant=>({...variant, product: {
                                        ...variant.product,  
                                        featuredAsset: variant.product.featuredAsset ? {source: `${getEnvVar('VENDURE')}${variant.product.featuredAsset.source}`}: undefined,
                                        tags: variant.product.facetValues.filter(x => x.facet.code.startsWith('zafiro_labels')).map(x => x.translations.find(x => x.languageCode === languageCode)?.name ?? x.translations.find(x => x.languageCode === 'en')?.name ?? x.translations[0]!.name),
                                    }        
                  })
        )}})),
        productVariants: {
            items: item.productVariants.items.map(variant=>({
                ...variant,
                featuredAsset: variant.featuredAsset ? {source: `${getEnvVar('VENDURE')}${variant.featuredAsset.source}`}: undefined,
                assets: variant.assets.map(x=>({source: `${getEnvVar('VENDURE')}${x.source}`})),
                product: {
                    ...variant.product,
                    tags: variant.product.facetValues.filter(x => x.facet.code.startsWith('zafiro_labels')).map(x => x.translations.find(x => x.languageCode === languageCode)?.name ?? x.translations.find(x => x.languageCode === 'en')?.name ?? x.translations[0]!.name),
                    featuredAsset: variant.product.featuredAsset ? {source: `${getEnvVar('VENDURE')}${variant.product.featuredAsset.source}`}: undefined,
                    assets: variant.product.assets.map(x=>({source: `${getEnvVar('VENDURE')}${x.source}`}))
                }
            }))
        }
    }
}


export default async ({channel, parent_id}: {channel: string, parent_id?: string})=>{
    const category: QueryResult = await promisedVendureQuery({
        apiType: "shop",
        cache: true,
        headers: {'vendure-token': channel},
        body: makeQuery(parent_id ? {id: {eq: parent_id}}: {}),
    });
    const childCategoryIds = category.data.collections.items.map(x=>x.children.map(x=>x.id))[0] ?? [];
    const _childCategories: QueryResult = childCategoryIds.length === 0 ? {data: {collections: {items: []}}} : await promisedVendureQuery({
        apiType: "shop",
        cache: true,
        headers: {'vendure-token': channel},
        body: makeQuery(parent_id ? {id: {in: childCategoryIds}} : {}),
    });
    //Items mapper modifica el producto en bruto que devuelve vendure para añadir URLs absolutas a las imágenes
    const parentCategories = category.data.collections.items.map(itemsMapper);
    //Filtramos las categorías que tienen el customField enabled a true
    const childCategories = _childCategories.data.collections.items.filter(x => x.customFields.enabled).map(itemsMapper);
    const childCategoriesItemsIds = new Set(childCategories.map(x=>x.productVariants.items.map(x=>x.id)).flat());
    //Dejamos en la categoría padre sólo los productos que no tienen subcategoría para que los productos con subcategoría no aparezcan en dos sitios
    return [
        ...childCategories,
        ...parentCategories.map((parentCategory)=>{
            return {...parentCategory, productVariants: {items: parentCategory.productVariants.items.filter(x=>!childCategoriesItemsIds.has(x.id))}};
        })
    ];
}