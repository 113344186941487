export const SET_KEY_PRESSED = "EVENTS_SET_KEY_PRESSED";
export const SET_FOCUS_TO_RESTART = "EVENTS_SET_FOCUS_TO_RESTART";
export const SET_NUMPAD_CODE = "EVENTS_SET_NUMPAD_CODE";
export const SET_NUMBER_PRESS = "EVENTS_SET_NUMBER_PRESS";
export const SET_MEDIA_EVENT = "EVENTS_SET_MEDIA_EVENT";
export const SET_ACTIVE_MENU_BUTTON = "EVENTS_SET_ACTIVE_MENU_BUTTON";
export const SET_ACTIVE_MENU_RESTART = "EVENTS_SET_ACTIVE_MENU_RESTART";

export function setKeyPressed(key) {
    return (dispatch) => {
        dispatch({
            type: SET_KEY_PRESSED,
            payload: key,
        });
    };
}

export function setFocusToRestart(id) {
    return (dispatch) => {
        dispatch({
            type: SET_FOCUS_TO_RESTART,
            payload: id,
        });
    };
}

export function setNumpadCode(code) {
    return (dispatch) => {
        dispatch({
            type: SET_NUMPAD_CODE,
            payload: code,
        });
    };
}

export function setNumberPress(number) {
    return (dispatch) => {
        dispatch({
            type: SET_NUMBER_PRESS,
            payload: number,
        });
    };
}
export function setMediaEvent(event) {
    return (dispatch) => {
        dispatch({
            type: SET_MEDIA_EVENT,
            payload: event,
        });
    };
}
export function setActiveMenuBtnID(id) {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_MENU_BUTTON,
            payload: id,
        });
    };
}
export function setActiveMenuToRestart(id) {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_MENU_RESTART,
            payload: id,
        });
    };
}
