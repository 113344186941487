import {
    SET_KEY_PRESSED,
    SET_NUMPAD_CODE,
    SET_FOCUS_TO_RESTART,
    SET_NUMBER_PRESS,
    SET_MEDIA_EVENT,
    SET_ACTIVE_MENU_BUTTON,
    SET_ACTIVE_MENU_RESTART,
} from "../actions/eventsActions";

const initialState = {
    keyDown: null,
    lastKeyPressed: null,
    keysHistory: [],
    activeMenuBtnId: null,
    activeMenuToRestart: null,
    focusToRestart: null,
    numpadCode: null,
    numberPress: null,
    mediaEvent: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_KEY_PRESSED:
            state.keysHistory.unshift(action.payload);
            if (state.keysHistory.length > 5) {
                state.keysHistory = state.keysHistory.slice(0, -1);
            }
            return {
                ...state,
                lastKeyPressed: action.payload,
                keysHistory: [...state.keysHistory],
            };

        case SET_FOCUS_TO_RESTART:
            return {
                ...state,
                focusToRestart: action.payload,
            };

        case SET_NUMPAD_CODE:
            return {
                ...state,
                numpadCode: action.payload,
            };
        case SET_MEDIA_EVENT:
            if (!action.payload) {
                return { ...state, mediaEvent: null };
            }
            return {
                ...state,
                mediaEvent: { code: action.payload, timestamp: new Date().getTime() },
            };

        case SET_NUMBER_PRESS:
            if (typeof action.payload === "number" && action.payload > -1) {
                if (!state.numberPress && action.payload === 0) {
                    state.numberPress = null;
                } else {
                    state.numberPress = `${state.numberPress || ""}${action.payload}`;
                }
            } else {
                state.numberPress = null;
            }
            return {
                ...state,
                numberPress: state.numberPress,
            };
        case SET_ACTIVE_MENU_BUTTON:
            return {
                ...state,
                activeMenuBtnId: action.payload,
            };
        case SET_ACTIVE_MENU_RESTART:
            return {
                ...state,
                activeMenuToRestart: action.payload,
            };
        default:
            return state;
    }
}
