import { getLanguageNameFromIso } from "../../../../utils/utils";
import { samsung } from "./samsung";

export const Media = {
    currentVideoSize: null,
    init() {
        samsung.video.init();
    },
    _stop() {
        samsung.video.stop();
    },
    setVideoSize(x, y, width, height) {
        samsung.video.resize(x, y, width, height);
    },

    _playChannel(ip, port) {
        samsung.video.tune(ip, port);
    },

    getAudioList(onSuccess) {
        let tracks = samsung.video.getAudioTracks();
        tracks = tracks ? tracks.items : null;
        if (!tracks) return;
        let audioList = [];
        for (let i = 0; i < tracks.length; i++) {
            audioList.push({
                id: i,
                name: getLanguageNameFromIso(samsung.int2text(tracks[i].lang)),
                iso: samsung.int2text(tracks[i].lang),
            });
        }
        onSuccess(audioList);
    },

    getSubtitleList(onSuccess) {
        let tracks = samsung.video.getSubtitleTracks();
        tracks = tracks ? tracks.subtitle : null;
        //console.debug("tracks : " + JSON.stringify(tracks));
        if (!tracks) return;
        let subtitleList = [];
        for (let i = 0; i < tracks.length; i++) {
            subtitleList.push({
                id: i,
                name: getLanguageNameFromIso(samsung.int2text(tracks[i].lang)),
            });
        }
        onSuccess(subtitleList);
    },

    changeAudio(ind) {
        samsung.video.changeAudio(ind);
    },

    changeSubtitle(ind) {
        samsung.video.setSubs(1);
        samsung.video.changeSubtitle(ind);
    },

    disableSubtitles() {
        samsung.video.hideSubs();
    },
};
