import { checkDeviceType } from "./utils";
import { keyMap as philips } from "../hooks/apis/philips/keyMap";
import { keyMap as tizen } from "../hooks/apis/samsung/tizen/keyMap";
import { keyMap as orsay } from "../hooks/apis/samsung/orsay/keyMap";
import { keyMap as stb } from "../hooks/apis/stb/keyMap";
import { keyMap as lg } from "../hooks/apis/procentric/keyMap";
import { keyMap as google } from "../hooks/apis/googleTV/keyMap";
import { KEYS } from "../utils/keys";
import { eventHandler } from "./eventHandler";
const base_keymap = [
    { code: 13, value: KEYS.enter },
    { code: 32, value: KEYS.back },
    { code: 72, value: KEYS.home },
    { code: 76, value: KEYS.lang },

    { code: 37, value: KEYS.left, type: "arrow" },
    { code: 38, value: KEYS.up, type: "arrow" },
    { code: 39, value: KEYS.right, type: "arrow" },
    { code: 40, value: KEYS.down, type: "arrow" },

    { code: 48, value: 0, type: "numeric" },
    { code: 49, value: 1, type: "numeric" },
    { code: 50, value: 2, type: "numeric" },
    { code: 51, value: 3, type: "numeric" },
    { code: 52, value: 4, type: "numeric" },
    { code: 53, value: 5, type: "numeric" },
    { code: 54, value: 6, type: "numeric" },
    { code: 55, value: 7, type: "numeric" },
    { code: 56, value: 8, type: "numeric" },
    { code: 57, value: 9, type: "numeric" },

    { code: 77, value: KEYS.messages },
    { code: 66, value: KEYS.checkout },
] as const;
const maps = {
    PC: [],
    STB: stb,
    Philips: philips,
    LG: lg,
    "Samsung-Tizen": tizen,
    "Samsung-Orsay": orsay,
    GoogleTV: google,
};

const device = checkDeviceType();
// @ts-ignore
const keymap: { code: number; value: string }[] = [...base_keymap, ...maps[device]];

type EventHandlerType<T extends KeyName[]> = (
    keyName: T[number],
    ev: KeyboardEvent,
) => { stopPropagation?: boolean; preventDefault?: boolean } | undefined;
export const makeKeyboardEvent = <T extends KeyName[]>(
    keyNames: T,
    handler: EventHandlerType<T>,
): ((ev: KeyboardEvent) => void) => {
    const codeToNameMap = new Map<number, T[number]>(
        keyNames.map((x) => {
            const mapping = keymap.find((y) => y.value === x) as { code: number; value: T[number] };
            return [mapping.code, mapping.value];
        }),
    );
    return (ev) => {
        const keyCode = ev.keyCode ?? ev.which;
        const keyName = codeToNameMap.get(keyCode) as T[number];
        if (keyNames.indexOf(keyName) === -1) return;
        const modifiers = handler(keyName, ev) ?? {};
        Object.keys(modifiers).forEach((k) => ev[k as keyof NonNullable<ReturnType<EventHandlerType<T>>>]());
        if (modifiers.preventDefault) ev.preventDefault();
        if (modifiers.stopPropagation) {
            ev.stopImmediatePropagation();
            eventHandler.stopPropagation(ev);
        }
    };
};
