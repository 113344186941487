import Button from "../common/button";
import Modal from "../common/modal";
import { Texts } from "../../utils/texts";
import { useDispatch, useSelector } from "react-redux";
import { setPopUp } from "../../actions/uiActions";
import { STB } from "../../hooks/apis/stb";
import { navigate } from "wouter/use-location";
import { useState } from "preact/hooks";
import Calculator from "../common/calculator";
import ReactHtmlParser from "react-html-parser";

export const AdultContentConfirm = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const [error, setError] = useState(null);

    const parentalCode = sessionStorage.getItem("parentalCode");

    const closeConfirmation = () => {
        dispatch(setPopUp(null));
    };

    const goToAdultContent = () => {
        closeConfirmation();
        // go to adult
        navigate(`/adult-movies`, { replace: false });
    };

    return parentalCode ? (
        <Modal
            title={Texts.translate(texts, "adult-movies")}
            subtitle={ReactHtmlParser(
                `${Texts.translate(texts, "adult-movies-warning")}<br /><br />${Texts.translate(
                    texts,
                    "confirm-parental-code",
                )}`,
            )}
            titleClass={"pt-6"}
            backBtn={true}
            preventCloseOnBack={true}
            backAction={closeConfirmation}
            body={
                <Calculator
                    code={parentalCode}
                    encrypt={parentalCode}
                    confirmText={Texts.translateCap(texts, "confirm")}
                    confirmIcon="room"
                    error={error}
                    confirmAction={(code) => {
                        if (code === parentalCode) {
                            goToAdultContent();
                        } else {
                            setError(Texts.translate(texts, "wrong parental code"));
                        }
                    }}
                    setError={setError}
                />
                // </>
            }
        />
    ) : (
        <Modal
            width="69.583vw"
            height="62.222vh"
            backBtn={true}
            backBtnClass={"mb-4"}
            title={Texts.translateCap(texts, "adult-movies")}
            titleClass={`text-center mt-8 mb-8 text-4xl pt-8`}
            customClass="text-center"
            firstFocus={`btn-continue`}
            body={
                <>
                    <div class={`text-xl text-center w-full text-2xl`} style={{ height: "26vh" }}>
                        <div>{Texts.translateCap(texts, "adult-movies-warning")}</div>
                        <div class={"mt-8"}>{Texts.translateCap(texts, "sure-to-continue")}</div>
                    </div>
                    <div style={{ bottom: 0, display: "flex" }}>
                        <div class={"py-6 mx-auto "} style={{ width: "44.583vw" }}>
                            <Button
                                insideModal={true}
                                data={{
                                    id: "continue",
                                    name: Texts.translateCap(texts, "continue"),
                                    border: true,
                                    customClass: " py-2 px-4 text-lg text-center rounded float-left",
                                    customStyle: {
                                        width: "100%",
                                    },
                                    onClick() {
                                        goToAdultContent();
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        />
    );
};
