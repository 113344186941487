import focus from "./focus";

export type Category = {
    id: string;
    name: string;
    items: Group[];
};
type Group = {
    id: number;
    name: string;
    items: any[];
};

export const getValueOf = (unit: string): number => {
    var terminations = ["px", "vh", "vw", "rem", "em"];
    var _value = 0;
    terminations.forEach((termination) => {
        if (unit.indexOf(termination) > -1) {
            _value = parseFloat(unit.replace(termination, ""));
        }
    });

    return _value;
};

export const getGridItemFocusedData = (activeCategory: Category) => {
    const gridItemType = activeCategory.items[0]?.items[0]?.type;
    if (
        !focus.value.current &&
        (focus.value.current?.indexOf(`_${gridItemType}_`) === -1 || focus.value.current?.indexOf("showAll") === -1)
    ) {
        return null;
    }
    if (focus.value.current && focus.value.current?.indexOf("showAll") > -1) {
        // FOCUS show all button - takes data of movie right before showAll
        const groupName = focus.value.current.split("-")[1];
        return activeCategory.items.filter((group: Group) => group.name === groupName)?.[0]?.items[14];
    }
    const gridFocusedId = document.getElementById(focus?.value?.current || "")?.getAttribute("customattribute") || "";
    let groupIndex = parseInt(
        document
            .getElementById(focus?.value?.current || "")
            ?.getAttribute("itemid")
            ?.split("_")[0] || "0",
    );
    return activeCategory.items[groupIndex]?.items.filter(
        (item: any) => parseInt(item.id) === parseInt(gridFocusedId),
    )?.[0];
};

export const focusFirstItemOfCategory = (category: Category) => {
    const _groupIdToFocus = category?.items?.[0]?.id;
    const _itemIdToFocus = category?.items?.[0]?.items?.[0]?.id;
    const _elementType = category?.items?.[0]?.items?.[0]?.type;
    if (_itemIdToFocus) {
        const elementToFocus = `btn-${_groupIdToFocus}_${_elementType}_${_itemIdToFocus}`;
        focus.value.replace(elementToFocus);
    }
};
