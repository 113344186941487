import { KEYS } from "../../../../utils/keys";

export const keyMap = [
    { code: 29443, value: KEYS.enter },
    { code: 88, value: KEYS.back },
    { code: 1118, value: KEYS.home },
    { code: 20, value: KEYS.lang },

    { code: 4, value: KEYS.left, type: "arrow" },
    { code: 29460, value: KEYS.up, type: "arrow" },
    { code: 5, value: KEYS.right, type: "arrow" },
    { code: 29461, value: KEYS.down, type: "arrow" },

    { code: 17, value: 0, type: "numeric" },
    { code: 101, value: 1, type: "numeric" },
    { code: 98, value: 2, type: "numeric" },
    { code: 6, value: 3, type: "numeric" },
    { code: 8, value: 4, type: "numeric" },
    { code: 9, value: 5, type: "numeric" },
    { code: 10, value: 6, type: "numeric" },
    { code: 12, value: 7, type: "numeric" },
    { code: 13, value: 8, type: "numeric" },
    { code: 14, value: 9, type: "numeric" },

    { code: 21, value: KEYS.messages },
    { code: 68, value: KEYS.channelUp },
    { code: 65, value: KEYS.channelDown },
];
