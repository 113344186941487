import shops from "./shops";
import shopByToken from "./shopByToken";
import categories from "./categories";
import product from "./product";
import variant from "./variant";
import design from "./design";
import subcategories from "./subcategories";
import order from "./order";
import orderById from "./orderById";
import orderInfo from "./orderInfo";
import extra from "./extra";
import myOrders from "./myOrders";
import shippingMethods from "./shippingMethods";
import pickupLocations from "./pickupLocations";


export default {
    shops,
    shopByToken,
    categories,
    product,
    variant,
    design,
    order,
    extra,
    orderById,
    orderInfo,
    subcategories,
    cart: (_customer: string) => [],
    myOrders,
    shippingMethods,
    pickupLocations
};
