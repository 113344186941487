import { memo, useEffect } from "preact/compat";
import { useDispatch, useSelector } from "react-redux";
import { navigateTo, setPopUp } from "../../actions/uiActions";
import { Texts } from "../../utils/texts";
import Button from "../../components/common/button";
import Query from "../../hooks/query";
import { padZero } from "../../utils/utils";
import { Media } from "../../hooks/apis/media";
import { STB } from "../../hooks/apis/stb";
import { setAlarm, updateStatus } from "../../actions/statusActions";
import focus from "../../utils/focus";
import { useLocation } from "wouter/preact";
import { useRef } from "react";

const AlarmActive = () => {
    const dispatch = useDispatch();
    const [location] = useLocation();

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const alarm = useSelector((state) => state.status.sessionData.alarm);
    const locationData = useSelector((state) => state.status.locationData);
    const sessionData = useSelector((state) => state.status.sessionData);

    const needToRestoreVideo = useRef();

    //listeners
    useEffect(() => {
        STB.setVolume(STB.alarmVolume);
        if (Media.playingChannel || Media.playingMedia) {
            Media.stop({ keepLastVideo: true });
            needToRestoreVideo.current = true;
        }

        //PLAY BEEP
        if (alarm && alarm.type === "TVCHANNEL") {
            //PLAY CHANNEL
            localStorage.setItem("channel_to_restore", alarm.tvChannelId);
            Media.playingAlarm = true;
            setTimeout(() => {
                dispatch(
                    navigateTo({
                        layout: "tv",
                    }),
                );
                focus.value.replace("btn-stop");
            }, 300);
        } else if (alarm && alarm.type === "BEEP") {
            Media.playAlarm();
            Media.ensureAlarmTimeout = setTimeout(() => {
                if (!Media.playingAlarm) {
                    Media.playAlarm();
                }
            }, 5000);
            focus.value.replace("btn-snooze-alarm");
        } else {
            focus.value.replace("btn-exit");
        }

        //Auto stop alarm after 15 minutes
        clearTimeout(Media.alarmTimeout);

        Media.alarmTimeout = setTimeout(() => {
            Media.stopAlarm();
            dispatch(setPopUp(null));

            Query({
                query: `
                        mutation{ 
                            updateAlarm (
                                id: ${alarm.id}
                                state: IGNORED
                            ) {
                                error{
                                    code
                                    msg
                                },
                                ok
                            }
                        }
                `,
                onResponse() {
                    dispatch(setPopUp("null"));
                },
            });
        }, Media.ALARM_STOP_TIMEOUT);
        return () => {
            Media.stopAlarm();
            if (needToRestoreVideo.current) {
                Media.replayLastVideo();
            }
            clearTimeout(Media.alarmTimeout);
            clearTimeout(Media.ensureAlarmTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Functions

    const stopAlarm = () => {
        Media.stopAlarm();

        Query({
            query: `
                    mutation{ 
                        updateAlarm (
                            id: ${alarm.id}
                            state: ATTENDED
                        ) {
                            error{
                                code
                                msg
                            }
                            ok
                        }
                    }
            `,
            onResponse() {
                dispatch(setAlarm(null));
                dispatch(setPopUp("alarmOFF"));
            },
        });
    };

    const snoozeAlarm = () => {
        const alarmTime = new Date();
        alarmTime.setTime(alarmTime.getTime() + 10 * 60 * 1000); // Snooze alarm 10 minutes later

        Query({
            query: `
            mutation{ 
            createAlarm(
                       type: BEEP
                       stayGuestRoomId: ${
                           sessionData.stayguest.stayGuestRooms.filter((r) => r.roomID === sessionData.room.id)[0].id
                       }
                       roomTvId: ${locationData.roomInfo.roomTvId}
                       startTime: "${alarmTime.toISOString()}"
                    ){
                        error
                        ok
                }
            }
            `,
            onResponse(res) {
                Media.snoozeAlarm();
                if (res?.data?.createAlarm?.ok) {
                    dispatch(updateStatus());
                    dispatch(setPopUp(null));
                }
            },
        });
    };

    return (
        <div className="text-center">
            <div className="text-5xl table mx-auto " style={{ marginBottom: "5vh", marginTop: "3vh" }}>
                <div className="icon icon-alarm float-left pr-4" />
                <div className="float-left">
                    {alarm
                        ? `${padZero(new Date(alarm.startTime).getHours())}:${padZero(
                              new Date(alarm.startTime).getMinutes(),
                          )}`
                        : null}
                </div>
            </div>

            {alarm && alarm.type === "BEEP" ? (
                <Button
                    insideModal={true}
                    data={{
                        id: "snooze-alarm",
                        name: Texts.capitalize(
                            texts["snooze {{data}} minutes"].replace("{{data}}", Media.ALARM_SNOOZE_TIMEOUT / 60000),
                        ),
                        border: true,
                        customClass: "py-2  w-full text-lg text-center rounded",
                        onClick() {
                            snoozeAlarm();
                        },
                    }}
                />
            ) : null}

            <Button
                insideModal={true}
                data={{
                    id: "stop",
                    name: Texts.capitalize(texts["stop"]),
                    border: true,
                    customClass: "py-2 mt-3  w-full text-lg text-center rounded",

                    onClick() {
                        stopAlarm();
                    },
                }}
            />
        </div>
    );
};

export default memo(AlarmActive);
