const iso6392 = [
    {
        name: "Afar",
        iso6392B: "aar",
        iso6391: "aa",
    },
    {
        name: "Abkhazian",
        iso6392B: "abk",
        iso6391: "ab",
    },
    {
        name: "Achinese",
        iso6392B: "ace",
    },
    {
        name: "Acoli",
        iso6392B: "ach",
    },
    {
        name: "Adangme",
        iso6392B: "ada",
    },
    {
        name: "Adyghe; Adygei",
        iso6392B: "ady",
    },
    {
        name: "Afro-Asiatic languages",
        iso6392B: "afa",
    },
    {
        name: "Afrihili",
        iso6392B: "afh",
    },
    {
        name: "Afrikaans",
        iso6392B: "afr",
        iso6391: "af",
    },
    {
        name: "Ainu",
        iso6392B: "ain",
    },
    {
        name: "Akan",
        iso6392B: "aka",
        iso6391: "ak",
    },
    {
        name: "Akkadian",
        iso6392B: "akk",
    },
    {
        name: "Albanian",
        iso6392B: "alb",
        iso6392T: "sqi",
        iso6391: "sq",
    },
    {
        name: "Aleut",
        iso6392B: "ale",
    },
    {
        name: "Algonquian languages",
        iso6392B: "alg",
    },
    {
        name: "Southern Altai",
        iso6392B: "alt",
    },
    {
        name: "Amharic",
        iso6392B: "amh",
        iso6391: "am",
    },
    {
        name: "English, Old (ca.450-1100)",
        iso6392B: "ang",
    },
    {
        name: "Angika",
        iso6392B: "anp",
    },
    {
        name: "Apache languages",
        iso6392B: "apa",
    },
    {
        name: "Arabic",
        iso6392B: "ara",
        iso6391: "ar",
    },
    {
        name: "Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)",
        iso6392B: "arc",
    },
    {
        name: "Aragonese",
        iso6392B: "arg",
        iso6391: "an",
    },
    {
        name: "Armenian",
        iso6392B: "arm",
        iso6392T: "hye",
        iso6391: "hy",
    },
    {
        name: "Mapudungun; Mapuche",
        iso6392B: "arn",
    },
    {
        name: "Arapaho",
        iso6392B: "arp",
    },
    {
        name: "Artificial languages",
        iso6392B: "art",
    },
    {
        name: "Arawak",
        iso6392B: "arw",
    },
    {
        name: "Assamese",
        iso6392B: "asm",
        iso6391: "as",
    },
    {
        name: "Asturian; Bable; Leonese; Asturleonese",
        iso6392B: "ast",
    },
    {
        name: "Athapascan languages",
        iso6392B: "ath",
    },
    {
        name: "Australian languages",
        iso6392B: "aus",
    },
    {
        name: "Avaric",
        iso6392B: "ava",
        iso6391: "av",
    },
    {
        name: "Avestan",
        iso6392B: "ave",
        iso6391: "ae",
    },
    {
        name: "Awadhi",
        iso6392B: "awa",
    },
    {
        name: "Aymara",
        iso6392B: "aym",
        iso6391: "ay",
    },
    {
        name: "Azerbaijani",
        iso6392B: "aze",
        iso6391: "az",
    },
    {
        name: "Banda languages",
        iso6392B: "bad",
    },
    {
        name: "Bamileke languages",
        iso6392B: "bai",
    },
    {
        name: "Bashkir",
        iso6392B: "bak",
        iso6391: "ba",
    },
    {
        name: "Baluchi",
        iso6392B: "bal",
    },
    {
        name: "Bambara",
        iso6392B: "bam",
        iso6391: "bm",
    },
    {
        name: "Balinese",
        iso6392B: "ban",
    },
    {
        name: "Basque",
        iso6392B: "baq",
        iso6392T: "eus",
        iso6391: "eu",
    },
    {
        name: "Basa",
        iso6392B: "bas",
    },
    {
        name: "Baltic languages",
        iso6392B: "bat",
    },
    {
        name: "Beja; Bedawiyet",
        iso6392B: "bej",
    },
    {
        name: "Belarusian",
        iso6392B: "bel",
        iso6391: "be",
    },
    {
        name: "Bemba",
        iso6392B: "bem",
    },
    {
        name: "Bengali",
        iso6392B: "ben",
        iso6391: "bn",
    },
    {
        name: "Berber languages",
        iso6392B: "ber",
    },
    {
        name: "Bhojpuri",
        iso6392B: "bho",
    },
    {
        name: "Bihari languages",
        iso6392B: "bih",
        iso6391: "bh",
    },
    {
        name: "Bikol",
        iso6392B: "bik",
    },
    {
        name: "Bini; Edo",
        iso6392B: "bin",
    },
    {
        name: "Bislama",
        iso6392B: "bis",
        iso6391: "bi",
    },
    {
        name: "Siksika",
        iso6392B: "bla",
    },
    {
        name: "Bantu languages",
        iso6392B: "bnt",
    },
    {
        name: "Bosnian",
        iso6392B: "bos",
        iso6391: "bs",
    },
    {
        name: "Braj",
        iso6392B: "bra",
    },
    {
        name: "Breton",
        iso6392B: "bre",
        iso6391: "br",
    },
    {
        name: "Batak languages",
        iso6392B: "btk",
    },
    {
        name: "Buriat",
        iso6392B: "bua",
    },
    {
        name: "Buginese",
        iso6392B: "bug",
    },
    {
        name: "Bulgarian",
        iso6392B: "bul",
        iso6391: "bg",
    },
    {
        name: "Burmese",
        iso6392B: "bur",
        iso6392T: "mya",
        iso6391: "my",
    },
    {
        name: "Blin; Bilin",
        iso6392B: "byn",
    },
    {
        name: "Caddo",
        iso6392B: "cad",
    },
    {
        name: "Central American Indian languages",
        iso6392B: "cai",
    },
    {
        name: "Galibi Carib",
        iso6392B: "car",
    },
    {
        name: "Catalan; Valencian",
        iso6392B: "cat",
        iso6391: "ca",
    },
    {
        name: "Caucasian languages",
        iso6392B: "cau",
    },
    {
        name: "Cebuano",
        iso6392B: "ceb",
    },
    {
        name: "Celtic languages",
        iso6392B: "cel",
    },
    {
        name: "Chamorro",
        iso6392B: "cha",
        iso6391: "ch",
    },
    {
        name: "Chibcha",
        iso6392B: "chb",
    },
    {
        name: "Chechen",
        iso6392B: "che",
        iso6391: "ce",
    },
    {
        name: "Chagatai",
        iso6392B: "chg",
    },
    {
        name: "Chinese",
        iso6392B: "chi",
        iso6392T: "zho",
        iso6391: "zh",
    },
    {
        name: "Chuukese",
        iso6392B: "chk",
    },
    {
        name: "Mari",
        iso6392B: "chm",
    },
    {
        name: "Chinook jargon",
        iso6392B: "chn",
    },
    {
        name: "Choctaw",
        iso6392B: "cho",
    },
    {
        name: "Chipewyan; Dene Suline",
        iso6392B: "chp",
    },
    {
        name: "Cherokee",
        iso6392B: "chr",
    },
    {
        name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
        iso6392B: "chu",
        iso6391: "cu",
    },
    {
        name: "Chuvash",
        iso6392B: "chv",
        iso6391: "cv",
    },
    {
        name: "Cheyenne",
        iso6392B: "chy",
    },
    {
        name: "Chamic languages",
        iso6392B: "cmc",
    },
    {
        name: "Montenegrin",
        iso6392B: "cnr",
    },
    {
        name: "Coptic",
        iso6392B: "cop",
    },
    {
        name: "Cornish",
        iso6392B: "cor",
        iso6391: "kw",
    },
    {
        name: "Corsican",
        iso6392B: "cos",
        iso6391: "co",
    },
    {
        name: "Creoles and pidgins, English based",
        iso6392B: "cpe",
    },
    {
        name: "Creoles and pidgins, French-based",
        iso6392B: "cpf",
    },
    {
        name: "Creoles and pidgins, Portuguese-based",
        iso6392B: "cpp",
    },
    {
        name: "Cree",
        iso6392B: "cre",
        iso6391: "cr",
    },
    {
        name: "Crimean Tatar; Crimean Turkish",
        iso6392B: "crh",
    },
    {
        name: "Creoles and pidgins",
        iso6392B: "crp",
    },
    {
        name: "Kashubian",
        iso6392B: "csb",
    },
    {
        name: "Cushitic languages",
        iso6392B: "cus",
    },
    {
        name: "Czech",
        iso6392B: "cze",
        iso6392T: "ces",
        iso6391: "cs",
    },
    {
        name: "Dakota",
        iso6392B: "dak",
    },
    {
        name: "Danish",
        iso6392B: "dan",
        iso6391: "da",
    },
    {
        name: "Dargwa",
        iso6392B: "dar",
    },
    {
        name: "Land Dayak languages",
        iso6392B: "day",
    },
    {
        name: "Delaware",
        iso6392B: "del",
    },
    {
        name: "Slave (Athapascan)",
        iso6392B: "den",
    },
    {
        name: "Dogrib",
        iso6392B: "dgr",
    },
    {
        name: "Dinka",
        iso6392B: "din",
    },
    {
        name: "Divehi; Dhivehi; Maldivian",
        iso6392B: "div",
        iso6391: "dv",
    },
    {
        name: "Dogri",
        iso6392B: "doi",
    },
    {
        name: "Dravidian languages",
        iso6392B: "dra",
    },
    {
        name: "Lower Sorbian",
        iso6392B: "dsb",
    },
    {
        name: "Duala",
        iso6392B: "dua",
    },
    {
        name: "Dutch, Middle (ca.1050-1350)",
        iso6392B: "dum",
    },
    {
        name: "Dutch; Flemish",
        iso6392B: "dut",
        iso6392T: "nld",
        iso6391: "nl",
    },
    {
        name: "Dyula",
        iso6392B: "dyu",
    },
    {
        name: "Dzongkha",
        iso6392B: "dzo",
        iso6391: "dz",
    },
    {
        name: "Efik",
        iso6392B: "efi",
    },
    {
        name: "Egyptian (Ancient)",
        iso6392B: "egy",
    },
    {
        name: "Ekajuk",
        iso6392B: "eka",
    },
    {
        name: "Elamite",
        iso6392B: "elx",
    },
    {
        name: "English",
        iso6392B: "eng",
        iso6391: "en",
    },
    {
        name: "English, Middle (1100-1500)",
        iso6392B: "enm",
    },
    {
        name: "Esperanto",
        iso6392B: "epo",
        iso6391: "eo",
    },
    {
        name: "Estonian",
        iso6392B: "est",
        iso6391: "et",
    },
    {
        name: "Ewe",
        iso6392B: "ewe",
        iso6391: "ee",
    },
    {
        name: "Ewondo",
        iso6392B: "ewo",
    },
    {
        name: "Fang",
        iso6392B: "fan",
    },
    {
        name: "Faroese",
        iso6392B: "fao",
        iso6391: "fo",
    },
    {
        name: "Fanti",
        iso6392B: "fat",
    },
    {
        name: "Fijian",
        iso6392B: "fij",
        iso6391: "fj",
    },
    {
        name: "Filipino; Pilipino",
        iso6392B: "fil",
    },
    {
        name: "Finnish",
        iso6392B: "fin",
        iso6391: "fi",
    },
    {
        name: "Finno-Ugrian languages",
        iso6392B: "fiu",
    },
    {
        name: "Fon",
        iso6392B: "fon",
    },
    {
        name: "French",
        iso6392B: "fre",
        iso6392T: "fra",
        iso6391: "fr",
    },
    {
        name: "French, Middle (ca.1400-1600)",
        iso6392B: "frm",
    },
    {
        name: "French, Old (842-ca.1400)",
        iso6392B: "fro",
    },
    {
        name: "Northern Frisian",
        iso6392B: "frr",
    },
    {
        name: "Eastern Frisian",
        iso6392B: "frs",
    },
    {
        name: "Western Frisian",
        iso6392B: "fry",
        iso6391: "fy",
    },
    {
        name: "Fulah",
        iso6392B: "ful",
        iso6391: "ff",
    },
    {
        name: "Friulian",
        iso6392B: "fur",
    },
    {
        name: "Ga",
        iso6392B: "gaa",
    },
    {
        name: "Gayo",
        iso6392B: "gay",
    },
    {
        name: "Gbaya",
        iso6392B: "gba",
    },
    {
        name: "Germanic languages",
        iso6392B: "gem",
    },
    {
        name: "Georgian",
        iso6392B: "geo",
        iso6392T: "kat",
        iso6391: "ka",
    },
    {
        name: "German",
        iso6392B: "ger",
        iso6392T: "deu",
        iso6391: "de",
    },
    {
        name: "Geez",
        iso6392B: "gez",
    },
    {
        name: "Gilbertese",
        iso6392B: "gil",
    },
    {
        name: "Gaelic; Scottish Gaelic",
        iso6392B: "gla",
        iso6391: "gd",
    },
    {
        name: "Irish",
        iso6392B: "gle",
        iso6391: "ga",
    },
    {
        name: "Galician",
        iso6392B: "glg",
        iso6391: "gl",
    },
    {
        name: "Manx",
        iso6392B: "glv",
        iso6391: "gv",
    },
    {
        name: "German, Middle High (ca.1050-1500)",
        iso6392B: "gmh",
    },
    {
        name: "German, Old High (ca.750-1050)",
        iso6392B: "goh",
    },
    {
        name: "Gondi",
        iso6392B: "gon",
    },
    {
        name: "Gorontalo",
        iso6392B: "gor",
    },
    {
        name: "Gothic",
        iso6392B: "got",
    },
    {
        name: "Grebo",
        iso6392B: "grb",
    },
    {
        name: "Greek, Ancient (to 1453)",
        iso6392B: "grc",
    },
    {
        name: "Greek, Modern (1453-)",
        iso6392B: "gre",
        iso6392T: "ell",
        iso6391: "el",
    },
    {
        name: "Guarani",
        iso6392B: "grn",
        iso6391: "gn",
    },
    {
        name: "Swiss German; Alemannic; Alsatian",
        iso6392B: "gsw",
    },
    {
        name: "Gujarati",
        iso6392B: "guj",
        iso6391: "gu",
    },
    {
        name: "Gwich'in",
        iso6392B: "gwi",
    },
    {
        name: "Haida",
        iso6392B: "hai",
    },
    {
        name: "Haitian; Haitian Creole",
        iso6392B: "hat",
        iso6391: "ht",
    },
    {
        name: "Hausa",
        iso6392B: "hau",
        iso6391: "ha",
    },
    {
        name: "Hawaiian",
        iso6392B: "haw",
    },
    {
        name: "Hebrew",
        iso6392B: "heb",
        iso6391: "he",
    },
    {
        name: "Herero",
        iso6392B: "her",
        iso6391: "hz",
    },
    {
        name: "Hiligaynon",
        iso6392B: "hil",
    },
    {
        name: "Himachali languages; Western Pahari languages",
        iso6392B: "him",
    },
    {
        name: "Hindi",
        iso6392B: "hin",
        iso6391: "hi",
    },
    {
        name: "Hittite",
        iso6392B: "hit",
    },
    {
        name: "Hmong; Mong",
        iso6392B: "hmn",
    },
    {
        name: "Hiri Motu",
        iso6392B: "hmo",
        iso6391: "ho",
    },
    {
        name: "Croatian",
        iso6392B: "hrv",
        iso6391: "hr",
    },
    {
        name: "Upper Sorbian",
        iso6392B: "hsb",
    },
    {
        name: "Hungarian",
        iso6392B: "hun",
        iso6391: "hu",
    },
    {
        name: "Hupa",
        iso6392B: "hup",
    },
    {
        name: "Iban",
        iso6392B: "iba",
    },
    {
        name: "Igbo",
        iso6392B: "ibo",
        iso6391: "ig",
    },
    {
        name: "Icelandic",
        iso6392B: "ice",
        iso6392T: "isl",
        iso6391: "is",
    },
    {
        name: "Ido",
        iso6392B: "ido",
        iso6391: "io",
    },
    {
        name: "Sichuan Yi; Nuosu",
        iso6392B: "iii",
        iso6391: "ii",
    },
    {
        name: "Ijo languages",
        iso6392B: "ijo",
    },
    {
        name: "Inuktitut",
        iso6392B: "iku",
        iso6391: "iu",
    },
    {
        name: "Interlingue; Occidental",
        iso6392B: "ile",
        iso6391: "ie",
    },
    {
        name: "Iloko",
        iso6392B: "ilo",
    },
    {
        name: "Interlingua (International Auxiliary Language Association)",
        iso6392B: "ina",
        iso6391: "ia",
    },
    {
        name: "Indic languages",
        iso6392B: "inc",
    },
    {
        name: "Indonesian",
        iso6392B: "ind",
        iso6391: "id",
    },
    {
        name: "Indo-European languages",
        iso6392B: "ine",
    },
    {
        name: "Ingush",
        iso6392B: "inh",
    },
    {
        name: "Inupiaq",
        iso6392B: "ipk",
        iso6391: "ik",
    },
    {
        name: "Iranian languages",
        iso6392B: "ira",
    },
    {
        name: "Iroquoian languages",
        iso6392B: "iro",
    },
    {
        name: "Italian",
        iso6392B: "ita",
        iso6391: "it",
    },
    {
        name: "Javanese",
        iso6392B: "jav",
        iso6391: "jv",
    },
    {
        name: "Lojban",
        iso6392B: "jbo",
    },
    {
        name: "Japanese",
        iso6392B: "jpn",
        iso6391: "ja",
    },
    {
        name: "Judeo-Persian",
        iso6392B: "jpr",
    },
    {
        name: "Judeo-Arabic",
        iso6392B: "jrb",
    },
    {
        name: "Kara-Kalpak",
        iso6392B: "kaa",
    },
    {
        name: "Kabyle",
        iso6392B: "kab",
    },
    {
        name: "Kachin; Jingpho",
        iso6392B: "kac",
    },
    {
        name: "Kalaallisut; Greenlandic",
        iso6392B: "kal",
        iso6391: "kl",
    },
    {
        name: "Kamba",
        iso6392B: "kam",
    },
    {
        name: "Kannada",
        iso6392B: "kan",
        iso6391: "kn",
    },
    {
        name: "Karen languages",
        iso6392B: "kar",
    },
    {
        name: "Kashmiri",
        iso6392B: "kas",
        iso6391: "ks",
    },
    {
        name: "Kanuri",
        iso6392B: "kau",
        iso6391: "kr",
    },
    {
        name: "Kawi",
        iso6392B: "kaw",
    },
    {
        name: "Kazakh",
        iso6392B: "kaz",
        iso6391: "kk",
    },
    {
        name: "Kabardian",
        iso6392B: "kbd",
    },
    {
        name: "Khasi",
        iso6392B: "kha",
    },
    {
        name: "Khoisan languages",
        iso6392B: "khi",
    },
    {
        name: "Central Khmer",
        iso6392B: "khm",
        iso6391: "km",
    },
    {
        name: "Khotanese; Sakan",
        iso6392B: "kho",
    },
    {
        name: "Kikuyu; Gikuyu",
        iso6392B: "kik",
        iso6391: "ki",
    },
    {
        name: "Kinyarwanda",
        iso6392B: "kin",
        iso6391: "rw",
    },
    {
        name: "Kirghiz; Kyrgyz",
        iso6392B: "kir",
        iso6391: "ky",
    },
    {
        name: "Kimbundu",
        iso6392B: "kmb",
    },
    {
        name: "Konkani",
        iso6392B: "kok",
    },
    {
        name: "Komi",
        iso6392B: "kom",
        iso6391: "kv",
    },
    {
        name: "Kongo",
        iso6392B: "kon",
        iso6391: "kg",
    },
    {
        name: "Korean",
        iso6392B: "kor",
        iso6391: "ko",
    },
    {
        name: "Kosraean",
        iso6392B: "kos",
    },
    {
        name: "Kpelle",
        iso6392B: "kpe",
    },
    {
        name: "Karachay-Balkar",
        iso6392B: "krc",
    },
    {
        name: "Karelian",
        iso6392B: "krl",
    },
    {
        name: "Kru languages",
        iso6392B: "kro",
    },
    {
        name: "Kurukh",
        iso6392B: "kru",
    },
    {
        name: "Kuanyama; Kwanyama",
        iso6392B: "kua",
        iso6391: "kj",
    },
    {
        name: "Kumyk",
        iso6392B: "kum",
    },
    {
        name: "Kurdish",
        iso6392B: "kur",
        iso6391: "ku",
    },
    {
        name: "Kutenai",
        iso6392B: "kut",
    },
    {
        name: "Ladino",
        iso6392B: "lad",
    },
    {
        name: "Lahnda",
        iso6392B: "lah",
    },
    {
        name: "Lamba",
        iso6392B: "lam",
    },
    {
        name: "Lao",
        iso6392B: "lao",
        iso6391: "lo",
    },
    {
        name: "Latin",
        iso6392B: "lat",
        iso6391: "la",
    },
    {
        name: "Latino",
        iso6392B: "las",
        iso6391: "ls",
    },
    {
        name: "Latvian",
        iso6392B: "lav",
        iso6391: "lv",
    },
    {
        name: "Lezghian",
        iso6392B: "lez",
    },
    {
        name: "Limburgan; Limburger; Limburgish",
        iso6392B: "lim",
        iso6391: "li",
    },
    {
        name: "Lingala",
        iso6392B: "lin",
        iso6391: "ln",
    },
    {
        name: "Lithuanian",
        iso6392B: "lit",
        iso6391: "lt",
    },
    {
        name: "Mongo",
        iso6392B: "lol",
    },
    {
        name: "Lozi",
        iso6392B: "loz",
    },
    {
        name: "Luxembourgish; Letzeburgesch",
        iso6392B: "ltz",
        iso6391: "lb",
    },
    {
        name: "Luba-Lulua",
        iso6392B: "lua",
    },
    {
        name: "Luba-Katanga",
        iso6392B: "lub",
        iso6391: "lu",
    },
    {
        name: "Ganda",
        iso6392B: "lug",
        iso6391: "lg",
    },
    {
        name: "Luiseno",
        iso6392B: "lui",
    },
    {
        name: "Lunda",
        iso6392B: "lun",
    },
    {
        name: "Luo (Kenya and Tanzania)",
        iso6392B: "luo",
    },
    {
        name: "Lushai",
        iso6392B: "lus",
    },
    {
        name: "Macedonian",
        iso6392B: "mac",
        iso6392T: "mkd",
        iso6391: "mk",
    },
    {
        name: "Madurese",
        iso6392B: "mad",
    },
    {
        name: "Magahi",
        iso6392B: "mag",
    },
    {
        name: "Marshallese",
        iso6392B: "mah",
        iso6391: "mh",
    },
    {
        name: "Maithili",
        iso6392B: "mai",
    },
    {
        name: "Makasar",
        iso6392B: "mak",
    },
    {
        name: "Malayalam",
        iso6392B: "mal",
        iso6391: "ml",
    },
    {
        name: "Mandingo",
        iso6392B: "man",
    },
    {
        name: "Maori",
        iso6392B: "mao",
        iso6392T: "mri",
        iso6391: "mi",
    },
    {
        name: "Austronesian languages",
        iso6392B: "map",
    },
    {
        name: "Marathi",
        iso6392B: "mar",
        iso6391: "mr",
    },
    {
        name: "Masai",
        iso6392B: "mas",
    },
    {
        name: "Malay",
        iso6392B: "may",
        iso6392T: "msa",
        iso6391: "ms",
    },
    {
        name: "Moksha",
        iso6392B: "mdf",
    },
    {
        name: "Mandar",
        iso6392B: "mdr",
    },
    {
        name: "Mende",
        iso6392B: "men",
    },
    {
        name: "Irish, Middle (900-1200)",
        iso6392B: "mga",
    },
    {
        name: "Mi'kmaq; Micmac",
        iso6392B: "mic",
    },
    {
        name: "Minangkabau",
        iso6392B: "min",
    },
    {
        name: "Uncoded languages",
        iso6392B: "mis",
    },
    {
        name: "Mon-Khmer languages",
        iso6392B: "mkh",
    },
    {
        name: "Malagasy",
        iso6392B: "mlg",
        iso6391: "mg",
    },
    {
        name: "Maltese",
        iso6392B: "mlt",
        iso6391: "mt",
    },
    {
        name: "Manchu",
        iso6392B: "mnc",
    },
    {
        name: "Manipuri",
        iso6392B: "mni",
    },
    {
        name: "Manobo languages",
        iso6392B: "mno",
    },
    {
        name: "Mohawk",
        iso6392B: "moh",
    },
    {
        name: "Mongolian",
        iso6392B: "mon",
        iso6391: "mn",
    },
    {
        name: "Mossi",
        iso6392B: "mos",
    },
    {
        name: "Multiple languages",
        iso6392B: "mul",
    },
    {
        name: "Munda languages",
        iso6392B: "mun",
    },
    {
        name: "Creek",
        iso6392B: "mus",
    },
    {
        name: "Mirandese",
        iso6392B: "mwl",
    },
    {
        name: "Marwari",
        iso6392B: "mwr",
    },
    {
        name: "Mayan languages",
        iso6392B: "myn",
    },
    {
        name: "Erzya",
        iso6392B: "myv",
    },
    {
        name: "Nahuatl languages",
        iso6392B: "nah",
    },
    {
        name: "North American Indian languages",
        iso6392B: "nai",
    },
    {
        name: "Neapolitan",
        iso6392B: "nap",
    },
    {
        name: "Nauru",
        iso6392B: "nau",
        iso6391: "na",
    },
    {
        name: "Navajo; Navaho",
        iso6392B: "nav",
        iso6391: "nv",
    },
    {
        name: "Ndebele, South; South Ndebele",
        iso6392B: "nbl",
        iso6391: "nr",
    },
    {
        name: "Ndebele, North; North Ndebele",
        iso6392B: "nde",
        iso6391: "nd",
    },
    {
        name: "Ndonga",
        iso6392B: "ndo",
        iso6391: "ng",
    },
    {
        name: "Low German; Low Saxon; German, Low; Saxon, Low",
        iso6392B: "nds",
    },
    {
        name: "Nepali",
        iso6392B: "nep",
        iso6391: "ne",
    },
    {
        name: "Nepal Bhasa; Newari",
        iso6392B: "new",
    },
    {
        name: "Nias",
        iso6392B: "nia",
    },
    {
        name: "Niger-Kordofanian languages",
        iso6392B: "nic",
    },
    {
        name: "Niuean",
        iso6392B: "niu",
    },
    {
        name: "Norwegian Nynorsk; Nynorsk, Norwegian",
        iso6392B: "nno",
        iso6391: "nn",
    },
    {
        name: "Bokmål, Norwegian; Norwegian Bokmål",
        iso6392B: "nob",
        iso6391: "nb",
    },
    {
        name: "Nogai",
        iso6392B: "nog",
    },
    {
        name: "Norse, Old",
        iso6392B: "non",
    },
    {
        name: "Norwegian",
        iso6392B: "nor",
        iso6391: "no",
    },
    {
        name: "N'Ko",
        iso6392B: "nqo",
    },
    {
        name: "Pedi; Sepedi; Northern Sotho",
        iso6392B: "nso",
    },
    {
        name: "Nubian languages",
        iso6392B: "nub",
    },
    {
        name: "Classical Newari; Old Newari; Classical Nepal Bhasa",
        iso6392B: "nwc",
    },
    {
        name: "Chichewa; Chewa; Nyanja",
        iso6392B: "nya",
        iso6391: "ny",
    },
    {
        name: "Nyamwezi",
        iso6392B: "nym",
    },
    {
        name: "Nyankole",
        iso6392B: "nyn",
    },
    {
        name: "Nyoro",
        iso6392B: "nyo",
    },
    {
        name: "Nzima",
        iso6392B: "nzi",
    },
    {
        name: "Occitan (post 1500)",
        iso6392B: "oci",
        iso6391: "oc",
    },
    {
        name: "Ojibwa",
        iso6392B: "oji",
        iso6391: "oj",
    },
    {
        name: "Oriya",
        iso6392B: "ori",
        iso6391: "or",
    },
    {
        name: "Oromo",
        iso6392B: "orm",
        iso6391: "om",
    },
    {
        name: "Osage",
        iso6392B: "osa",
    },
    {
        name: "Ossetian; Ossetic",
        iso6392B: "oss",
        iso6391: "os",
    },
    {
        name: "Turkish, Ottoman (1500-1928)",
        iso6392B: "ota",
    },
    {
        name: "Otomian languages",
        iso6392B: "oto",
    },
    {
        name: "Papuan languages",
        iso6392B: "paa",
    },
    {
        name: "Pangasinan",
        iso6392B: "pag",
    },
    {
        name: "Pahlavi",
        iso6392B: "pal",
    },
    {
        name: "Pampanga; Kapampangan",
        iso6392B: "pam",
    },
    {
        name: "Panjabi; Punjabi",
        iso6392B: "pan",
        iso6391: "pa",
    },
    {
        name: "Papiamento",
        iso6392B: "pap",
    },
    {
        name: "Palauan",
        iso6392B: "pau",
    },
    {
        name: "Persian, Old (ca.600-400 B.C.)",
        iso6392B: "peo",
    },
    {
        name: "Persian",
        iso6392B: "per",
        iso6392T: "fas",
        iso6391: "fa",
    },
    {
        name: "Philippine languages",
        iso6392B: "phi",
    },
    {
        name: "Phoenician",
        iso6392B: "phn",
    },
    {
        name: "Pali",
        iso6392B: "pli",
        iso6391: "pi",
    },
    {
        name: "Polish",
        iso6392B: "pol",
        iso6391: "pl",
    },
    {
        name: "Pohnpeian",
        iso6392B: "pon",
    },
    {
        name: "Portuguese",
        iso6392B: "por",
        iso6391: "pt",
    },
    {
        name: "Prakrit languages",
        iso6392B: "pra",
    },
    {
        name: "Provençal, Old (to 1500); Occitan, Old (to 1500)",
        iso6392B: "pro",
    },
    {
        name: "Pushto; Pashto",
        iso6392B: "pus",
        iso6391: "ps",
    },
    {
        name: "Reserved for local use",
        iso6392B: "qaa-qtz",
    },
    {
        name: "Quechua",
        iso6392B: "que",
        iso6391: "qu",
    },
    {
        name: "Rajasthani",
        iso6392B: "raj",
    },
    {
        name: "Rapanui",
        iso6392B: "rap",
    },
    {
        name: "Rarotongan; Cook Islands Maori",
        iso6392B: "rar",
    },
    {
        name: "Romance languages",
        iso6392B: "roa",
    },
    {
        name: "Romansh",
        iso6392B: "roh",
        iso6391: "rm",
    },
    {
        name: "Romany",
        iso6392B: "rom",
    },
    {
        name: "Romanian; Moldavian; Moldovan",
        iso6392B: "rum",
        iso6392T: "ron",
        iso6391: "ro",
    },
    {
        name: "Rundi",
        iso6392B: "run",
        iso6391: "rn",
    },
    {
        name: "Aromanian; Arumanian; Macedo-Romanian",
        iso6392B: "rup",
    },
    {
        name: "Russian",
        iso6392B: "rus",
        iso6391: "ru",
    },
    {
        name: "Sandawe",
        iso6392B: "sad",
    },
    {
        name: "Sango",
        iso6392B: "sag",
        iso6391: "sg",
    },
    {
        name: "Yakut",
        iso6392B: "sah",
    },
    {
        name: "South American Indian languages",
        iso6392B: "sai",
    },
    {
        name: "Salishan languages",
        iso6392B: "sal",
    },
    {
        name: "Samaritan Aramaic",
        iso6392B: "sam",
    },
    {
        name: "Sanskrit",
        iso6392B: "san",
        iso6391: "sa",
    },
    {
        name: "Sasak",
        iso6392B: "sas",
    },
    {
        name: "Santali",
        iso6392B: "sat",
    },
    {
        name: "Sicilian",
        iso6392B: "scn",
    },
    {
        name: "Scots",
        iso6392B: "sco",
    },
    {
        name: "Selkup",
        iso6392B: "sel",
    },
    {
        name: "Semitic languages",
        iso6392B: "sem",
    },
    {
        name: "Irish, Old (to 900)",
        iso6392B: "sga",
    },
    {
        name: "Sign Languages",
        iso6392B: "sgn",
    },
    {
        name: "Shan",
        iso6392B: "shn",
    },
    {
        name: "Sidamo",
        iso6392B: "sid",
    },
    {
        name: "Sinhala; Sinhalese",
        iso6392B: "sin",
        iso6391: "si",
    },
    {
        name: "Siouan languages",
        iso6392B: "sio",
    },
    {
        name: "Sino-Tibetan languages",
        iso6392B: "sit",
    },
    {
        name: "Slavic languages",
        iso6392B: "sla",
    },
    {
        name: "Slovak",
        iso6392B: "slo",
        iso6392T: "slk",
        iso6391: "sk",
    },
    {
        name: "Slovenian",
        iso6392B: "slv",
        iso6391: "sl",
    },
    {
        name: "Southern Sami",
        iso6392B: "sma",
    },
    {
        name: "Northern Sami",
        iso6392B: "sme",
        iso6391: "se",
    },
    {
        name: "Sami languages",
        iso6392B: "smi",
    },
    {
        name: "Lule Sami",
        iso6392B: "smj",
    },
    {
        name: "Inari Sami",
        iso6392B: "smn",
    },
    {
        name: "Samoan",
        iso6392B: "smo",
        iso6391: "sm",
    },
    {
        name: "Skolt Sami",
        iso6392B: "sms",
    },
    {
        name: "Shona",
        iso6392B: "sna",
        iso6391: "sn",
    },
    {
        name: "Sindhi",
        iso6392B: "snd",
        iso6391: "sd",
    },
    {
        name: "Soninke",
        iso6392B: "snk",
    },
    {
        name: "Sogdian",
        iso6392B: "sog",
    },
    {
        name: "Somali",
        iso6392B: "som",
        iso6391: "so",
    },
    {
        name: "Songhai languages",
        iso6392B: "son",
    },
    {
        name: "Sotho, Southern",
        iso6392B: "sot",
        iso6391: "st",
    },
    {
        name: "Spanish; Castilian",
        iso6392B: "spa",
        iso6391: "es",
    },
    {
        name: "Sardinian",
        iso6392B: "srd",
        iso6391: "sc",
    },
    {
        name: "Sranan Tongo",
        iso6392B: "srn",
    },
    {
        name: "Serbian",
        iso6392B: "srp",
        iso6391: "sr",
    },
    {
        name: "Serer",
        iso6392B: "srr",
    },
    {
        name: "Nilo-Saharan languages",
        iso6392B: "ssa",
    },
    {
        name: "Swati",
        iso6392B: "ssw",
        iso6391: "ss",
    },
    {
        name: "Sukuma",
        iso6392B: "suk",
    },
    {
        name: "Sundanese",
        iso6392B: "sun",
        iso6391: "su",
    },
    {
        name: "Susu",
        iso6392B: "sus",
    },
    {
        name: "Sumerian",
        iso6392B: "sux",
    },
    {
        name: "Swahili",
        iso6392B: "swa",
        iso6391: "sw",
    },
    {
        name: "Swedish",
        iso6392B: "swe",
        iso6391: "sv",
    },
    {
        name: "Classical Syriac",
        iso6392B: "syc",
    },
    {
        name: "Syriac",
        iso6392B: "syr",
    },
    {
        name: "Tahitian",
        iso6392B: "tah",
        iso6391: "ty",
    },
    {
        name: "Tai languages",
        iso6392B: "tai",
    },
    {
        name: "Tamil",
        iso6392B: "tam",
        iso6391: "ta",
    },
    {
        name: "Tatar",
        iso6392B: "tat",
        iso6391: "tt",
    },
    {
        name: "Telugu",
        iso6392B: "tel",
        iso6391: "te",
    },
    {
        name: "Timne",
        iso6392B: "tem",
    },
    {
        name: "Tereno",
        iso6392B: "ter",
    },
    {
        name: "Tetum",
        iso6392B: "tet",
    },
    {
        name: "Tajik",
        iso6392B: "tgk",
        iso6391: "tg",
    },
    {
        name: "Tagalog",
        iso6392B: "tgl",
        iso6391: "tl",
    },
    {
        name: "Thai",
        iso6392B: "tha",
        iso6391: "th",
    },
    {
        name: "Tibetan",
        iso6392B: "tib",
        iso6392T: "bod",
        iso6391: "bo",
    },
    {
        name: "Tigre",
        iso6392B: "tig",
    },
    {
        name: "Tigrinya",
        iso6392B: "tir",
        iso6391: "ti",
    },
    {
        name: "Tiv",
        iso6392B: "tiv",
    },
    {
        name: "Tokelau",
        iso6392B: "tkl",
    },
    {
        name: "Klingon; tlhIngan-Hol",
        iso6392B: "tlh",
    },
    {
        name: "Tlingit",
        iso6392B: "tli",
    },
    {
        name: "Tamashek",
        iso6392B: "tmh",
    },
    {
        name: "Tonga (Nyasa)",
        iso6392B: "tog",
    },
    {
        name: "Tonga (Tonga Islands)",
        iso6392B: "ton",
        iso6391: "to",
    },
    {
        name: "Tok Pisin",
        iso6392B: "tpi",
    },
    {
        name: "Tsimshian",
        iso6392B: "tsi",
    },
    {
        name: "Tswana",
        iso6392B: "tsn",
        iso6391: "tn",
    },
    {
        name: "Tsonga",
        iso6392B: "tso",
        iso6391: "ts",
    },
    {
        name: "Turkmen",
        iso6392B: "tuk",
        iso6391: "tk",
    },
    {
        name: "Tumbuka",
        iso6392B: "tum",
    },
    {
        name: "Tupi languages",
        iso6392B: "tup",
    },
    {
        name: "Turkish",
        iso6392B: "tur",
        iso6391: "tr",
    },
    {
        name: "Altaic languages",
        iso6392B: "tut",
    },
    {
        name: "Tuvalu",
        iso6392B: "tvl",
    },
    {
        name: "Twi",
        iso6392B: "twi",
        iso6391: "tw",
    },
    {
        name: "Tuvinian",
        iso6392B: "tyv",
    },
    {
        name: "Udmurt",
        iso6392B: "udm",
    },
    {
        name: "Ugaritic",
        iso6392B: "uga",
    },
    {
        name: "Uighur; Uyghur",
        iso6392B: "uig",
        iso6391: "ug",
    },
    {
        name: "Ukrainian",
        iso6392B: "ukr",
        iso6391: "uk",
    },
    {
        name: "Umbundu",
        iso6392B: "umb",
    },
    {
        name: "Undetermined",
        iso6392B: "und",
    },
    {
        name: "Urdu",
        iso6392B: "urd",
        iso6391: "ur",
    },
    {
        name: "Uzbek",
        iso6392B: "uzb",
        iso6391: "uz",
    },
    {
        name: "Vai",
        iso6392B: "vai",
    },
    {
        name: "Venda",
        iso6392B: "ven",
        iso6391: "ve",
    },
    {
        name: "Vietnamese",
        iso6392B: "vie",
        iso6391: "vi",
    },
    {
        name: "Volapük",
        iso6392B: "vol",
        iso6391: "vo",
    },
    {
        name: "Votic",
        iso6392B: "vot",
    },
    {
        name: "Wakashan languages",
        iso6392B: "wak",
    },
    {
        name: "Wolaitta; Wolaytta",
        iso6392B: "wal",
    },
    {
        name: "Waray",
        iso6392B: "war",
    },
    {
        name: "Washo",
        iso6392B: "was",
    },
    {
        name: "Welsh",
        iso6392B: "wel",
        iso6392T: "cym",
        iso6391: "cy",
    },
    {
        name: "Sorbian languages",
        iso6392B: "wen",
    },
    {
        name: "Walloon",
        iso6392B: "wln",
        iso6391: "wa",
    },
    {
        name: "Wolof",
        iso6392B: "wol",
        iso6391: "wo",
    },
    {
        name: "Kalmyk; Oirat",
        iso6392B: "xal",
    },
    {
        name: "Xhosa",
        iso6392B: "xho",
        iso6391: "xh",
    },
    {
        name: "Yao",
        iso6392B: "yao",
    },
    {
        name: "Yapese",
        iso6392B: "yap",
    },
    {
        name: "Yiddish",
        iso6392B: "yid",
        iso6391: "yi",
    },
    {
        name: "Yoruba",
        iso6392B: "yor",
        iso6391: "yo",
    },
    {
        name: "Yupik languages",
        iso6392B: "ypk",
    },
    {
        name: "Zapotec",
        iso6392B: "zap",
    },
    {
        name: "Blissymbols; Blissymbolics; Bliss",
        iso6392B: "zbl",
    },
    {
        name: "Zenaga",
        iso6392B: "zen",
    },
    {
        name: "Standard Moroccan Tamazight",
        iso6392B: "zgh",
    },
    {
        name: "Zhuang; Chuang",
        iso6392B: "zha",
        iso6391: "za",
    },
    {
        name: "Zande languages",
        iso6392B: "znd",
    },
    {
        name: "Zulu",
        iso6392B: "zul",
        iso6391: "zu",
    },
    {
        name: "Zuni",
        iso6392B: "zun",
    },
    {
        name: "No linguistic content; Not applicable",
        iso6392B: "zxx",
    },
    {
        name: "Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki",
        iso6392B: "zza",
    },
];

// get iso 639-1 from isos 639-2 or 639-1
export const getIso6391 = (iso) => {
    const _iso = iso6392.find((item) => item.iso6392B === iso || item.iso6391 === iso || item?.iso6392T === iso);
    return _iso?.iso6391 ? _iso.iso6391 : iso;
};

export const getIso6392 = (iso) => {
    const _iso = iso6392.find((item) => item.iso6392B === iso || item.iso6391 === iso);
    return _iso?.iso6392B ? _iso.iso6392B : iso;
};

import ISO6392 from "./ISO_639-2.json";

export const getLanguageNameFromIso6392 = (iso6392) => {
    if (iso6392 === "spa" || iso6392 === "es") {
        return ISO6392[iso6392].native[ISO6392[iso6392].native.length - 1];
    }
    if (ISO6392?.[iso6392]?.native?.length > 0) {
        return ISO6392[iso6392].native[0];
    } else {
        return null;
    }
};
export const getLanguageEnglishNameFromIso6392 = (iso6392) => {
    if (iso6392 === "spa" || iso6392 === "es") {
        return ISO6392[iso6392].native[ISO6392[iso6392].native.length - 1];
    }
    return ISO6392?.[iso6392]?.english?.length > 0 ? ISO6392?.[iso6392]?.english[0] : iso6392;
};
