import Query from "../../query";

export const rentMovie = (stayGuestRoomsId: number, movieId: number, callback: any, fail: any) => {
    Query({
        query: `
            mutation{
                rentMovie(
                    stayGuestRoomID:${stayGuestRoomsId} movieID:${movieId} 
                ){
                    id
                    ok
                }
        }`,
        onResponse(res) {
            if (res?.data?.rentMovie?.ok) {
                if (callback) {
                    callback();
                }
                // setSuccess(true);
                // setError("");
                // dispatch(setNumpadCode(null));
            } else if (fail) {
                fail();
                // setOrderProcessing(false);
                // setError(Texts.translate(texts, "order-error"));
            } else {
                throw new Error(`rentMovie fail movieId:${movieId}`);
            }
        },
        onError() {
            if (fail) {
                fail();
            }
        },
    });
};
