/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setMessageRead } from "../../actions/statusActions";
import Query from "../../hooks/query";
import { screenUtils } from "../../utils/screenUtils";
import { Theme } from "../../utils/theme";
import Button from "../../components/common/button";
import VerticalList from "../../components/common/verticalList";
import { memo } from "preact/compat";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import ParseSalesMessagesTags from "../../hooks/GraphqlCalls/parseSalesMessagesTags";
import Loading from "../../components/loading";
import { KEYS } from "../../utils/keys";
import { inPreview } from "../../utils/utils";

const Messages = () => {
    const dispatch = useDispatch();

    const ITEM_TYPE = "message";

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const messages = useSelector((state) => state.status.sessionData.messages);
    const tags = useSelector((state) => state.status.locationData.tags);
    const messagesRef = useRef(messages);
    messagesRef.current = messages;
    //States
    const [messageShown, setMessageShown] = useState(null);
    const [msgRdy, setMsgRdy] = useState(false);
    const [parsingMsg, setParsingMsg] = useState(false);

    // replace SALES special TAGS
    ParseSalesMessagesTags(setMsgRdy, [parsingMsg, setParsingMsg]);

    //listeners
    useEffect(() => {
        if (!messagesRef.current || messagesRef.current.length === 0) {
            focus.value.replace("btn-exit");
        }
        return () => {
            sessionStorage.removeItem("messageShownId");
        };
    }, []);

    useEffect(() => {
        if (focus.value.current?.indexOf(`btn-${ITEM_TYPE}-`) > -1) {
            if (messagesRef.current) {
                const msgFiltered = messagesRef.current.filter(
                    (msg) => String(msg.id) === focus.value.current.replace(`btn-${ITEM_TYPE}-`, ""),
                );

                if (msgFiltered?.[0]) {
                    setMessageShown(msgFiltered[0]);
                    sessionStorage.setItem("messageShownId", msgFiltered[0].id);
                }
            }
        }
    }, [focus.value.current]);

    useEffect(() => {
        if (messageShown && !messageShown.isRead) {
            setAsRead(messageShown.id);
        }
        if (messageShown && messageShown?.message?.information && !messageShown?.message?.information?.parsed) {
            setParsingMsg(false);
            ParseSalesMessagesTags(setMsgRdy, [parsingMsg, setParsingMsg]);
        }
    }, [messageShown]);

    const isCritical = (msg) => {
        if (!msg) return null;
        return ["PRIORITY", "CRITICAL"].indexOf(msg.message.priority) > -1;
    };

    const setAsRead = (msgId) => {
        if (inPreview()) {
            dispatch(setMessageRead(msgId));
            return;
        }
        Query({
            query: `
                    mutation{ 
                        markStayGuestMessagesAsRead (
                            stayGuestMessageIds: [${msgId}]
                        ) {
                            error,
                            ok
                        }
                    }
            `,
            onResponse(res) {
                if (res?.data?.markStayGuestMessagesAsRead?.ok) {
                    dispatch(setMessageRead(msgId));
                }
            },
        });
    };

    const parseTime = (time) => {
        if (!time) {
            return "";
        }
        const myDate = new Date(time);
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        return myDate < today
            ? myDate.toLocaleDateString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
              })
            : myDate.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
              });
    };

    const onArrowKey = (direction) => {
        if (direction === "left") {
            focus.value.replace(`btn-message-${messageShown?.id}`);
            return true;
        } else if (direction === "right") {
            focus.value.replace(`btn-msgscrolling-text`);
            return true;
        }
        return false;
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData?.type === "arrow") {
            preventDefault = onArrowKey(keyData.value);
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    if (!msgRdy && !messageShown) {
        return (
            <Loading
                text={texts["loading-messages"]}
                style={{
                    color: Theme.light ? Theme.getDefaultStyles().gray[800] : Theme.getDefaultStyles().gray[100],
                    height: "55vh",
                }}
            />
        );
    }

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div
                style={{
                    width: "80vw",
                    display: "block",
                    height: messagesRef.current && messagesRef.current.length > 0 ? "55vh" : "",
                }}
            >
                {messagesRef.current && messagesRef.current.length > 0 ? (
                    <>
                        <div
                            className="float-left"
                            style={{
                                width: "27vw",
                                height: "55vh",
                                paddingRight: "0.35vw",
                                marginRight: "4.75vw",
                                borderRight: `1px solid ${Theme.colors.default.gray["300"]}`,
                            }}
                        >
                            <VerticalList
                                insideModal={true}
                                activeItemId={`message-${sessionStorage.getItem("messageShownId")}`}
                                data={{
                                    list: messagesRef.current,
                                    customClass: "pr-2",
                                    itemType: ITEM_TYPE,
                                    itemCustomClass: "w-full",
                                    itemCustomStyle: {
                                        marginBottom: "0.5vh",
                                        backgroundColor: "transparent",
                                    },
                                    itemRounded: true,
                                    maxHeight: "55vh",
                                    onClick() {
                                        focus.value.replace("btn-msgscrolling-text");
                                    },
                                }}
                            />
                        </div>
                        <div className="float-left" style={{ width: "48vw", height: "49vh" }}>
                            <div>
                                <>
                                    <div className="bold text-2xl mb-6">
                                        {isCritical(messageShown) ? (
                                            <span className="icon icon-message-important msgUrgentIcon pr-2" />
                                        ) : null}
                                        {messageShown
                                            ? screenUtils.replaceSpecialTags(messageShown.message.title, tags)
                                            : ""}
                                    </div>

                                    <Button
                                        insideModal={true}
                                        data={{
                                            id: "msgscrolling-text",
                                            body: messageShown
                                                ? screenUtils.replaceSpecialTags(messageShown.message.body, tags)
                                                : "",
                                            innerHTML:
                                                messageShown?.message?.innerHTML ||
                                                screenUtils.replaceSpecialTags(messageShown?.message?.body, tags),
                                            customClass:
                                                "p-4 text-xl overflow-y-auto scrollable w-full rounded pre-line",
                                            bodyClass: "overflow-y-auto pr-2",
                                            customStyle: {
                                                height: "42vh",
                                            },
                                            keysBlockedOnScroll: [KEYS.up, KEYS.down],
                                        }}
                                    />
                                    <div class="text-base mt-8 text-right">
                                        {parseTime(messageShown ? messageShown.message.sentTime : null)}
                                    </div>
                                </>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-2xl  text-center" style={{ marginTop: "21vh", marginBottom: "21vh" }}>
                            {texts["no-messages"]}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(Messages);
