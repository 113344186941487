import { memo, useEffect, useRef, useState } from "preact/compat";
import { useSelector } from "react-redux";
import { STB } from "../hooks/apis/stb";
import Screen from "./screen";
import "../assets/styles/ccinstructions";
import { eventHandler } from "../utils/eventHandler";
import Query from "../hooks/query";
import { KEYS } from "../utils/keys";
import focus from "../utils/focus";
import Limbo from "./screen/limbo";
import { WIDGETS } from "../utils/constants";
import { inPreview, printCenteredMessage } from "../utils/utils";
import ReactHtmlParser from "react-html-parser";

const CcInstructions = ({ ccInstructionsData }) => {
    const locationData = useSelector((state) => state.status.locationData);
    const tvInputs = useSelector((state) => state.status.locationData.tvInputs);
    const chromecastRef = useSelector((state) => state.status.locationData.chromecastRef);

    const texts = useSelector((state) => state.ui.texts);

    const getChromecastInput = () => {
        const cccInput = tvInputs?.find((input) => input.deviceType === "CHROMECAST");
        if (cccInput) {
            return parseInt(cccInput.input?.slice(cccInput.input.length - 1, cccInput.input.length));
        }

        return locationData?.roomInfo?.chromecastTvInput;
    };

    const [lowSignal, setLowSignal] = useState(false);
    const [ccInfo, setCCInfo] = useState(false);
    const [wrongNetwork, setWrongNetwork] = useState(false);
    const [ccInput] = useState(getChromecastInput());

    const checkCCSignalTimeout = useRef();

    useEffect(() => {
        const changeInput =
            !inPreview() && locationData?.roomInfo?.chromecastModel === "GoogleTV" && STB.model !== "GoogleTV";
        //If the CC conected to the device is a Google TV, change to CC input
        if (changeInput) {
            const lastInputChange = sessionStorage.getItem("lastInputChange");
            const now = new Date();

            //prevent multiple input change
            if (lastInputChange) {
                const now = new Date();
                if (now.getTime() - parseInt(lastInputChange) < 1000) {
                    console.log("no salto");
                } else {
                    ensureInstructionsInCC();
                }
            } else {
                ensureInstructionsInCC();
            }
            return;
        } else {
            checkCCSignal();
            checkCCSignalTimeout.current = setInterval(
                function () {
                    checkCCSignal();
                },
                5 * 60 * 1000,
            ); //check each 5 min
        }
        if (!STB.navigateOnCCInstructions) {
            focus.value.replace("limbo");
        }
        return () => {
            clearInterval(checkCCSignalTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkCCSignal = () => {
        Query({
            query: `
                {
                    chromecastStatus{
                        lowSignal
                        wrongNetwork
                    }
                }
            `,
            onResponse(res) {
                setCCInfo(true);
                setLowSignal(res?.data?.chromecastStatus?.lowSignal);
                setWrongNetwork(res?.data?.chromecastStatus?.wrongNetwork);
            },
            onError() {
                setCCInfo(true);
            },
        });
    };

    const ensureInstructionsInCC = () => {
        const now = new Date();
        if (chromecastRef) {
            Query({
                query: `
                    mutation{
                        ensureInstructionsInCC(ccRef:"${chromecastRef}"){
                            ok
                            error{
                            code
                            msg
                            }
                        }
                    }
              `,
                propagateError: false,
                onResponse() {},
            });
        }
        sessionStorage.setItem("onCCView", true);
        sessionStorage.setItem("lastInputChange", now.getTime());
        setTimeout(function () {
            STB.changeToInput(ccInput);
        }, 1000);
    };

    const onkeydown = (e) => {
        if (STB.navigateOnCCInstructions) {
            return;
        }
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = true;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                case KEYS.home:
                    preventDefault = false;
                    break;
                case KEYS.enter:
                    if (wrongNetwork || inPreview()) {
                        preventDefault = false;
                        break;
                    }
                    if (STB.builtInCC) {
                        STB.showCC();
                    } else if (ccInput) {
                        STB.changeToInput(ccInput);
                    }
                    preventDefault = false;
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    if (!ccInfo) {
        return <></>;
    }

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            {wrongNetwork ? (
                <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                    {printCenteredMessage(ReactHtmlParser(texts["cc-wrong-network"]), null, "#373D42", "#F5F6F8")}
                </div>
            ) : lowSignal ? (
                <div id="lowSignalOverlay">
                    <div id="lowSignalText">
                        <div>
                            <img src="https://drive.zafiro.cloud/icons/svg/warning.svg" />
                            {texts["low-signal-title"]}
                        </div>
                        <div> {texts["low-signal-issues"]}</div>
                    </div>
                </div>
            ) : (
                <Screen
                    data={ccInstructionsData}
                    preventFocus={!STB.navigateOnCCInstructions}
                    isCCIntructions={true}
                    noFocusableWidgets={[WIDGETS.VIDEO]}
                    autoplay={true}
                    propagateKeys={true}
                    videoRepeat={true}
                />
            )}
            <Limbo />
        </div>
    );
};

export default memo(CcInstructions);
