/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setMessageRead } from "../../actions/statusActions";
import { STB } from "../../hooks/apis/stb";
import Query from "../../hooks/query";
import { screenUtils } from "../../utils/screenUtils";
import { Texts } from "../../utils/texts";
import Button from "../../components/common/button";
import { isCritical, isImportant } from "../../utils/messageUtils";
import focus from "../../utils/focus";
import { inPreview } from "../../utils/utils";

const UrgentMessages = () => {
    const dispatch = useDispatch();

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const messages = useSelector((state) => state.status.sessionData.messages);
    const tags = useSelector((state) => state.status.locationData.tags);

    //States
    const [messageShown, setMessageShown] = useState();
    const [messagePage, setMessagePage] = useState();
    const [urgentMessages, setUrgentMessages] = useState([]);

    //Listeners
    useEffect(() => {
        let urgents = [];
        let importants = [];
        messages.map((msg) => (!msg.isRead && isCritical(msg.message) ? urgents.push(msg) : null));
        messages.map((msg) => (!msg.isRead && isImportant(msg.message) ? importants.push(msg) : null));
        if (urgents.length > 0) {
            if (importants.length > 0) {
                urgents = urgents.concat(importants);
            }
            if (urgents.find((msg) => msg?.message?.priority === "CRITICAL")) {
                STB.powerOn();
            }
            setUrgentMessages(urgents);
            setMessagePage(1);
        }
        if (urgents.length === 0 && importants.length > 0) {
            setUrgentMessages(importants);
            setMessagePage(1);
        }
        return () => {
            const MSId = sessionStorage.getItem("messageShownId");
            if (MSId) {
                setAsRead(MSId);
                sessionStorage.removeItem("messageShownId");
            }
        };
    }, []);

    useEffect(() => {
        if (messageShown && !messageShown.isRead) {
            setAsRead(messageShown.id);
        }

        if (!urgentMessages || !messagePage) {
            return;
        }
        setMessageShown(urgentMessages[messagePage - 1]);
        sessionStorage.setItem("messageShownId", urgentMessages[messagePage - 1].id);
        if (urgentMessages.length === 1 || messagePage === urgentMessages.length) {
            focus.value.replace("btn-exit");
        } else if (messagePage === 1) {
            focus.value.replace("btn-messages-next");
        } else if (focus.value.current === "btn-messages-prev" && messagePage > 1) {
            return;
        } else if (focus.value.current === "btn-messages-next" && messagePage < urgentMessages.length) {
            return;
        }
    }, [messagePage]);

    const setAsRead = (msgId) => {
        if (inPreview()) {
            dispatch(setMessageRead(msgId));
            return;
        }
        Query({
            query: `
                    mutation{ 
                        markStayGuestMessagesAsRead (
                            stayGuestMessageIds: [${msgId}]
                        ) {
                            error
                            ok
                        }
                    }
            `,
            onResponse(res) {
                if (res?.data?.markStayGuestMessagesAsRead?.ok) {
                    dispatch(setMessageRead(msgId));
                }
            },
        });
    };

    const parseTime = (time) => {
        if (!time) {
            return "";
        }
        const myDate = new Date(time);
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        return myDate < today
            ? myDate.toLocaleDateString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
              })
            : myDate.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
              });
    };

    return (
        <div>
            <div style={{ width: "auto" }}>
                <div style={{ height: "45vh" }}>
                    {messageShown ? (
                        <div>
                            <div className="bold text-lg mb-8">
                                {isCritical(messageShown.message) ? (
                                    <span className="icon icon-message-important msgUrgentIcon pr-2" />
                                ) : null}
                                {screenUtils.replaceSpecialTags(messageShown.message.title, tags)}
                            </div>

                            <Button
                                insideModal={true}
                                data={{
                                    id: "msgscrolling-text",
                                    body: screenUtils.replaceSpecialTags(messageShown.message.body, tags),
                                    customClass: "p-4 text-lg overflow-y-auto scrollable w-full pre-line",
                                    bodyClass: "overflow-y-auto",
                                    customStyle: {
                                        height: "34vh",
                                    },
                                }}
                            />
                            <div class="text-base mt-3 text-right">
                                {messageShown ? parseTime(messageShown.message.sentTime) : null}
                            </div>

                            <div className="pt-8 text-center">
                                {urgentMessages && urgentMessages.length > 1 ? (
                                    <>
                                        {messagePage > 1 ? (
                                            <Button
                                                insideModal={true}
                                                data={{
                                                    id: "messages-prev",
                                                    name: Texts.capitalize(texts["previous"]),
                                                    border: true,
                                                    customClass: "py-2 float-left text-sm rounded",
                                                    customStyle: {
                                                        width: "10vw",
                                                    },
                                                    onClick() {
                                                        setMessagePage(messagePage - 1);
                                                    },
                                                }}
                                            />
                                        ) : null}
                                        {messagePage < urgentMessages.length ? (
                                            <Button
                                                insideModal={true}
                                                data={{
                                                    id: "messages-next",
                                                    name: Texts.capitalize(texts["next"]),
                                                    border: true,
                                                    customClass: "py-2 float-right text-sm rounded",
                                                    customStyle: {
                                                        width: "10vw",
                                                    },
                                                    onClick() {
                                                        setMessagePage(messagePage + 1);
                                                    },
                                                }}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default UrgentMessages;
