import { promisedVendureQuery } from "../../../hooks/query";
import { carrito } from "../signals/carrito";
import storage from "../../../utils/storage";
import shopByToken from "./shopByToken";

const addItemToOrder = ({
    channelToken,
    variantId,
    extraConfig,
    quantity,
    deliveryLocation,
    schedule,
}: {
    channelToken: string;
    variantId: number;
    quantity: number;
    extraConfig: Record<number, Sales.Extras.Config>;
    deliveryLocation: string;
    schedule: [number, number];
}): Promise<void> => {
    return promisedVendureQuery({
        body: `
            mutation {
                addItemToOrder(
                productVariantId: ${variantId},
                quantity: ${quantity}, 
                activeOrderInput: {
                    guestIdOrderStrategy: {
                        guestId: ${localStorage.getItem("lastStayGuestId")},
                        roomNumber: ${localStorage.getItem("roomNumber")},
                        deliveryLocation: "${deliveryLocation}"
                        ${
                            schedule?.[0] && schedule?.[1]
                                ? `, scheduleStart: "${schedule[0]}", scheduleEnd: "${schedule[1]}"`
                                : schedule?.[0]
                                ? `, scheduleStart: "${schedule[0]}"`
                                : `, scheduleStart: "${new Date().getTime()}"`
                        }
                    }
                },
                customFields: {
                    extras: ${JSON.stringify(JSON.stringify(extraConfig))}
                }){
                    __typename
                    ...on Order {
                        id
                    }
                }
            }`,
        apiType: "shop",
        headers: {
            "vendure-token": channelToken,
        },
    });
};

const addPaymentMethod = ({
    methodCode,
    channelToken,
    guestId,
    roomNumber,
}: {
    methodCode: string;
    metadata: Record<any, any>;
    channelToken: string;
    guestId: string | number;
    roomNumber: string | number;
}): Promise<void> => {
    return promisedVendureQuery({
        body: `
            mutation {
                addPaymentToOrder(input: {method: "${methodCode}", metadata: {}},
                  activeOrderInput: {guestIdOrderStrategy: {guestId: ${guestId}, roomNumber: ${roomNumber}}}){
                  __typename
                  ...on OrderPaymentStateError {
                    message
                    errorCode
                  }
                }
            }`,
        apiType: "shop",
        headers: {
            "vendure-token": channelToken,
        },
    });
};

const addShippingMethodToOrder = ({
    methodId,
    channelToken,
    roomNumber,
    guestId,
}: {
    methodId: string | number;
    channelToken: string;
    roomNumber: string | number;
    guestId: string | number;
}): Promise<any> => {
    return promisedVendureQuery({
        body: `
            mutation {
                setOrderShippingMethod(
                  shippingMethodId: ${methodId},
                  activeOrderInput: {
                    guestIdOrderStrategy: {guestId: ${guestId}, roomNumber: ${roomNumber}}
                  }) {
                  __typename
                  ...on Order{
                    id
                    state
                  }
                }
            }`,
        apiType: "shop",
        headers: {
            "vendure-token": channelToken,
        },
    });
};

const getAvailableShippingMethods = async ({
    channelToken,
    roomNumber,
    guestId,
}: {
    channelToken: string;
    roomNumber: number | string;
    guestId: number | string;
}): Promise<{ id: string; code: string }[]> => {
    const channel = await shopByToken(channelToken);
    let shipping_methods: { id: string; code: string }[] = [];
    if (channel.customFields.shipping_config.delivery?.enabled) shipping_methods.push({ id: "0", code: "delivery" });
    if (channel.customFields.shipping_config.pickup?.enabled) shipping_methods.push({ id: "0", code: "pickup" });
    const response = await promisedVendureQuery({
        body: `
            query {
                eligibleShippingMethods(
                  activeOrderInput: {
                    guestIdOrderStrategy: {guestId: ${guestId}, roomNumber: ${roomNumber}}
                  }){
                  id,
                  code
                }
            }`,
        apiType: "shop",
        headers: {
            "vendure-token": channelToken,
        },
    });
    return response?.data?.eligibleShippingMethods;
};

const transitionOrderToState = ({
    channelToken,
    guestId,
    roomNumber,
}: {
    channelToken: string;
    guestId: string | number;
    roomNumber: string | number;
}): Promise<any> => {
    return promisedVendureQuery({
        body: `
            mutation {
              transitionOrderToState(
                state: "ArrangingPayment",
                activeOrderInput:{
                  guestIdOrderStrategy: {guestId: ${guestId}, roomNumber: ${roomNumber}}
                }){
                __typename
                ...on OrderStateTransitionError {
                  message
                  errorCode
                  fromState
                  toState
                }
                ...on Order {
                  id
                  state
                }
            }
        }`,
        apiType: "shop",
        headers: {
            "vendure-token": channelToken,
        },
    });
};

export default async ({
    channelToken,
    deliveryLocation = "",
    schedule,
    shippingMethod = "pickup",
}: {
    channelToken: string;
    deliveryLocation: string;
    schedule: [number, number];
    shippingMethod: "pickup" | "delivery";
}): Promise<void | null> => {
    const productos = carrito.peek()[channelToken] ?? [];
    let error = false;
    if (!productos[0]) return;
    const roomNumber = storage.get("roomNumber", 0);
    const guestId = storage.get("lastStayGuestId", 0);
    for (let i = 0; i < productos.length; i++)
        await addItemToOrder({
            channelToken,
            variantId: productos[i]!.variant,
            extraConfig: productos[i]!.config,
            quantity: productos[i]!.quantity,
            deliveryLocation,
            schedule,
        });
    const availableShippingMethods = await getAvailableShippingMethods({ channelToken, guestId, roomNumber });
    if (!availableShippingMethods) {
        return null;
    }
    const shippingMethodObj = availableShippingMethods.find((x) => x.code === shippingMethod);
    await addShippingMethodToOrder({
        methodId: shippingMethodObj?.id as string,
        roomNumber,
        guestId,
        channelToken,
    }).then((data) => {
        if (!data?.data) {
            error = true;
        }
    });
    if (error) {
        return null;
    }
    await transitionOrderToState({ channelToken, guestId, roomNumber }).then((data) => {
        if (data?.data?.transitionOrderToState?.errorCode) {
            error = true;
        }
    });
    if (error) {
        return null;
    }
    return await addPaymentMethod({ metadata: {}, methodCode: "charge-to-room", channelToken, guestId, roomNumber });
};
