import { vendureQuery } from "../../../hooks/query";
import { getEnvVar } from "../../../utils/utils";
import getExtras from "./extras";

const getProduct = (productId: number, channelToken: string): Promise<Sales.Product>=>new Promise((resolve, reject)=>vendureQuery({apiType: "shop", headers: {'vendure-token': channelToken}, body: `
    {
      product(id: "${productId}") {
        id
    	createdAt
        updatedAt
        languageCode
        name
        description
    	variants {
          id
          assets {
            id
            source
          }
          name
          facetValues {
            id
            translations {
              languageCode
              name
            }
          }
          priceWithTax
          currencyCode
        }
        featuredAsset {
          id
          source
          fileSize
          mimeType
          height
          name
          preview
          width
        }
        assets {
          id
          source
        }
        translations {
          languageCode
          name
          description
          slug
        }
        facetValues {
          id
          translations {
            id
            name
            languageCode
          }
        }
      }
}`,
    onResponse: (x)=>{
        x.data.product.variants = x.data.product.variants.map((x: any)=>({
            ...x,
            priceWithTax: x.priceWithTax,
        }));
        x.data.product.featuredAsset.source &&= `${getEnvVar('VENDURE')}${x.data.productVariant.featuredAsset?.source}`;
        x.data.product.assets = x.data.product.assets.map((x: any)=>`${getEnvVar('VENDURE')}${x.source}`);
        resolve(x?.data?.product);
    },
    onError: (e)=>reject(e)
}));

export default async (productId: number, channelToken: string): Promise<Sales.Product>=>{
    const [product, extras] = await Promise.all([getProduct(productId, channelToken), getExtras(productId, channelToken)]);
    return {...product, extras};
}