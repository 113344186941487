export const Texts = {
    capitalize(text: string): string {
        return text?.length ? text[0]!.toUpperCase() + text.substring(1) : text;
    },
    translate(texts: { [key: string]: string }, key: string): string {
        return texts[key] ?? key;
    },
    translateCap(texts: { [key: string]: string }, key: string): string {
        return this.capitalize(texts[key] ?? key);
    },
} as const;
