import { useRef, useLayoutEffect } from 'preact/compat';
import type { FC } from "react";
import type { CSSProperties } from "preact/compat";
import { useSignal } from "@preact/signals";

const Image: FC<{src: string, alt?: string, style?: CSSProperties, className?: string, id?: string,}> = ({src, alt= 'Imagen', style = {}, id, className})=>{
    const url = useSignal<string | undefined>(undefined);
    const elementRef = useRef<HTMLImageElement>(null);

    useLayoutEffect(() => {
        if (elementRef.current) {
            url.value = `${src}?w=${elementRef.current.width}&h=${elementRef.current.height}`
        }
    }, [elementRef, src]);

    //@ts-expect-error
    return (<img ref={elementRef} onError={(e) => {e.target.style.visibility='hidden'}} id={id} style={style} className={className} src={url.value} alt={alt}/>);
}

export default Image;