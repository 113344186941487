/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useMemo } from "preact/compat";
import NumericButton from "./numericButton";

const HourPicker = ({ hours, minutes, setHours, setMinutes, insideModal }) => {
    useEffect(() => {
        const cDate = new Date();

        setHours(hours || cDate.getHours());
        setMinutes(minutes || cDate.getMinutes());
    }, []);

    return useMemo(() => {
        return (
            <div className="table mx-auto">
                <div className="float-left">
                    <NumericButton
                        id={"btn-hours"}
                        value={hours}
                        setValue={setHours}
                        minValue={0}
                        maxValue={23}
                        insideModal={insideModal}
                    />
                </div>
                <div
                    className="float-left"
                    style={{
                        fontSize: "2.4vw",
                        fontWeight: "bold",
                        marginLeft: "1vw",
                        marginRight: "1vw",
                    }}
                >
                    :
                </div>
                <div className="float-left">
                    <NumericButton
                        id={"btn-minutes"}
                        value={minutes}
                        setValue={setMinutes}
                        minValue={0}
                        maxValue={59}
                        insideModal={insideModal}
                    />
                </div>
            </div>
        );
    });
};

export default memo(HourPicker);
