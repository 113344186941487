import { useEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setKeyPressed, setActiveMenuBtnID } from "../actions/eventsActions";
import {
    displayMenu,
    navigateTo,
    setMenuLastFocus,
    setPopUp,
    showLoading,
    setMenuTemporaryType,
    setVideoPos,
    setFlagWidgetsPos,
    displayErrorPopUp,
} from "../actions/uiActions";
import {
    CHECK_ONLINE_PATH,
    DEFAULT_ACTIVE_MENU_HISTORY,
    MENU_TYPES,
    NO_MENU_SCREENS,
    PATHS,
    PERMISSION,
} from "../utils/constants";
import { KEYS } from "../utils/keys";
import { moveFocus } from "../utils/movement";
import { Media } from "./apis/media";
import { STB } from "./apis/stb";
import { navigate } from "wouter/preact/use-location";
import { useLocation } from "wouter/preact";
import { eventHandler } from "../utils/eventHandler";
import focus from "../utils/focus";
import { cloneObject, focusOnLimbo, getCurrentLayout, inHomePage, inPreview, isAdultEnabled } from "../utils/utils";
import { setServerOnline } from "../actions/statusActions";
import { SESSION } from "../utils/session";

const KeyControl = () => {
    const dispatch = useDispatch();
    const [location] = useLocation();
    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const navigateToLayout = useSelector((state) => state.ui.navigateTo);
    const keysHistory = useSelector((state) => state.events.keysHistory);
    const menu = useSelector((state) => state.ui.menu);
    const popup = useSelector((state) => state.ui.popup);
    const showMenu = useSelector((state) => state.ui.showMenu);
    const activeMenuBtnId = useSelector((state) => state.events.activeMenuBtnId);
    const { sessionData, locationData } = useSelector((state) => state.status);

    const [pathHistory, setPathHistory] = useState([]);

    //Functions
    const activeMenuHistory = useRef(DEFAULT_ACTIVE_MENU_HISTORY);
    const menuRef = useRef();
    menuRef.current = menu;
    const showMenuRef = useRef();
    showMenuRef.current = showMenu;
    const pathHistoryRef = useRef();
    pathHistoryRef.current = pathHistory;
    const popupRef = useRef();
    popupRef.current = popup;

    const keyDownAction = (keyData) => {
        if (keyData.value !== KEYS.back) {
            dispatch(setKeyPressed(keyData));
        }
        const focused_item = focus.value.current ? document.getElementById(focus.value.current) : null;
        sessionStorage.getItem("propagateKeyEventToDefault") && sessionStorage.removeItem("propagateKeyEventToDefault");

        //If home => load landing page
        if (keyData.value === KEYS.home) {
            STB.ensureZafiroInterface();

            if (popupRef.current) {
                return;
            }
            if (
                menuRef.current?.menuKeys &&
                menuRef.current.menuKeys.indexOf(keyData.value) > -1 &&
                ((menuRef.current.type !== MENU_TYPES.NONE && NO_MENU_SCREENS.indexOf(getCurrentLayout()) > -1) ||
                    menuRef.current.type === MENU_TYPES.HIDDEN)
            ) {
                dispatch(displayMenu(true));
                focus.value.stack();
            }
            if (
                ((showMenuRef.current && focused_item?.getAttribute("menuItem")) ||
                    menuRef.current?.type === MENU_TYPES.NONE) &&
                !inHomePage()
            ) {
                dispatch(setActiveMenuBtnID(null));
                if (menuRef.current.type === MENU_TYPES.HIDDEN) {
                    dispatch(displayMenu(false));
                }
                dispatch(setMenuTemporaryType(null));
                dispatch(
                    navigateTo({
                        layout: PATHS.HOME,
                    }),
                );
            } else if (menuRef.current?.type !== MENU_TYPES.NONE) {
                dispatch(displayMenu(true));
            }
            return;
        }

        //If arrow key => move focus
        if (keyData.type === "arrow" && !focusOnLimbo()) {
            const itemId = moveFocus(focus.value.current, keyData.value);
            if (itemId) {
                focus.value.replace(itemId);
            } else if (
                !popupRef.current &&
                menuRef.current?.menuKeys &&
                menuRef.current.menuKeys.indexOf(keyData.value) > -1 &&
                menuRef.current.type === MENU_TYPES.HIDDEN
            ) {
                dispatch(displayMenu(true));
            }
        } else if (
            keyData.type === "arrow" &&
            !popupRef.current &&
            menuRef.current?.menuKeys &&
            menuRef.current.menuKeys.indexOf(keyData.value) > -1 &&
            menuRef.current.type === MENU_TYPES.HIDDEN
        ) {
            dispatch(displayMenu(true));
        }

        switch (keyData.value) {
            //enter => click focused element
            case KEYS.enter:
                if (focused_item) {
                    focused_item.click();
                }
                break;

            case KEYS.messages:
                dispatch(setPopUp("messages"));
                break;
            case KEYS.bill:
                dispatch(setPopUp("bill"));
                break;
            case KEYS.checkout:
                dispatch(setPopUp("checkout"));
                break;
            case KEYS.power:
                STB.powerKey();
                break;
            case KEYS.lang:
                dispatch(setPopUp("language"));
                break;

            //Navigate to previous layout
            case KEYS.back:
                if (
                    showMenuRef.current &&
                    (menuRef.current.temporaryType === MENU_TYPES.HIDDEN || menuRef.current.type === MENU_TYPES.HIDDEN)
                ) {
                    dispatch(setMenuLastFocus(focus.value.current));
                    dispatch(displayMenu(false));
                    focus.value.unstack();
                    return;
                }
                if (popup) {
                    dispatch(setPopUp(null));
                    return;
                }
                // browser history back
                if (!inHomePage()) {
                    const prevPathName = pathHistoryRef.current?.[1];
                    if (
                        prevPathName &&
                        (prevPathName === "/" ||
                            prevPathName.indexOf("screen/") > -1 ||
                            prevPathName.indexOf("/roomshop") > -1)
                    ) {
                        sessionStorage.setItem("unstackOnLoad", true);
                    } else {
                        focus.value.unstack();
                    }
                    //On layout change, stop current videos
                    if (Media && (Media.playingMedia || Media.playingChannel)) {
                        Media.showVideoOnBack();
                        Media.stop();
                        setTimeout(() => historyBack(), 200);
                        return;
                    } else {
                        historyBack();
                    }
                }
                break;

            default:
                break;
        }
    };

    const historyBack = () => {
        if (activeMenuHistory.current.length > 1) {
            if (activeMenuHistory.current[activeMenuHistory.current.length - 1].path === document.location.pathname) {
                activeMenuHistory.current.pop();
                dispatch(setActiveMenuBtnID(activeMenuHistory.current[activeMenuHistory.current.length - 1].menuFocus));
            }
        } else {
            dispatch(setActiveMenuBtnID(null));
        }
        dispatch(setVideoPos(null));
        if (STB.manageHistory) {
            eventHandler.locationGoBack();
        } else {
            history.back();
        }
    };

    useEffect(() => {
        //restoreFocus();
    }, [document.activeElement]);

    const simulateKeyPress = (key, keyCode) => {
        // String.fromCharCode(STB.nextKey)
        let e = new KeyboardEvent("keydown", {
            key: key,
            code: key,
            which: keyCode,
            keyCode,
            charCode: keyCode,
            bubbles: true,
            cancelable: true,
        });
        document.getElementById(focus.value.current)?.dispatchEvent(e);
    };

    //onkeydown, dispatch action to set keyDown in events store
    const onkeydown = (e) => {
        restoreFocus(e, true);
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            keyDownAction(keyData);
        }
        e.preventDefault();
    };

    const restoreFocus = function (e, preventSimulate) {
        if (!focus?.value?.current) {
            return;
        }
        //se añade condición && focus.value.current !== "limbo" ya que por alguna razón , en el GoogleTV STB, cuando está un canal a pantalla completa y no aparece el menún de TV, al hacer foco sobre el div limbo no se marca como activeElement,
        // sin embargo el keydown del limbo lo pilla.
        if (
            document.activeElement === document.body /*&&
            (focus.value.current !== "limbo" || (focus.value.current === "limbo" && !document.getElementById("limbo")))*/
        ) {
            if (sessionStorage.getItem("outOfInterface") === "true") {
                const code = e.keyCode ? e.keyCode : e.which;
                let keyData = STB.keyMap.filter((key) => parseInt(key.code) === parseInt(code));
                if (keyData?.[0]?.value === KEYS.home) {
                    STB.backToInterface();
                } else {
                    return;
                }
            }
            //restore foccus
            let itemToFocus = document.getElementById(focus.value.current);
            if (itemToFocus && window.getComputedStyle(itemToFocus)?.["display"] != "none") {
                //ensure that item has tabIndex attribute before focus it
                !itemToFocus.getAttribute("tabIndex") && itemToFocus.setAttribute("tabIndex", 0);
                itemToFocus?.focus();
                //once the focus is in the right element, we fire key event again
                e && !preventSimulate && simulateKeyPress(e.key, e.keyCode);
                return;

                //Case to restore focus if current focused item doesn't exists
            } else {
                setTimeout(function () {
                    const navigableButtons = document.getElementsByClassName("navigable");
                    let toFocus = navigableButtons?.[0];

                    if (toFocus?.id === "limbo" && navigableButtons?.[1]?.id != "noFocus") {
                        toFocus = navigableButtons?.[1];
                    }
                    if (toFocus && document.activeElement === document.body) {
                        dispatch(setPopUp(null));
                        toFocus.focus();
                        focus.value.replace(toFocus.id);
                    } else if (!toFocus && document.activeElement === document.body) {
                        dispatch(setPopUp(null));
                        focus.value.replace("noFocus");
                    }
                    e && !preventSimulate && simulateKeyPress(e.key, e.keyCode);
                }, 800);
            }
        }
    };

    //////////////////
    // Listeners
    //init key control
    useEffect(() => {
        document.onkeydown = onkeydown;
        document.addEventListener("OnKeyReceived", (e) => STB.onKeyReceived(e, simulateKeyPress, restoreFocus), true);

        if (typeof MutationObserver === "function") {
            const observer = new MutationObserver(restoreFocus);
            observer.observe(document, {
                childList: true,
                attributes: true,
                subtree: true,
            });
        } else {
            document.addEventListener("DOMSubtreeModified", restoreFocus);
        }

        if (typeof window !== "undefined") {
            window.simulateKeyPress = (key) => {
                let e = new KeyboardEvent("keydown", {
                    which: key.key,
                    keyCode: key.key,
                    charCode: key.key,
                    bubbles: true,
                    cancelable: true,
                });
                restoreFocus(e);
                document.getElementById(focus.value.current)?.dispatchEvent(e);
            };
            window.ping = () => {
                return STB.pong();
            };
        }

        // eslint-disable-next-line
    }, []);

    //Listen keysHistory to force reload interface when HOME key is pressed 3 consecutive times in an interval of 2 second
    useEffect(() => {
        if (
            keysHistory?.length > 2 &&
            keysHistory[0]?.value === "home" &&
            keysHistory[1]?.value === "home" &&
            keysHistory[2]?.value === "home"
        ) {
            if (new Date(keysHistory[0].time).getTime() - new Date(keysHistory[2].time).getTime() < 2000) {
                dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
                Media.stop();
                dispatch(showLoading(true));
                //Timeout to show Zafiro TV loading message
                setTimeout(() => {
                    //reload only if online network connection
                    var checkServerImage = document.createElement("img");
                    checkServerImage.onload = function () {
                        if (inPreview()) {
                            window.location =
                                window.location.origin +
                                `?lang=${localStorage.getItem("lang")}&token=${sessionStorage.getItem(
                                    "token",
                                )}&stayToken=${sessionStorage.getItem("stayToken")}`;
                        } else {
                            STB.reload();
                        }
                    };
                    checkServerImage.onerror = function () {
                        dispatch(showLoading(false));
                        dispatch(setServerOnline(false));
                    };
                    checkServerImage.src = CHECK_ONLINE_PATH + "?t=" + new Date().getTime();
                }, 400);
            }
        }
    }, [keysHistory]);

    const isNavigationAvailable = () => {
        switch (navigateToLayout.layout) {
            case PATHS.ADULT_MOVIES:
                if (!isAdultEnabled(sessionData)) {
                    dispatch(displayErrorPopUp({ text: texts["content-not-available"], timeout: 3000 }));
                    focus.value.unstack();
                    return false;
                }
                dispatch(setPopUp("adultContentConfirm"));
                return false;
            case PATHS.CCINSTRUCTIONS:
                if (locationData?.machineFunction === "TV" && !locationData?.roomInfo?.chromecastModel) {
                    dispatch(displayErrorPopUp({ text: texts["content-not-available"], timeout: 3000 }));
                    focus.value.unstack();
                    return false;
                }

            default:
                return true;
        }
    };

    const goToLayout = () => {
        SESSION.checkConnection();
        if (!isNavigationAvailable()) {
            return;
        }

        if (navigateToLayout.layout === location) {
            if (menuRef.current.type === MENU_TYPES.HIDDEN || menuRef.current.temporaryType === MENU_TYPES.HIDDEN) {
                dispatch(displayMenu(false));
            }
            return;
        }

        //On layout change, stop current videos
        if (Media && (Media.playingMedia || Media.playingChannel)) {
            Media.showVideoOnBack();
            Media.stop();
            dispatch(setVideoPos(null));
            setTimeout(() => goToLayout(), 200);
            return;
        }

        if (location !== navigateToLayout.layout) {
            let navigateID = navigateToLayout.id ? `/${navigateToLayout.id}` : "";
            if (navigateToLayout.layout === PATHS.HOME) {
                activeMenuHistory.current = [activeMenuHistory.current.shift()];
            } else if (activeMenuBtnId && navigateToLayout.layout !== PATHS.HOME) {
                activeMenuHistory.current.push({
                    menuFocus: activeMenuBtnId,
                    path: `${navigateToLayout.layout}${navigateID}`,
                });
            }
            navigate(`${navigateToLayout.layout}${navigateID}`);
            dispatch(setFlagWidgetsPos(true));
        }
    };

    useEffect(() => {
        if (navigateToLayout) {
            goToLayout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToLayout]);

    useEffect(() => {
        const tmp = cloneObject(pathHistory);
        if (window.location.pathname !== tmp[0]) {
            tmp.unshift(window.location.pathname);
            setPathHistory(tmp.slice(0, 2));
        }
    }, [window.location.pathname]);

    return <></>;
};

export default KeyControl;
