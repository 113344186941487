import memize from "memize";
import { vendureQuery } from "../../../hooks/query";

type QueryResult = {
    data: {
        shippingMethods: {
            items: {
                code: string;
                translations: {
                    name: string;
                    languageCode: string;
                }[];
            }[];
        };
    };
};

export default memize(
    (): Promise<QueryResult> =>
        new Promise((resolve, reject) =>
            vendureQuery({
                apiType: "admin",
                headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}` },
                cache: true,
                body: `
            query{
              shippingMethods(options: {take: 999}) {
                items {           
                  id                     
                  code
                  translations{
                      name
                      languageCode
                  }
                }
              }
            }`,
                onResponse: (data: QueryResult) => resolve(data),
                onError: (e) => reject(e),
            }),
        ),
);
