//@ts-nocheck
import { carrito } from "../signals/carrito";
import api from "../api";
import { Cart } from "sales-layout-library/tv";
import focus from "../../../utils/focus";
import { useDispatch } from "react-redux";
import { setPopUp } from "../../../actions/uiActions";
import storage from "../../../utils/storage";
import VerticalList from "../../reusable/verticalList";
import Image from "../../reusable/image";

export const onSubmit = async ({ dispatch }: { dispatch: any }) => {
    dispatch(setPopUp("confirmOrder"));
};

export const onClearCart = async ({ dispatch }: { dispatch: any }) => {
    dispatch(setPopUp("clearCart"));
};
export const onItemClick = async ({ dispatch }: { dispatch: any }) => {
    dispatch(setPopUp("CartItemModal"));
};

export default ({
    channel,
    variants,
    templating,
    texts,
    extras,
}: {
    channel: Awaited<ReturnType<typeof api.shopByToken>>;
    extras: Record<string, Awaited<ReturnType<typeof api.extra>> & { name: string }>;
    variants: { [key: string]: Awaited<ReturnType<typeof api.variant>> };
    templating: any;
    texts: { [key: string]: string };
    languageCode: string;
    projectLanguageCode: string;
}) => {
    const dispatch = useDispatch();
    storage.set("orderToken", channel.token);
    sessionStorage.setItem("currencyCode", channel.currencyCode);
    let productsWithImages = false;
    for (const product in variants) {
        if (variants[product]?.featuredAsset) {
            productsWithImages = true;
        }
    }
    //variants?.forEach(product => { if(product?.featuredAsset){ noImages=false; }});

    return (
        <Cart
            data={{
                shop: {
                    name: channel.name,
                    token: channel.token,
                    currencyCode: channel.currencyCode,
                    pricesIncludeTax: channel.pricesIncludeTax,
                    productsWithImages: productsWithImages,
                },
                signals: { cart: carrito },
                variants: variants as any,
                extras: extras as any,
            }}
            signals={{ focus }}
            templating={{ ...templating, texts }}
            injected={{
                onClearCart: () => onClearCart({ dispatch }),
                onSubmit: () => onSubmit({ dispatch }),
                onItemClick: () => onItemClick({ dispatch }),
                List: VerticalList as any,
                Image: Image as any,
            }}
        />
    );
};
