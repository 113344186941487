import { combineReducers } from "redux";
import reducerEvents from "./reducerEvents";
import reducerStatus from "./reducerStatus";
import reducerDevice from "./reducerDevice";
import reducerUI from "./reducerUI";

export default combineReducers({
    events: reducerEvents,
    status: reducerStatus,
    device: reducerDevice,
    ui: reducerUI,
});
