import { cloneElement } from "preact";
import { memo, useMemo } from "preact/compat";

const ItemDetail = ({ element, updateElement }) => {
    return useMemo(() => {
        return cloneElement(element.detailComponent, {
            item: element,
            itemUpdate: (item, attbs) => {
                updateElement(item, attbs);
            },
        });
    }, []);
};

export default memo(ItemDetail);
