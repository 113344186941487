import { MENU_TYPES } from "../../utils/constants";
import { KEYS } from "../../utils/keys";
import { getMenuNavigableItemsBoundingClientRect, isElementVisible, moveFocus } from "../../utils/movement";
import { resetMenuScrollValues, screenUtils } from "../../utils/screenUtils";
import { displayMenu, setFlagWidgetsPos } from "../../actions/uiActions";
import focus from "../../utils/focus";

export const ScreenKeyControl = ({ direction, dispatch, sessionData, menu, flagGetWidgetPos, activeMenuBtnId }) => {
    const endOfList = (direction) => {
        if (direction === KEYS.right || direction === KEYS.down) {
            return !document.querySelector(`#${focus.value.current}`).nextSibling;
        } else if (direction === KEYS.left || direction === KEYS.up) {
            return !document.querySelector(`#${focus.value.current}`).previousSibling;
        }
    };

    /*
     * Get next widget to focus from the current widget focused position
     */
    const getWidgetToFocus = (direction, isMenuItem, justScreen) => {
        const focusedItem = document.getElementById(focus.value.current);
        const focusedWidgetId = focusedItem?.getAttribute("widgetId");
        // for fixed menu all navigable screen widgets + all navigable menu widgets
        let allNavigalbeWidgets = screenUtils.widgetsPos?.TV.filter((element) => element.navigable).concat(
            window?.MenuRender?.menuWidgetsPos ? window.MenuRender.menuWidgetsPos : [],
        );
        if (isMenuItem && (menu.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN)) {
            // just menu navigable widgets
            allNavigalbeWidgets = window?.MenuRender?.menuWidgetsPos ? window.MenuRender.menuWidgetsPos : [];
        } else if (
            (menu && (menu.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN)) ||
            justScreen
        ) {
            // just all navigable screen widgets
            allNavigalbeWidgets = screenUtils.widgetsPos.TV.filter((element) => element.navigable);
        }

        let closestElement = false;
        if (allNavigalbeWidgets && allNavigalbeWidgets.length > 0 && focusedWidgetId) {
            const focusedWidgetPosis = { ...allNavigalbeWidgets.filter((pos) => pos.ID == focusedWidgetId)[0] };

            if (!focusedWidgetPosis) {
                return;
            }
            let inWidgetZoneElements = [];
            let inDirectionElements = [];
            allNavigalbeWidgets.map((wPos) => {
                //skip the loop over the current element and no focusable widget
                if (
                    wPos.ID != focusedWidgetId &&
                    wPos.navigable &&
                    isElementVisible(document.getElementById(window.ScreenRender?.divPrefix + wPos.ID))
                ) {
                    if (!wPos.boundingClient || wPos.menuNavItem) {
                        wPos.boundingClient = document
                            .getElementById(window.ScreenRender.divPrefix + wPos.ID)
                            .getBoundingClientRect();
                    }
                    if (!focusedWidgetPosis.boundingClient || focusedWidgetPosis.menuNavItem) {
                        focusedWidgetPosis.boundingClient = document
                            .getElementById(window.ScreenRender.divPrefix + focusedWidgetId)
                            .getBoundingClientRect();
                    }
                    // to evaluate a widget must be all widget from the border of the focused widget to direction moving
                    if (
                        (direction === KEYS.right &&
                            wPos.boundingClient.left <= focusedWidgetPosis.boundingClient.right) ||
                        (direction === KEYS.left &&
                            wPos.boundingClient.right >= focusedWidgetPosis.boundingClient.left) ||
                        (direction === KEYS.down &&
                            wPos.boundingClient.top <= focusedWidgetPosis.boundingClient.bottom) ||
                        (direction === KEYS.up && wPos.boundingClient.bottom >= focusedWidgetPosis.boundingClient.top)
                    ) {
                        return false;
                    }
                    // widget is in move direction
                    inDirectionElements.push(wPos);
                    // evalue if is in widget zone
                    if (
                        isWidgetZone(focusedWidgetPosis, wPos, direction) &&
                        ((!focusedWidgetPosis.menuitem && !wPos.menuitem) ||
                            (focusedWidgetPosis.menuitem && wPos.menuitem))
                    ) {
                        inWidgetZoneElements.push(wPos);
                    }
                }
            });
            // seek for closest element in direction
            let elementsToSearch = null;
            if (inWidgetZoneElements.length > 0) {
                elementsToSearch = inWidgetZoneElements;
            } else if (inDirectionElements.length > 0) {
                elementsToSearch = inDirectionElements;
            }
            // select closest element
            closestElement = getClosestElement(focusedWidgetPosis, elementsToSearch);
        }
        return closestElement.ID;
    };

    const isWidgetZone = (origin, destiny, direction) => {
        let _result = false;
        let midPointsDestiny = midPointOf(destiny);
        switch (direction) {
            case KEYS.right:
            case KEYS.left:
                if (
                    (destiny.boundingClient.top >= origin.boundingClient.top &&
                        destiny.boundingClient.top <= origin.boundingClient.bottom) ||
                    (destiny.boundingClient.bottom >= origin.boundingClient.top &&
                        destiny.boundingClient.bottom <= origin.boundingClient.bottom) ||
                    (destiny.boundingClient.top <= origin.boundingClient.top &&
                        destiny.boundingClient.bottom >= origin.boundingClient.bottom) ||
                    (midPointsDestiny.top >= origin.boundingClient.top &&
                        midPointsDestiny.top <= origin.boundingClient.bottom)
                ) {
                    _result = true;
                }
                break;
            case KEYS.up:
            case KEYS.down:
                if (
                    (destiny.boundingClient.left >= origin.boundingClient.left &&
                        destiny.boundingClient.left <= origin.boundingClient.right) ||
                    (destiny.boundingClient.right >= origin.boundingClient.left &&
                        destiny.boundingClient.right <= origin.boundingClient.right) ||
                    (destiny.boundingClient.left <= origin.boundingClient.left &&
                        destiny.boundingClient.right >= origin.boundingClient.right) ||
                    (midPointsDestiny.left >= origin.boundingClient.left &&
                        midPointsDestiny.left <= origin.boundingClient.right)
                ) {
                    _result = true;
                }
                break;
            default:
                break;
        }
        return _result;
    };

    const getClosestElement = (origin, array) => {
        if (!array || array.length === 0) {
            return false;
        }
        // mid points of origin
        let midPointsOrigin = midPointOf(origin);
        let dist = null;
        let _closestElement = false;
        array.forEach((element) => {
            // mid point of element
            let midPointsElement = midPointOf(element);
            // distance between points
            let distanceOrgToElem = squareRoot(
                Math.pow(midPointsOrigin.left - midPointsElement.left, 2) +
                    Math.pow(midPointsOrigin.top - midPointsElement.top, 2),
            );
            // evaluate distance
            if (!dist || distanceOrgToElem < dist) {
                dist = distanceOrgToElem;
                _closestElement = element;
            }
        });
        return _closestElement;
    };

    const squareRoot = (value) => {
        let loop = 5;
        let x1 = value / 2;
        let x2;
        for (let i = 0; i < loop; i++) {
            x2 = x1 - (x1 * x1 - value) / (2 * x1);
            x1 = x2;
        }
        return x1;
    };

    const midPointOf = (element) => {
        return {
            left: Math.abs(
                element.boundingClient.left + (element.boundingClient.right - element.boundingClient.left) / 2,
            ),
            top: Math.abs(
                element.boundingClient.top + (element.boundingClient.bottom - element.boundingClient.top) / 2,
            ),
        };
    };

    const forceStayInMenuOrScreen = (direction, nextElement) => {
        const focusedItem = document.getElementById(focus.value.current);
        // force to stay in menu only exit with direction to screen
        if (focusedItem.getAttribute("menuitem") && nextElement.getAttribute("menuitem")) {
            return false;
        } else if (focusedItem.getAttribute("menuitem") && !nextElement.getAttribute("menuitem")) {
            return !inAndOutMenu(direction, "out");
        } else if (!focusedItem.getAttribute("menuitem") && nextElement.getAttribute("menuitem")) {
            return !inAndOutMenu(direction, "in");
        }
    };

    const inAndOutMenu = (direction, io) => {
        const EVAL_MENU_ANIMATION = {
            fadeInAnimation: (direction, io) => {
                if ([KEYS.left, KEYS.right, KEYS.up, KEYS.down].indexOf(direction) > -1) {
                    return true;
                }
            },
            rightAnimation: (direction, io) => {
                if ((KEYS.left === direction && io === "out") || (KEYS.right === direction && io === "in")) {
                    return true;
                }
            },
            leftAnimation: (direction, io) => {
                if ((KEYS.right === direction && io === "out") || (KEYS.left === direction && io === "in")) {
                    return true;
                }
            },
            bottomAnimation: (direction, io) => {
                if ((KEYS.up === direction && io === "out") || (KEYS.down === direction && io === "in")) {
                    return true;
                }
            },
            default: () => {
                return false;
            },
        };
        return EVAL_MENU_ANIMATION[menu.animation]
            ? EVAL_MENU_ANIMATION[menu.animation](direction, io)
            : EVAL_MENU_ANIMATION.default();
    };

    //Functions
    const onArrowKey = (direction) => {
        if (flagGetWidgetPos) {
            screenUtils.getoffSetNavigableWidgets();
            dispatch(setFlagWidgetsPos(false));
        }
        if (window.MenuRender && !window.MenuRender.tvmenunavOffSetToApply) {
            window.MenuRender.tvmenunavOffSetToApply = {};
        }
        const focusedItem = document.getElementById(focus.value.current);
        let focusedMenuItem = focusedItem?.getAttribute("menuitem");
        let wToFOcus = null;
        if (focusedMenuItem && !endOfList(direction) && isNaN(Number(focus.value.current))) {
            // default movement to use scroll of list (return ID of widget)
            let toFocus = moveFocus(focus.value.current, direction);
            if (toFocus) {
                wToFOcus = toFocus.replace(window.ScreenRender?.divPrefix, "");
            }
        } else {
            // screen movement (return ID of widget)
            wToFOcus = getWidgetToFocus(direction, focusedMenuItem);
        }
        if (wToFOcus) {
            let elemenToFocus = document.getElementById(window.ScreenRender?.divPrefix + wToFOcus);

            // RESET SCROLL IF GOING OUT OF MENU
            if (elemenToFocus && !elemenToFocus.getAttribute("menuitem") && focusedMenuItem) {
                // reset menu scroll
                resetMenuScrollValues(direction);
            }
            if (
                elemenToFocus &&
                elemenToFocus.getAttribute("menuitem") &&
                !focusedMenuItem &&
                window.MenuRender?.activeMenuOffSet?.[elemenToFocus.parentNode.id]
            ) {
                window.MenuRender.tvmenunavOffSetToApply[elemenToFocus.parentNode.id] =
                    window.MenuRender.activeMenuOffSet[elemenToFocus.parentNode.id];
            }
            // allow only exit from menu when arrow is in direction of screen
            if (menu.type !== MENU_TYPES.FIXED && forceStayInMenuOrScreen(direction, elemenToFocus)) {
                // if founds a menuWidget and it is screen widget will seach just un screen widgets again to check if there is another next focusable in screen
                let screenwToFOcus = getWidgetToFocus(direction, focusedMenuItem, true);
                if (screenwToFOcus) {
                    focus.value.replace(window.ScreenRender.divPrefix + screenwToFOcus);
                }
                return;
            }

            if (focusedMenuItem && !elemenToFocus?.getAttribute("menuitem") && !activeMenuBtnId) {
                // will get out of menu focus then reset ALL scroll of navigalbe items
                if (
                    MenuRender.activeMenuOffSet &&
                    MenuRender.activeMenuOffSet[document.activeElement.parentElement.id]
                ) {
                    MenuRender.activeMenuOffSet[document.activeElement.parentElement.id] = {};
                }
                getMenuNavigableItemsBoundingClientRect();
            } else if (!focusedMenuItem && elemenToFocus?.getAttribute("menuitem")) {
                // will enter menu, get focus of first menu widget navigable
                if (activeMenuBtnId) {
                    wToFOcus = activeMenuBtnId.replace(window.ScreenRender.divPrefix, "");
                    getMenuNavigableItemsBoundingClientRect();
                } else {
                    wToFOcus = window.MenuRender.getFirstWidgetToFocus(sessionData, true).replace(
                        window.ScreenRender.divPrefix,
                        "",
                    );
                }
            }
            focus.value.replace(window.ScreenRender?.divPrefix + wToFOcus);
        } else if (
            !menu?.showing &&
            menu?.menuKeys &&
            menu.menuKeys.indexOf(direction) > -1 &&
            (menu.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN)
        ) {
            dispatch(displayMenu(true));
        }
    };
    onArrowKey(direction);
};
