import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp } from "../../../actions/uiActions";
import { isMovieFullyWatched } from "../../../utils/movies";
import { figmapx2vw, inPreview } from "../../../utils/utils";
import Button from "../../../components/common/button";

const StartOverButton = ({ itemUpdated, startOverMovie }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    if (itemUpdated.rented && !isMovieFullyWatched(itemUpdated.visualizations)) {
        return (
            <Button
                insideModal={true}
                data={{
                    id: "start-over",
                    icon: "start-over",
                    iconSize: "text-5xl",
                    customClass: `text-gray-100 text-center p-1`,
                    customStyle: {
                        width: figmapx2vw(54),
                        height: figmapx2vw(54),
                        borderRadius: "50%",
                        color: "#F5F6F8",
                    },
                    focusClass: "grid-btn-back-focus rounded",
                    onClick() {
                        if (inPreview()) {
                            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
                            return;
                        }
                        // start over the movie
                        startOverMovie();
                    },
                }}
            />
        );
    }
    return null;
};

export default StartOverButton;
