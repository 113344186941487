import Query from "../query";

const mutationSaveAlarm = (alarmData) => {
    const { alarmType, alarmTime, chSelected, sessionData, locationData, onResponse } = alarmData;
    Query({
        query: `
        mutation{ 
        createAlarm(
                   type: ${alarmType}
                   stayGuestRoomId: ${
                       sessionData.stayguest.stayGuestRooms.filter((r) => r.roomID === sessionData.room.id)[0].id
                   }
                   roomTvId: ${locationData.roomInfo.roomTvId}
                   ${alarmType === "TVCHANNEL" ? `tvChannelId: ${chSelected.replace("channelSelector-", "")}` : ""}
                   startTime: "${alarmTime.toISOString()}"
                ){
                    error
                    ok
            }
        }
        `,
        onResponse,
    });
};

export default mutationSaveAlarm;
