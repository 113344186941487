import { useEffect, useState } from "preact/hooks";
import Limbo from "../../components/screen/limbo";
import focus from "../../utils/focus";
import { Media } from "../../hooks/apis/media";
import {
    figmapx2vh,
    figmapx2vw,
    getDasExternalUrl,
    setVideoOnBackground,
    setVideoOnForeground,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp, setVideoPos } from "../../actions/uiActions";
import { MOVIE_DETAIL_DISPLAY } from "../../utils/constants";
import { addBgTransparentImportant } from "../../utils/movies";
import { setMediaEvent } from "../../actions/eventsActions";
import { EVENTS } from "../../utils/eventsConst";
import ReactHtmlParser from "react-html-parser";
import { useRef } from "react";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";

const Trailer = ({ movie, backToMovieDetail }) => {
    const MENU_TIMEOUT = 4000;

    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const isVideoFullScreen = useSelector((state) => state.ui.isVideoFullScreen);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);

    const [showMenu, setShowMenu] = useState(true);

    const menuTimeout = useRef();

    useEffect(() => {
        restartMenuTimeout();
        focus.value.replace("movieTrailerLimbo");
        setVideoOnBackground();
        Media.stop();
        dispatch(setMediaEvent(null));
        Media.playHLSVideo(movie.trailerUrl);
        dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));

        setTimeout(() => {
            dispatch(setVideoPos("fullscreen"));
        }, 100);
        return () => {
            setVideoOnForeground();
            Media.stop();
            dispatch(setMediaEvent(null));
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
        };
    }, []);

    useEffect(() => {
        if (reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM) {
            backToMovieDetail();
            dispatch(setMediaEvent(null));
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_ERROR) {
            dispatch(
                displayErrorPopUp({
                    text: texts["content-not-available"],
                    onClose: () => {
                        backToMovieDetail();
                    },
                    timeout: 3000,
                }),
            );
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_SUCCESS) {
            addBgTransparentImportant("trailer_backgroundImage");
        }
    }, [reducerMediaEvent]);

    useEffect(() => {
        if (isVideoFullScreen) {
            addBgTransparentImportant("trailer_backgroundImage");
        }
    }, [isVideoFullScreen]);

    const restartMenuTimeout = () => {
        setShowMenu(true);
        clearTimeout(menuTimeout.current);
        menuTimeout.current = setTimeout(function () {
            setShowMenu(false);
        }, MENU_TIMEOUT);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);

        if (keyData) {
            switch (keyData.value) {
                case KEYS.power:
                case KEYS.home:
                    return;
                case KEYS.back:
                    backToMovieDetail();
                    break;
                default:
                    restartMenuTimeout();
                    break;
            }
        }
        eventHandler.stopPropagation(e);
    };

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo id={"movieTrailerLimbo"} noBlocker={true} />
            {showMenu && (
                <div
                    className="fixed table top-0 left-0 w-full h-full bg-black bg-no-repeat bg-center bg-cover"
                    id="trailer_backgroundImage"
                    style={{
                        visibility: "visible",
                        background: "transparent",
                        /*backgroundImage: `url(${getDasExternalUrl(movie.backgroundUrl, {
                            w: window.innerWidth,
                            h: window.innerHeight,
                        })})`,*/
                    }}
                >
                    <div
                        id="trailer_backgroundGradient"
                        style={{
                            width: "100vw",
                            paddingLeft: `${figmapx2vw(64)}`,
                            fontSize: `${figmapx2vw(28)}`,
                            height: `${figmapx2vh(164)}`,
                            background: "linear-gradient(0deg, #373D4200 0%, #373D429E 62%, #373D42 100%)",
                            color: "#FFFFFF",
                        }}
                    >
                        <span
                            className={"icon icon-tv-back-remote"}
                            style={{
                                lineHeight: `${figmapx2vh(164)}`,
                                marginRight: `${figmapx2vw(10)}`,
                            }}
                        ></span>
                        {`${texts["Trailer"]} - ${ReactHtmlParser(movie.name)}`}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Trailer;
