import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigateTo, setLanding } from "../../actions/uiActions";
import { isWelcomed } from "../../utils/utils";
import Query from "../query";

const GetLanding = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const landingPage = useSelector((state) => state.ui.landingPage);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { sessionData } = useSelector((state) => state.status);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (isWelcomed(sessionData) && !landingPage && sessionData && sessionData.hash) {
            Query({
                query: `{
                        landingContent{
                            id
                            name
                            designID
                            contentStyle
                            contentWidgets{
                                ID
                                contentWidgets{
                                    ID
                                    actions
                                    data
                                    pos
                                    type
                                    widgetStyle
                                    fonts{
                                        externalURL
                                        libraryRef
                                        name
                                    }
                                }
                                data
                                pos
                                type
                                actions
                                widgetStyle
                                fonts{
                                    externalURL
                                    libraryRef
                                    name
                                }
                            }
                            devices{
                                grid{
                                    cols
                                    rows
                                }
                                type
                            }
                            pos
    
                    }
                }`,
                onResponse(res) {
                    if (!res?.data?.landingContent?.designID) {
                        const dataText = res.data.landingContent?.contentWidgets[0].data
                            ? JSON.parse(res.data.landingContent?.contentWidgets[0].data)
                            : null;
                        dispatch(setLanding({ noLanding: true }));
                        dispatch(
                            navigateTo({
                                layout: "noLanding",
                                text: dataText?.Code?.Text,
                            }),
                        );
                    } else if (res?.data?.landingContent) {
                        dispatch(setLanding(res.data.landingContent));
                    }
                },
            });
        }
    }, [sessionData, landingPage, dispatch]);
};

export default GetLanding;
