import { Theme } from "./theme";

// @ts-ignore
const getProp = <T extends {}>(obj: T, key: string): string => key.split(".").reduce((o, x) => (typeof o == "undefined" || o === null ? o : o[x]), obj) as unknown as string;
const getButtons = <T extends {}>(colors: T) => {
    return {
        default: {
            color: getProp(colors, "buttons.status.default.colors.fgColor"),
            backgroundColor: getProp(colors, "buttons.status.default.colors.bgColor"),
            borderColor: getProp(colors, "buttons.status.default.border.color"),
            borderWidth: getProp(colors, "buttons.status.default.border.width"),
        },
        active: {
            color: getProp(colors, "buttons.status.active.colors.fgColor"),
            backgroundColor: getProp(colors, "buttons.status.active.colors.bgColor"),
            borderColor: getProp(colors, "buttons.status.active.border.color"),
            borderWidth: getProp(colors, "buttons.status.active.border.width"),
        },
        focus: {
            color: getProp(colors, "buttons.status.focus.colors.fgColor"),
            backgroundColor: getProp(colors, "buttons.focus.active.colors.bgColor"),
            borderColor: getProp(colors, "buttons.status.focus.border.color"),
            borderWidth: getProp(colors, "buttons.status.focus.border.width"),
        },
    };
};
const getSection2 = <T extends {}>(colors: T) => {
    return {
        default: {
            color: getProp(colors, "section2.status.default.colors.fgColor"),
            backgroundColor: getProp(colors, "section2.status.default.colors.bgColor"),
        },
    };
};
const getSection1 = <T extends {}>(colors: T) => {
    return {
        default: {
            color: getProp(colors, "section1.colors.fgColor"),
            backgroundColor: getProp(colors, "section1.colors.bgColor"),
        },
    };
};
const getSection3 = <T extends {}>(colors: T) => {
    return {
        default: {
            color: getProp(colors, "section3.colors.fgColor"),
            backgroundColor: getProp(colors, "section3.colors.bgColor"),
        },
    };
};
export default <T extends {}>(colors: T) => {
    if (!colors) {
        //@ts-ignore
        colors = {
            background: { opacity: 100, color: "rgba(255,255,255,1)", targets: false },
            buttons: {
                status: {
                    default: {
                        colors: {
                            bgColor: Theme?.colors?.defaultBackground,
                            fgColor: Theme?.colors?.defaultForeground,
                        },
                        border: {
                            color: "#D3DAE1",
                            width: 1,
                        },
                    },
                    active: {
                        colors: {
                            bgColor: Theme?.colors?.activeBackground,
                            fgColor: Theme?.colors?.activeForeground,
                        },
                        border: {
                            color: "rgba(255,255,255,1)",
                            width: 0,
                        },
                    },
                    focus: {
                        colors: {
                            bgColor: Theme?.colors?.focusBackground,
                            fgColor: Theme?.colors?.focusForeground,
                        },
                        border: {
                            color: "rgba(34,134,239,0.8)",
                            width: 5,
                        },
                    },
                },
                targets: true,
            },
            items: {
                status: {
                    focus: {
                        colors: {
                            bgColor: Theme?.colors?.focusBackground,
                            fgColor: Theme?.colors?.focusForeground,
                        },
                    },
                },
                targets: true,
            },
            section1: {
                colors: {
                    bgColor: "#76889B",
                    fgColor: "rgba(255,255,255,1)",
                },
                targets: true,
            },
            section2: {
                status: {
                    default: {
                        colors: {
                            fgColor: "rgba(255,255,255,1)",
                            bgColor: "#76889B",
                        },
                    },
                    focus: {
                        colors: {
                            fgColor: Theme?.colors?.focusForeground,
                            bgColor: Theme?.colors?.focusBackground,
                        },
                    },
                },
                targets: true,
            },
            section3: {
                colors: {
                    bgColor: "rgba(245,246,248,0.8)",
                    fgColor: "#2E3843",
                },
                targets: true,
            },
        };
    }

    const stylesheet = {
        roomshops: () => {
            return {
                items: {
                    focus: {
                        backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                    },
                },
                section1: getSection1(colors),
                section3: getSection3(colors),
            };
        },
        cart: () => {
            return {
                items: {
                    focus: {
                        color: getProp(colors, "items.status.focus.colors.fgColor"),
                        backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                    },
                },
                section1: getSection1(colors),
                section3: getSection3(colors),
                buttons: getButtons(colors),
            };
        },
        products: () => {
            return {
                items: {
                    focus: {
                        borderColor: getProp(colors, "items.status.focus.colors.fgColor"),
                    },
                },
                section1: getSection1(colors),
                section3: getSection3(colors),
                buttons: getButtons(colors),
                section2: getSection2(colors),
            };
        },
        productDetails: () => {
            return {
                items: {
                    focus: {
                        backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                    },
                },
                section1: getSection1(colors),
                section3: getSection3(colors),
                buttons: getButtons(colors),
                section2: getSection2(colors),
            };
        },
    };
    return {
        roomshops: stylesheet.roomshops(),
        cart: stylesheet.cart(),
        products: stylesheet.products(),
        productDetails: stylesheet.productDetails(),
    };
};
