import { STB } from "../hooks/apis/stb";
import focus from "./focus";
import { writeLog } from "./utils";

export const Logger = {
    init() {
        //Enable log in development
        if (process.env.PREACT_APP_DEVELOPMENT && typeof window !== "undefined") {
            window.onerror = function (message, source, lineno, colno) {
                writeLog(`ERROR: ${source} (line: ${lineno}, col: ${colno}) -   ${message}`, true);
            };
        }

        /*  
        setInterval(() => {
            Logger.serverRequest("Active: " + document.activeElement.id);
            Logger.serverRequest("FOCO: " + focus.value.current);
        }, 5000);
        */
    },
    remoteLog(text) {
        console.log(text);
        fetch("http://tvgw.local:2222/logger/logger.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: "ref=" + STB.ref + "&msg=" + encodeURIComponent(text),
        }).catch(function (error) {
            console.log("tvgw.local:2222 no responding");
        });
    },

    serverRequest(text) {
        // Creating Our XMLHttpRequest object
        let xhr = new XMLHttpRequest();

        text = text.replaceAll(" ", "_");
        // Making our connection
        let url = `http://10.154.2.71/devRef/${STB.ref}/LOG/${text}?t=${new Date().getTime()}`;
        //let url = `http://10.11.1.1?${text}${new Date()}`;
        xhr.open("GET", url, true);

        // function execute after request is successful
        xhr.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                console.log(this.responseText);
            }
        };
        // Sending our request
        xhr.send();
    },
};
