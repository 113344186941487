import type { FunctionComponent } from "preact";
import { makeKeyboardEvent } from "../../utils/events";
import { Children, PropsWithChildren } from "preact/compat";
import { useSignal } from "@preact/signals";
import scrollPolyfill from 'scroll-polyfill'

type Options = {
    delays?: {
        initial?: number,
        tick?: number,
        restart?: number,
    },
    speed?: number,
}

const DEFAULT_OPTIONS = {
    delays: {
        initial: 500,
        tick: 100,
        restart: 2500
    },
    speed: 24,
} as const;


export function autoscrollable<T extends FunctionComponent<any>>(Component: T, opts: Options) {
    const options = { ...DEFAULT_OPTIONS, ...opts, delays: { ...DEFAULT_OPTIONS.delays, ...opts.delays } };
    const scrollHandler = makeKeyboardEvent(['up', 'down', 'left', 'right', 'enter', 'back'], (key, ev) => {
        const target = ev.target as HTMLDivElement;
        if (key === 'up')
            target.scrollBy(0, -options.speed);
        else if (key === 'down')
            target.scrollBy(0, options.speed);
        else if (key === 'right')
            target.scrollBy(options.speed, 0);
        else if (key === 'left')
            target.scrollBy(-options.speed, 0);
        else if (key === 'enter' || key === 'back')
            handler.value = lockHandler;
        return { stopPropagation: true, preventDefault: true };
    });
    //No quitar los var, hay DEPENDENCIAS CÍCLICAS que resuelven en tiempo de ejecución
    var lockHandler = makeKeyboardEvent(['enter'], () => {
        handler.value = scrollHandler;
        return { stopPropagation: true, preventDefault: true };
    });
    var handler = useSignal(lockHandler);
    return function (props: Parameters<T>[0]) {
        return <Component {...props} onKeydown={handler.value} />
    }
}

export function Autoscrollable({ opts = {}, children }: PropsWithChildren<{ opts?: Options }>) {
    const options = { ...DEFAULT_OPTIONS, ...opts, delays: { ...DEFAULT_OPTIONS.delays, ...opts.delays } };
    const scrollHandler = makeKeyboardEvent(['up', 'down', 'left', 'right', 'enter', 'back'], (key, ev) => {
        scrollPolyfill({ force: true })
        const target = ev.target as HTMLDivElement;
        if (key === 'up' && target.scrollTop !== 0) { target.scrollBy(0, -options.speed); }
        else if (key === 'down' && target.scrollTop < target.scrollHeight - target.clientHeight) { target.scrollBy(0, options.speed); }
        else if (key === 'right' && target.scrollLeft < target.scrollWidth - target.clientWidth) { target.scrollBy(options.speed, 0); }
        else if (key === 'left' && target.scrollLeft !== 0) { target.scrollBy(-options.speed, 0); }
        else {
            if(key !== 'down'){
                target.scrollTop = 0;
            }
            target.scrollLeft = 0;
            return { preventDefault: false };
        }
        return { stopPropagation: true, preventDefault: true };
    });
    const Component = Children.only(children);
    return <div onKeyDown={scrollHandler}>{Component}</div>;
}