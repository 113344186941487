import query from "../../../hooks/query";
import memize from "memize";
import { Theme } from "../../../utils/theme";
import { completeDasURL, px2vw } from "../../../utils/utils";

const fn = (designId: number): Promise<{ data: string; name: string }> =>
    new Promise<{ data: string; name: string }>((resolve) =>
        query({
            query: `
    {
      salesScreen(designID: "${designId}") {
        data
        name
      }
    }`,
            onResponse: (x) => {
                const salesTheme = x?.data?.salesScreen ? JSON.parse(x?.data?.salesScreen?.data) : null;

                if (salesTheme && !salesTheme?.background?.useHomeBg) {
                    const salesBg = {
                        image:
                            salesTheme?.background?.externalUrl ||
                            completeDasURL(salesTheme?.background?.libraryRef, { width: "" }),
                        opacity: salesTheme.style.background.opacity / 100,
                        bgColor: salesTheme.style.background.color,
                    };
                    sessionStorage.setItem("salesBg", JSON.stringify(salesBg));
                } else {
                    sessionStorage.removeItem("salesBg");
                }
                let focusSize = 5;
                if (Theme?.focus?.size) {
                    //@ts-ignore
                    focusSize = parseInt(Theme.focus["size"].replace("px", ""));
                }
                if (salesTheme) {
                    const css = `#sales .focusOutline { box-shadow: 0 0 0 ${Theme.focus?.size} ${salesTheme?.style
                        ?.items?.status?.focus?.colors?.bgColor} !important;}
                     #sales .sales-buttons.btnFocused { background-color: ${salesTheme?.style?.buttons?.status?.focus
                         ?.colors?.bgColor} !important; color: ${salesTheme?.style?.buttons?.status?.focus?.colors
                         ?.fgColor} !important;}
                     #sales .btnFocused { background-color: ${salesTheme?.style?.items?.status?.focus?.colors
                         ?.bgColor} !important; color: ${salesTheme?.style?.items?.status?.focus?.colors
                         ?.fgColor} !important; border-width: ${px2vw(focusSize)}vw; border-color: ${salesTheme?.style
                         ?.items?.status?.focus?.colors?.bgColor}; border-style: solid }`;

                    Theme.appendStyleToHead(css);
                }

                resolve(x?.data?.salesScreen ? JSON.parse(x?.data?.salesScreen?.data) : null);
            },
        }),
    );

export default memize(fn) as typeof fn;
