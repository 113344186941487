/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useMemo } from "preact/compat";
import NumericButton from "./numericButton";

const DatePicker = ({ day, month, year, setDay, setMonth, setYear, insideModal, tomorrow }) => {
    useEffect(() => {
        const cDate = new Date();
        tomorrow && cDate.setDate(cDate.getDate() + 1);

        setDay(day || cDate.getDate());
        setMonth(month || cDate.getMonth() + 1);
        setYear(year || cDate.getFullYear());
    }, []);

    useEffect(() => {
        checkMonthDays();
    }, [day, month, year]);

    //Avoid insert wrong day for a month&year
    const checkMonthDays = () => {
        const monthDays = new Date(year, month, 0).getDate();
        if (day > monthDays) {
            setDay(monthDays);
        }
    };

    return useMemo(() => {
        return (
            <div className="table mx-auto">
                <div className="float-left">
                    <NumericButton
                        id={"btn-day"}
                        value={day}
                        setValue={setDay}
                        minValue={1}
                        maxValue={31}
                        insideModal={insideModal}
                    />
                </div>
                <div
                    className="float-left"
                    style={{
                        fontSize: "2.4vw",
                        fontWeight: "bold",
                        marginLeft: "1vw",
                        marginRight: "1vw",
                    }}
                >
                    /
                </div>
                <div className="float-left">
                    <NumericButton
                        id={"btn-month"}
                        value={month}
                        setValue={setMonth}
                        minValue={1}
                        maxValue={12}
                        insideModal={insideModal}
                    />
                </div>
                <div
                    className="float-left"
                    style={{
                        fontSize: "2.4vw",
                        fontWeight: "bold",
                        marginLeft: "1vw",
                        marginRight: "1vw",
                    }}
                >
                    /
                </div>
                <div className="float-left">
                    <NumericButton
                        id={"btn-year"}
                        value={year}
                        setValue={setYear}
                        minValue={new Date().getFullYear()}
                        maxValue={new Date().getFullYear() + 1}
                        width={"5vw"}
                        insideModal={insideModal}
                    />
                </div>
            </div>
        );
    });
};

export default memo(DatePicker);
