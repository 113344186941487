import { useEffect, useRef } from "preact/hooks";
import type { FC } from "react";
import { makeKeyboardEvent } from "../../utils/events";
import focus from "../../utils/focus";
import scrollPolyfill from 'scroll-polyfill'


const isOutOfViewport = (elem: Element): boolean => {
    const bounding = elem.getBoundingClientRect();
    return bounding.right > (window.innerWidth || document.documentElement.clientWidth) || bounding.left < 0;
};


const HorizontalList: FC<{ noDataElement: any, children: any, style: any, id?: string, propagateKeys: string[] }> = ({ noDataElement, children, style = [], id = 'list',propagateKeys }) => {
    scrollPolyfill({ force: true })
    const listEl = useRef<HTMLDivElement>(null);
    const innerHandler = makeKeyboardEvent(['right', 'left', 'enter'], (key) => {
        if (key === 'right') {
            const isListExhausted = Array.isArray(children) && children.length === 0 || document.activeElement === listEl.current!.lastChild;
            if (!document.activeElement?.nextElementSibling?.nextElementSibling || isListExhausted) {
                listEl.current!.parentElement?.scrollTo({ left: listEl.current!.scrollWidth, behavior: 'smooth' });
            }
            else if (document.activeElement?.nextElementSibling?.nextElementSibling && isOutOfViewport(document.activeElement.nextElementSibling.nextElementSibling)) {
                listEl.current!.parentElement?.scrollBy({ left: document.activeElement?.clientWidth, behavior: 'smooth' });
            }
            if (document!.activeElement!.nextElementSibling)
                focus.value.replace(document!.activeElement!.nextElementSibling!.getAttribute('id') as string);
            return { stopPropagation: true, preventDefault: true };
        }
        else if (key === 'left') {
            const isListExhausted = Array.isArray(children) && children.length === 0 || document.activeElement === listEl.current!.firstChild;
            if (!document.activeElement?.previousElementSibling?.previousElementSibling || isListExhausted) {
                listEl.current!.parentElement?.scrollTo({ left: 0, behavior: 'smooth' });
            }
            else if (document.activeElement?.previousElementSibling?.previousElementSibling && isOutOfViewport(document.activeElement.previousElementSibling.previousElementSibling)) {
                listEl.current!.parentElement?.scrollBy({ left: -document.activeElement?.clientWidth, behavior: 'smooth' });
            }
            if (document!.activeElement!.previousElementSibling)
                focus.value.replace(document!.activeElement!.previousElementSibling!.getAttribute('id') as string);
            return { stopPropagation: true, preventDefault: true };
        }
        else if (key === 'enter') {
            //@ts-ignore
            document.activeElement!.click();            
            //@ts-ignore
            return { stopPropagation: propagateKeys?.['enter'] ? false : true};
        }
    });
    useEffect(() => {
        if (listEl.current) {
            listEl.current.addEventListener('keydown', innerHandler);
            listEl.current.scrollTo({ left: 0 });
        }
        return () => {
            listEl.current && listEl.current.removeEventListener('keydown', innerHandler);
        }

    }, [listEl]);
    return !Array.isArray(children) || children.length === 0
        ? noDataElement ?? null
        : (
            <div style={{ ...(style ?? {} as any), scrollBehavior: 'smooth' }} id={id} ref={listEl}>
                {children}
            </div>
        );
};

export default HorizontalList;