import { Signal, signal } from "@preact/signals";

export const debugSignal = <T>(value: T): Signal<T>=>{
    const ret = signal<T>(value);
    ret.subscribe((value)=>console.trace(value));
    return ret;
}

export const persistedSignal = <T extends keyof LocalStorage>(key: T, defaultValue: LocalStorage[T]): Signal<LocalStorage[T]>=>{
    const value = localStorage.getItem(key);
    const ret = signal<LocalStorage[T]>(value !== null ? JSON.parse(value) : defaultValue);
    ret.subscribe((value)=>localStorage.setItem(key, JSON.stringify(value)));
    localStorage.setItem(key, JSON.stringify(ret.peek()));
    return ret;
}

export const promisedSignal = <T, P extends Promise<T>, E>(promise: P, defaultValue: E): Promise<Signal<T | E>> => {
    return promise.then(x=>signal(x)).catch(_=>signal(defaultValue))
}