import Button from "../../components/common/button";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { KEYS } from "../../utils/keys";
import { moveFocus } from "../../utils/movement";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";

const SPECIAL_BUTTON_CATEGORY_ID = "btn-specialCategory";

const Categories = ({ gridData, activeCategory, setActiveCategory, fullGroup = false, specialButton = false }) => {
    const focusNextCategory = () => {
        const _name = document.activeElement.id.replace("btn-", "");
        const catIndex = gridData.findIndex((cat) => cat.id === _name);
        if (catIndex + 1 >= gridData.length) {
            return;
        }
        focus.value.replace(`btn-${gridData[catIndex + 1].id}`);
    };

    const focusPreviousCategory = () => {
        const _name = document.activeElement.id.replace("btn-", "");
        const catIndex = gridData.findIndex((cat) => cat.id === _name);
        if (catIndex === 0) {
            return;
        }
        focus.value.replace(`btn-${gridData[catIndex - 1].id}`);
    };

    const onCategoriesKeyDown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.right:
                    if (!specialButton) {
                        focusNextCategory();
                        preventDefault = true;
                    } else if (focus.value.current === SPECIAL_BUTTON_CATEGORY_ID) {
                        preventDefault = true;
                    }
                    break;
                case KEYS.left:
                    if (focus.value.current !== SPECIAL_BUTTON_CATEGORY_ID) {
                        focusPreviousCategory();
                        preventDefault = true;
                    }
                    break;
                case KEYS.down:
                    const nextMovieDown =
                        moveFocus(document.activeElement.id, "down") ||
                        `btn-${gridData.find((category) => category.id === activeCategory).items[0].id}_movie_${
                            gridData.find((category) => category.id === activeCategory).items[0].items[0].id
                        }`;
                    // focus in first movie of first group of catergory
                    focus.value.replace(nextMovieDown);
                    preventDefault = true;
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            className="filters"
            style={{
                height: figmapx2vh(200),
                fontSize: figmapx2vw(36),
                display: fullGroup ? "none" : "",
                width: "100vw",
                zIndex: 10,
                background: `linear-gradient(0deg, rgba(55, 61, 66, 0) 30.4%, rgba(55, 61, 66, 0.62) 61.02%, #373D42 100%)`,
            }}
            onKeyDown={(e) => {
                onCategoriesKeyDown(e);
            }}
        >
            {specialButton && (
                <div
                    style={{
                        position: "absolute",
                        right: figmapx2vh(80),
                        top: 0,
                        paddingTop: "7vh",
                        width: figmapx2vh(243),
                        height: figmapx2vh(70),
                    }}
                >
                    {specialButton()}
                </div>
            )}
            <div className="mx-auto table text-center">
                {gridData.map((gFilter) => {
                    if (!gFilter || gFilter.hide) {
                        return;
                    }

                    return (
                        <div key={gFilter.id} className="float-left">
                            <Button
                                data={{
                                    id: gFilter.id,
                                    customClass: `filter `,
                                    focusClass: "channelList-focus",
                                    removeButtonClass: true,
                                    customAttribute: "category",
                                    name: gFilter.name,
                                    onClick() {
                                        if (gFilter.id !== activeCategory) {
                                            setActiveCategory(gFilter.id);
                                        }
                                    },
                                }}
                            />
                            {activeCategory === gFilter.id && focus.value.current !== `btn-${gFilter.id}` ? (
                                <div className={"border mx-auto"} style={{ width: "31%" }}></div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Categories;
