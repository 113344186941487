import { useSelector } from "react-redux";
import Button from "../../../components/common/button";
import { KEYS } from "../../../utils/keys";
import { Texts } from "../../../utils/texts";
import { figmapx2vh, figmapx2vw } from "../../../utils/utils";
import focus from "../../../utils/focus";
import ReactHtmlParser from "react-html-parser";

const FullPlotDisplay = ({ plot }) => {
    const texts = useSelector((state) => state.ui.texts);

    return (
        <div
            className={"text-gray-100"}
            style={{
                fontSize: figmapx2vw(28),
                width: figmapx2vw(904),
            }}
        >
            <div
                class={"font-700"}
                style={{
                    fontSize: figmapx2vw(36),
                    marginBottom: figmapx2vh(40),
                }}
            >
                <span
                    className={"icon icon-tv-back-remote"}
                    style={{
                        marginRight: figmapx2vw(20),
                    }}
                ></span>
                {Texts.translateCap(texts, "read-more")}
            </div>
            <Button
                insideModal={true}
                data={{
                    id: "fullPlot",
                    body: ReactHtmlParser(plot),
                    customClass: "bg-transparent text-gray-100 scrollable w-full rounded pre-line",
                    bodyClass: "scrollbarVisible",
                    focusClass: "bg-transparent text-gray-100-important",
                    customStyle: {
                        height: "79vh",
                    },
                    keysBlockedOnScroll: [KEYS.up, KEYS.down],
                }}
            />
        </div>
    );
};

export default FullPlotDisplay;

export const RenderReadMoreButton = ({ readMorePlotButton, displayFullPlot }) => {
    const texts = useSelector((state) => state.ui.texts);
    return readMorePlotButton ? (
        <div
            id="read-more-plot"
            tabindex="0"
            className={`navigable inline-block w-auto ${
                focus.value.current === "read-more-plot" ? "readMoreFocus" : ""
            }`}
            style={{
                fontStyle: "italic",
                marginTop: figmapx2vh(10),
                marginBottom: figmapx2vh(32),
                borderBottom: "0.208117vw solid rgba(0,0,0,0)",
                height: figmapx2vh(38),
            }}
            onClick={() => {
                displayFullPlot();
            }}
        >
            {Texts.translateCap(texts, "read-more")}
        </div>
    ) : (
        <div style={{ marginBottom: figmapx2vh(32) }}>&nbsp;</div>
    );
};
