import { useDispatch, useSelector } from "react-redux";
import { isMovieFullyWatched } from "../../../utils/movies";
import { Texts } from "../../../utils/texts";
import { figmapx2vw, getStayGuestRoomId, inPreview, printPrice } from "../../../utils/utils";
import { displayErrorPopUp, setPopUp } from "../../../actions/uiActions";
import { SESSION } from "../../../utils/session";
import { rentMovie } from "../../../hooks/GraphqlCalls/movies/rentMovie";
import Button from "../../../components/common/button";

const RentButton = ({ item, itemUpdated, playMovie }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const { sessionData } = useSelector((state) => state.status);
    const stayGuestRoomId = getStayGuestRoomId(sessionData);

    const CURRENCY_CODE = useSelector((state) => state.status.locationData.projectCurrency);

    return (
        <Button
            insideModal={true}
            data={{
                id: "rent",
                name:
                    itemUpdated?.rented || item.free2guest
                        ? Texts.translateCap(
                              texts,
                              isMovieFullyWatched(itemUpdated.visualizations) ? "play-movie" : "resume-movie",
                          )
                        : Texts.translateCap(texts, "rent-for").replace(
                              "{{price}}",
                              printPrice(item.priceToShow, CURRENCY_CODE),
                          ),
                customClass: `py-2 px-8 w-auto text-2xl my-auto text-gray-100 rounded text-center`,
                customStyle: {
                    minWidth: figmapx2vw(320),
                    color: "#F5F6F8",
                    marginRight: figmapx2vw(48),
                    border: `${figmapx2vw(4)} solid #F5F6F8`,
                    borderColor: "#F5F6F8",
                },
                focusClass: "grid-btn-back-focus rounded",
                onClick() {
                    if (inPreview()) {
                        dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
                        return;
                    }
                    SESSION.cMovie = item;
                    if (itemUpdated?.rented) {
                        // play movie
                        console.log("voy al player");
                        playMovie();
                    } else if (!itemUpdated.rented && item.free2guest) {
                        // RENT movie on background and PLAY movie
                        rentMovie(
                            parseInt(stayGuestRoomId),
                            parseInt(item.id),
                            () => {
                                //play movie
                                playMovie();
                            },
                            () => {
                                //error rent
                                dispatch(displayErrorPopUp({ text: "error en el sistema" }));
                            },
                        );
                    } else {
                        dispatch(setPopUp("movieRent"));
                        // RENT movie
                    }
                },
            }}
        />
    );
};

export default RentButton;
