export const CURRENCY_POSSITION = {
    SUFFIX: "suffix",
    PREFIX: "prefix",
};

export const addCurrencySimbol = (value, symbol = "€", position = CURRENCY_POSSITION.SUFFIX) => {
    const currencySymbol = symbol !== "" ? symbol : "€";
    const valueFixed = parseFloat(value).toFixed(2);
    if (position === CURRENCY_POSSITION.SUFFIX || position === "") {
        return `${valueFixed} ${currencySymbol}`;
    }
    if (position === CURRENCY_POSSITION.PREFIX) {
        return `${currencySymbol} ${valueFixed}`;
    }
};

export const dateToddmmyyy = (date) => {
    const lang = localStorage.getItem("lang");
    return new Date(date).toLocaleString(lang === "es" ? "es-ES" : "en-Gb", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
    });
};

export const parseBillData = (billData) => {
    const { currencySymbol, currencyPosition } = billData;
    const listBillItems = [];
    billData.items.forEach((element, index) => {
        const divBill = `<div class="text-left border rounded border-gray-300 relative table w-full">
                                <div class="px-4 py-4 float-left" style="width: 10%;">x${element.quantity}</div>
                                <div class="px-4 py-4 float-left"  style="width: 90%;">
                                    <div class="text-left w-full bolder pb-4">${element.description}</div>
                                    <div class="w-full table">
                                        <div class="w-1/2 text-left font-400 relative float-left">${dateToddmmyyy(
                                            element.date * 1000,
                                        )}</div>
                                        <div class="w-1/2 text-right font-500 relative float-left ${
                                            parseFloat(element.price) < 0 ? "text-red-100" : ""
                                        }">${addCurrencySimbol(element.price, currencySymbol, currencyPosition)}
                                        </div>
                                    </div>
                                </div>
                        </div>`;
        listBillItems.push({ id: index, innerHTML: divBill });
    });

    return listBillItems;
};
