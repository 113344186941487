import { KEYS } from "../../../utils/keys";

export const keyMap = [
    { code: 409, value: KEYS.power },
    { code: 462, value: KEYS.home },
    { code: 404, value: KEYS.lang },
    { code: 405, value: KEYS.messages },
    { code: 461, value: KEYS.back },
    { code: 427, value: KEYS.channelUp },
    { code: 428, value: KEYS.channelDown },
];
