import { useEffect } from "preact/hooks";
import Button from "../common/button";
import focus from "../../utils/focus";
import { clearCart } from "../sales/signals/carrito";
import { Texts } from "../../utils/texts";
import { useDispatch, useSelector } from "react-redux";
import { setPopUp } from "../../actions/uiActions";
import storage from "../../utils/storage";

const ClearCart = () => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);

    useEffect(() => {
        focus.value.replace("btn-empty");
    }, []);

    const executeClearCart = (val) => {
        clearCart({ token: storage.get("orderToken") });
        dispatch(setPopUp(null));
    };

    return (
        <div className="mt-8">
            <Button
                insideModal={true}
                data={{
                    id: "empty",
                    name: Texts.capitalize(texts["empty"]),
                    border: true,
                    customClass: " py-2 px-4 text-2xl text-center rounded float-left",
                    customStyle: {
                        width: "100%",
                    },
                    onClick: () => {
                        executeClearCart();
                    },
                }}
            />
        </div>
    );
};

export default ClearCart;
