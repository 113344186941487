import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Button from "../common/button";
import Calculator from "../common/calculator";
import Modal from "../common/modal";
import focus from "../../utils/focus";
import { Texts } from "../../utils/texts";
import { setNumpadCode } from "../../actions/eventsActions";
import order from "../sales/api/order";
import { clearCart } from "../sales/signals/carrito";
import storage from "../../utils/storage";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import Loading from "../loading";
import { getVendureTranslation, inPreview, limitDecimals, printPrice } from "../../utils/utils";
import HorizontalList from "../common/horizontalList";
import { TIME } from "../../utils/constants";
import { useLocation } from "wouter";
import api from "../sales/api";
import { setLocationData } from "../../actions/statusActions";
import { Theme } from "../../utils/theme";

/**
 * @param a {Date}
 * @param b {Date}
 * @return {1 | -1}
 */
const absoluteDateComparator = (a, b) => {
    /**
     * @param a {[Date, Date]}
     * @param b {[Date, Date]}
     * @return {1 | -1}
     */
    if (a[0] < b[0]) {
        return -1;
    } else if (a[0] === b[0]) {
        return a[1] < b[1] ? -1 : 1;
    } else {
        return a[1] < b[1] ? -1 : 1;
    }
};
/**
 * @param currentTime {Date}
 * @return {function(): (1 | -1)}
 */
const relativeDateComparator = (currentTime) => {
    /**
     * @param a {[Date, Date]}
     * @param b {[Date, Date]}
     * @return {1 | -1}
     */
    return (a, b) => {
        if (a[0] < currentTime && b[0] < currentTime) {
            return -1;
        }
        return 1;
    };
};
const OrderConfirm = () => {
    const params = useLocation();
    const dispatch = useDispatch();
    const CURRENCY_CODE = sessionStorage.getItem("currencyCode");

    const DELIVERY_TIME = { asap: "asap", schedule: "schedule" };
    const [error, setError] = useState("");
    const [orderSuccess, setSuccess] = useState(false);
    const [orderProcessing, setOrderProcessing] = useState(false);
    const [step, setStep] = useState(null);
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState([null, null]);
    const [deliveryTimeOptions, setDeliveryTimeOptions] = useState(DELIVERY_TIME.asap);
    const [deliveryLocation, setDeliveryLocation] = useState(null);
    const [deliveryResponse, setDeliveryResponse] = useState(null);
    const [shopData, setShopData] = useState(null);
    const [oneOption, setOneOption] = useState(false);

    const hasRoomserviceOption = shopData?.shipping_config?.delivery.enabled ?? false;
    const hasPickupOption = (shopData?.shipping_config?.pickup?.enabled && shopData?.pickup_location) || false;

    const numpadCode = useSelector((state) => state.events.numpadCode);
    const texts = useSelector((state) => state.ui.texts);
    const locationData = useSelector((state) => state.status.locationData);
    const channelToken = storage.get("orderToken");

    const parentalCode = sessionStorage.getItem("parentalCode");

    const deliveryMethodData = deliveryResponse?.find((method) => method.code === deliveryMethod);

    const modalTextColor = Theme.light ? Theme.colors.default.gray["800"] : Theme.colors.default.gray["100"];

    useEffect(() => {
        getDeliveryMethods();
    }, []);

    useEffect(() => {
        if (hasRoomserviceOption && hasPickupOption) {
            if (shopData.pickup_location.length === 1) {
                setLocationData(shopData.pickup_location[0]);
            }
            setStep(1);
        } else if (!hasRoomserviceOption && hasPickupOption && shopData.pickup_location.length > 1) {
            setDeliveryMethod("pickup");
            setOneOption(true);
            setStep(1);
        } else if (hasRoomserviceOption || (hasPickupOption && shopData.pickup_location.length === 1)) {
            if (hasRoomserviceOption) {
                selectDeliveryMethodRoomService();
            } else {
                selectDeliveryMethodPickUp(shopData.pickup_location[0]);
            }
        }
    }, [shopData]);

    const makeOrder = () => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        const shippingMethodCode = deliveryMethod?.startsWith("delivery") ? "delivery" : "pickup";
        const _deliveryLocation = shippingMethodCode === "pickup" ? deliveryLocation?.id : locationData.roomInfo.name;

        order({
            channelToken: channelToken,
            shippingMethod: shippingMethodCode,
            schedule: deliveryTime,
            deliveryLocation: _deliveryLocation,
        }).then((data) => {
            if (!data || data.errors) {
                setOrderProcessing(false);
                setError(Texts.translate(texts, "order-error"));
            } else {
                clearCart({ token: channelToken });
                setSuccess(true);
                setError("");
                dispatch(setNumpadCode(null));
            }
        });
    };

    useEffect(() => {
        focus.value.current === `btn-${DELIVERY_TIME.schedule}` && setDeliveryTimeOptions(DELIVERY_TIME.schedule);
        focus.value.current === `btn-${DELIVERY_TIME.asap}` && setDeliveryTimeOptions(DELIVERY_TIME.asap);
    }, [focus.value.current]);

    const getDeliveryMethods = () =>
        Promise.all([
            api.shippingMethods(),
            api.pickupLocations(channelToken),
            api.shopByToken(params[0].split("/")[2]),
        ]).then(([shippingMethods, pickupLocations, shop]) => {
            setDeliveryResponse(shippingMethods?.data?.shippingMethods?.items);
            setShopData({
                ...shop?.customFields,
                pricesIncludeTax: shop?.pricesIncludeTax,
                shipping_config: shop?.customFields?.shipping_config ? shop?.customFields?.shipping_config : undefined,
                pickup_location: pickupLocations.data.pickupLocations.map((item) => {
                    return {
                        ...item,
                        name: getVendureTranslation(item.translations),
                        price: shop?.pricesIncludeTax ? item.price.withTax / 100 : item.price.withoutTax / 100,
                    };
                }),
            });
        });

    /**
     *
     * @return {[number, number][]}
     */
    const getSortedAvailableTimeSlots = () => {
        /**
         * @type {{startTime: string, endTime: string}[]|null}
         */
        const times = shopData?.delivery_schedule ? JSON.parse(shopData.delivery_schedule) : null;
        let currentTime = new Date();
        shopData?.delayed_delivery_threshold &&
            currentTime.setMinutes(currentTime.getMinutes() + shopData?.delayed_delivery_threshold);
        //Prepare slots each 30 minutes
        if (!times) {
            const currentMinute = currentTime.getMinutes();
            if (currentMinute < 30) {
                // We can order on the second half of the current hour
                currentTime.setMinutes(0);
            } else {
                // We can order at next hour o'clock
                // currentTime = new Date(currentTime.getTime() + TIME.ONE_HOUR_IN_MS); // Uncomment this line to exclude the ongoing delivery slot
                currentTime.setMinutes(30); //Subtract whatever minutes currentTime has
            }
            return Array.from({ length: 48 }).map((_, i) => [
                currentTime.getTime() + (i * TIME.ONE_HOUR_IN_MS) / 2,
                currentTime.getTime() + ((i + 1) * TIME.ONE_HOUR_IN_MS) / 2,
            ]);
        } else {
            let timeSlots = [];
            times.forEach((time) => {
                const startTime = time.startTime.split(":");
                const endTime = time.endTime.split(":");
                const startDate = new Date();
                const endDate = new Date();
                startDate.setHours(startTime[0]);
                startDate.setMinutes(startTime[1]);
                endDate.setHours(endTime[0]);
                endDate.setMinutes(endTime[1]);
                while (startDate.getTime() < endDate.getTime()) {
                    let slotEnd = startDate.getTime() + TIME.ONE_HOUR_IN_MS / 2;
                    if (slotEnd > endDate.getTime() || endDate.getTime() - slotEnd < TIME.ONE_HOUR_IN_MS / 4) {
                        slotEnd = endDate.getTime();
                    }
                    timeSlots.push([
                        startDate.getTime() + (startDate < currentTime ? 86400000 : 0),
                        slotEnd + (startDate < currentTime ? 86400000 : 0),
                    ]);
                    startDate.setTime(slotEnd);
                }
            });

            const orderedSlots = timeSlots.sort(absoluteDateComparator);
            return orderedSlots;
        }
    };

    const printDeliveryTimes = () => {
        const times = getSortedAvailableTimeSlots();
        return (
            <div className={"relative"}>
                <HorizontalList
                    insideModal={true}
                    data={{
                        id: "channel_HL",
                        noFocusOnLoad: true,
                        timeSlots: true,
                        list: times
                            .map(
                                ([start, end]) =>
                                    `${new Date(start).getHours().toString(10).padStart(2, "0")}:${new Date(start)
                                        .getMinutes()
                                        .toString(10)
                                        .padStart(2, "0")} - ${new Date(end)
                                        .getHours()
                                        .toString(10)
                                        .padStart(2, "0")}:${new Date(end).getMinutes().toString(10).padStart(2, "0")}`,
                            )
                            .map((x, i) => {
                                return { id: JSON.stringify(times[i]), name: x };
                            }),
                        paginated: true,
                        itemsPerPage: 5,
                        customClass: "p-4",
                        itemType: "deliveryTime",
                        itemCustomStyle: {
                            marginBottom: "0.5vh",
                            padding: "o.5vw",
                            backgroundColor: "transparent",
                        },
                        itemRounded: true,
                        onClick: (value) => {
                            setDeliveryTime(JSON.parse(value));
                            setStep(4);
                        },
                    }}
                />
            </div>
        );
    };
    const printDeliveryLocations = () => {
        return (
            <div className={"relative mt-8"}>
                <HorizontalList
                    insideModal={true}
                    data={{
                        id: "delivery_locations",
                        list: shopData.pickup_location.map((location) => {
                            return {
                                id: location.id,
                                name: location.name,
                                price: location.price,
                            };
                        }),
                        paginated: true,
                        itemsPerPage: 3,
                        customClass: "p-4",
                        itemType: "deliveryLocation",
                        itemCustomStyle: {
                            marginBottom: "0.5vh",
                            padding: "o.5vw",
                            backgroundColor: "transparent",
                        },
                        itemRounded: true,
                        onClick: (value) => {
                            selectDeliveryMethodPickUp(shopData.pickup_location.find((item) => item.id === value));
                        },
                    }}
                />
            </div>
        );
    };

    const printNextDeliveryTime = (showASAP) => {
        const availableDeliverySlots = getSortedAvailableTimeSlots();
        const nextDeliverySlot = availableDeliverySlots[0];
        const currentTime = new Date().getTime();
        const isIncludedInCurrentSlot =
            currentTime > availableDeliverySlots?.[0]?.[0] && currentTime < availableDeliverySlots?.[0]?.[1];
        return (
            <>
                <div>
                    {!isIncludedInCurrentSlot && nextDeliverySlot
                        ? texts["next-delivery-time"].replace(
                              "{{time}}",
                              `${new Date(nextDeliverySlot[0]).getHours().toString(10).padStart(2, "0")}:${new Date(
                                  nextDeliverySlot[0],
                              )
                                  .getMinutes()
                                  .toString(10)
                                  .padStart(2, "0")}`,
                          )
                        : showASAP
                        ? texts["asap-desc"]
                        : null}
                </div>
            </>
        );
    };

    const getDeliveryPrice = (method) => {
        const roomServicePrice =
            (shopData.shipping_config[method || deliveryMethod]?.price.base *
                (!shopData.pricesIncludeTax
                    ? 1
                    : 1 + shopData.shipping_config[method || deliveryMethod].price.taxRate / 100)) /
            100;
        if (method === "delivery") {
            return roomServicePrice;
        }
        if (deliveryMethod === "pickup" && deliveryLocation?.price) {
            return deliveryLocation?.price;
        }
        return roomServicePrice;
    };

    const composeOrderTotalSubtitle = () => {
        let deliveryCost,
            totalCost = 0;
        let itemsTotal = parseFloat(sessionStorage.getItem("orderTotal"));
        totalCost = limitDecimals(itemsTotal + parseFloat(getDeliveryPrice()));

        return (
            <>
                {Texts.translate(texts, "order-total-sum").replace("{{total}}", printPrice(totalCost, CURRENCY_CODE))}{" "}
                <span style={{ textTransform: "lowercase" }}>{printTaxLabel()}</span>
                <div className={"bold pt-4"}>
                    {Texts.translate(texts, parentalCode ? "add-parental-code" : "Add your room number")}
                </div>
            </>
        );
    };

    const selectDeliveryMethodRoomService = () => {
        setDeliveryMethod("delivery");
        setDeliveryLocation({ name: texts["in-your-room"] });
        setStep(2);
    };

    const selectDeliveryMethodPickUp = (locationSelected) => {
        // locationSelected => getVendureTranslation(shopData.pickup_location[0].translations)
        setDeliveryMethod("pickup");
        // setDeliveryLocation(`${texts["at"]} ${locationSelected.name}`);
        setDeliveryLocation(locationSelected);
        setStep(2);
    };

    const printTaxLabel = () => {
        return <>({texts[`Tax ${shopData?.pricesIncludeTax ? "included" : "excluded"}`]})</>;
    };

    const setASAPDeliveryTime = () => {
        const availableDeliverySlots = getSortedAvailableTimeSlots();
        const nextDeliverySlot = availableDeliverySlots[0];
        setDeliveryTime([nextDeliverySlot[0], null]);
    };

    return step === 1 ? (
        <Modal
            title={Texts.translate(texts, "Choose delivery method")}
            firstFocus={hasRoomserviceOption ? "btn-room-service" : "btn-pickup"}
            backBtn={true}
            width="69.583vw"
            height="62.222vh"
            backAction={() => {
                dispatch(setPopUp(null));
            }}
            body={
                !deliveryResponse ? (
                    <Loading inModal={true} />
                ) : (
                    <>
                        <div className={"table w-full"}>
                            {hasRoomserviceOption && (
                                <div className={"table-cell w-1/2"}>
                                    <div className="pt-8 px-8 mx-8">
                                        <Button
                                            insideModal={true}
                                            data={{
                                                id: "room-service",
                                                name: texts["Room service"],
                                                active: deliveryMethod === "delivery",
                                                customClass: "text-center py-2 w-full text-2xl",
                                                border: true,
                                                onClick: () => {
                                                    selectDeliveryMethodRoomService();
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {!oneOption && (
                                <div className={"table-cell w-1/2"}>
                                    <div className="pt-8 px-8 mx-8">
                                        <Button
                                            insideModal={true}
                                            data={{
                                                id: "pickup",
                                                name: texts["pick up"],
                                                customClass: "text-center py-2 w-full text-2xl",
                                                border: true,
                                                active: deliveryMethod === "pickup",
                                                onClick: () => {
                                                    if (shopData.pickup_location.length === 1) {
                                                        selectDeliveryMethodPickUp(shopData.pickup_location[0]);
                                                    } else if (deliveryMethod === "pickup") {
                                                        const _elementToFocus = shopData.pickup_location.find(
                                                            (element) =>
                                                                document.getElementById(
                                                                    `btn-deliveryLocation-${element.id}`,
                                                                ),
                                                        );
                                                        if (_elementToFocus) {
                                                            focus.value.replace(
                                                                `btn-deliveryLocation-${_elementToFocus.id}`,
                                                            );
                                                        }
                                                    } else {
                                                        setDeliveryMethod("pickup");
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {focus.value.current === "btn-room-service" ||
                        (focus.value.current === "btn-pickup" && shopData.pickup_location.length === 1) ? (
                            <div style={{ color: modalTextColor }}>
                                <div className={"mt-8 pt-8 text-center text-xl"}>
                                    {texts["room-service-desc"].replace(
                                        "{{placeto}}",
                                        focus.value.current === "btn-room-service"
                                            ? texts["in-your-room"]
                                            : `${texts["at"]} `,
                                    )}
                                    {focus.value.current === "btn-pickup" && (
                                        <strong>{shopData.pickup_location[0].name}</strong>
                                    )}
                                </div>
                                {focus.value.current === "btn-room-service" &&
                                    shopData.shipping_config.delivery.price.base !== 0 && (
                                        <div className="text-center text-xl">
                                            {texts["delivery-cost"].replace(
                                                "{{price}}",
                                                printPrice(getDeliveryPrice("delivery"), CURRENCY_CODE),
                                            )}{" "}
                                            {printTaxLabel()}
                                        </div>
                                    )}
                                {focus.value.current === "btn-pickup" && shopData.pickup_location[0].price !== 0 && (
                                    <div className="text-center text-xl">
                                        {texts["delivery-cost"].replace(
                                            "{{price}}",
                                            printPrice(shopData.pickup_location[0].price, CURRENCY_CODE),
                                        )}{" "}
                                        {printTaxLabel()}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className={"mt-8 pt-8 text-center text-xl"}>{texts["different-locations"]}</div>
                                <div className="text-center text-xl">
                                    {texts["different-locations-costs"]}{" "}
                                    {deliveryMethod === "pickup" && printTaxLabel()}
                                </div>
                            </>
                        )}
                        <div style={{ height: "20vh" }}>
                            {deliveryMethod === "pickup" && shopData.pickup_location.length > 1
                                ? printDeliveryLocations()
                                : null}
                        </div>
                    </>
                )
            }
        />
    ) : step === 2 ? (
        <Modal
            title={Texts.translate(texts, "Delivery method")}
            width="69.583vw"
            height="62.222vh"
            firstFocus={"btn-continue"}
            backBtn={true}
            cancelBtn={hasRoomserviceOption && hasPickupOption}
            preventCloseOnBack={true}
            backAction={() => {
                if ((hasRoomserviceOption && hasPickupOption) || oneOption) {
                    setStep(1);
                } else {
                    dispatch(setPopUp(null));
                }
            }}
            body={
                <>
                    <div style={{ height: "25vh" }} className={"text-2xl"}>
                        <div className="pt-4 text-center">
                            {ReactHtmlParser(
                                Texts.translate(texts, "room-service-desc").replace(
                                    "{{placeto}}",
                                    `${deliveryMethod === "pickup" ? texts["at"] : ""} <strong>${
                                        deliveryLocation.name
                                    }</strong>`,
                                ),
                            )}
                        </div>

                        {(deliveryMethod === "delivery" && shopData.shipping_config.delivery?.price?.base) ||
                        (deliveryMethod === "pickup" && deliveryLocation?.price) ? (
                            <div className="py-2 mt-8 text-center">
                                {texts["attend-service-cost"].replace(
                                    "{{price}}",
                                    printPrice(getDeliveryPrice(), CURRENCY_CODE),
                                )}

                                <span style={{ textTransform: "lowercase" }}> {printTaxLabel()}.</span>
                            </div>
                        ) : null}
                    </div>
                    <div style={{ marginLeft: "10vw", marginRight: "10vw", marginTop: "10vh" }}>
                        <Button
                            insideModal={true}
                            data={{
                                id: "continue",
                                name: Texts.capitalize(texts["continue"]),
                                customClass: "text-center py-2 w-full text-2xl",
                                border: true,
                                onClick: () => {
                                    setStep(3);
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 3 && shopData ? (
        shopData?.allow_scheduled_delivery ? (
            <Modal
                title={Texts.translate(texts, "Choose delivery time")}
                width="69.583vw"
                height="62.222vh"
                backBtn={true}
                cancelBtn={true}
                preventCloseOnBack={true}
                backAction={() => setStep(2)}
                firstFocus={"btn-asap"}
                body={
                    <>
                        <div className="py-8 mx-auto table " style={{ width: "80%" }}>
                            <Button
                                insideModal={true}
                                data={{
                                    id: "asap",
                                    active: deliveryTimeOptions === DELIVERY_TIME.asap,
                                    name: texts["As soon as possible"],
                                    customClass: "text-center py-2 float-left text-2xl",
                                    customStyle: { width: "48%" },
                                    border: true,
                                    onClick: () => {
                                        setASAPDeliveryTime();
                                        setStep(4);
                                    },
                                }}
                            />
                            <Button
                                insideModal={true}
                                data={{
                                    id: "schedule",
                                    active: deliveryTimeOptions === DELIVERY_TIME.schedule,
                                    name: texts["Schedule your delivery time"],
                                    customClass: "text-center py-2  float-left text-2xl",
                                    customStyle: { width: "48%", marginLeft: "4%" },
                                    border: true,
                                }}
                            />
                        </div>
                        <div className={"table w-full my-8 text-center text-2xl"} style={{ height: "5vh" }}>
                            {deliveryTimeOptions === DELIVERY_TIME.asap
                                ? printNextDeliveryTime(true)
                                : deliveryTimeOptions === DELIVERY_TIME.schedule
                                ? texts["Select a delivery hour"]
                                : ""}
                        </div>
                        <div style={{ height: "20vh" }}>
                            {deliveryTimeOptions === DELIVERY_TIME.schedule ? printDeliveryTimes() : null}
                        </div>
                    </>
                }
            />
        ) : (
            <Modal
                title={Texts.translate(texts, "Delivery time")}
                width="69.583vw"
                height="62.222vh"
                backBtn={true}
                cancelBtn={true}
                preventCloseOnBack={true}
                backAction={() => setStep(2)}
                firstFocus={"btn-continue"}
                body={
                    <>
                        <div style={{ height: "25vh" }} className={"text-2xl text-center"}>
                            <div className={"pt-4"}>{texts["asap-desc"]}</div>
                            <div className={"mt-8"}>{printNextDeliveryTime()}</div>
                        </div>
                        <div style={{ marginLeft: "10vw", marginRight: "10vw", marginTop: "10vh" }}>
                            <Button
                                insideModal={true}
                                data={{
                                    id: "continue",
                                    name: Texts.capitalize(texts["continue"]),
                                    customClass: "text-center py-2  w-full text-2xl",
                                    border: true,
                                    onClick: () => {
                                        setStep(4);
                                    },
                                }}
                            />
                        </div>
                    </>
                }
            />
        )
    ) : step === 4 && orderSuccess ? (
        <Modal
            title={Texts.translate(texts, "Order confirmation")}
            width="69.583vw"
            height="62.222vh"
            firstFocus={"btn-close"}
            body={
                <>
                    <div style={{ height: "20vh", marginTop: "10vh" }} className={"text-2xl text-center"}>
                        {ReactHtmlParser(Texts.translate(texts, "order-success"))}
                    </div>
                    <div style={{ marginLeft: "10vw", marginRight: "10vw", marginTop: "10vh" }}>
                        <Button
                            insideModal={true}
                            data={{
                                id: "close",
                                name: Texts.capitalize(texts["close"]),
                                customClass: "text-center py-2 w-full text-2xl",
                                border: true,
                                onClick: () => {
                                    dispatch(setPopUp(null));
                                    history.go(-parseInt(sessionStorage.getItem("stepsBack") || 4));
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 4 ? (
        <Modal
            title={Texts.translate(texts, "Finalise order")}
            subtitle={composeOrderTotalSubtitle()}
            titleClass={"pt-6"}
            backBtn={true}
            cancelBtn={true}
            preventCloseOnBack={true}
            backAction={() => setStep(3)}
            body={
                <>
                    {orderProcessing && (
                        <div
                            className={"absolute text-2xl text-center bg-white"}
                            style={{ height: "79vh", width: "27vw", opacity: "1", top: "12vh" }}
                        >
                            <div className={"table w-full h-full"}>
                                <div className={"table-cell vertical-middle w-full h-full text-center"}>
                                    {ReactHtmlParser(Texts.translate(texts, "order-processing"))}
                                </div>
                            </div>
                        </div>
                    )}
                    <Calculator
                        code={parentalCode || locationData.roomInfo.number}
                        encrypt={parentalCode}
                        confirmText={texts["Finalize"]}
                        confirmIcon="room"
                        error={error}
                        confirmAction={(code) => {
                            if (code === (parentalCode || locationData.roomInfo.number)) {
                                setOrderProcessing(true);
                                makeOrder();
                            } else {
                                setError(
                                    Texts.translate(texts, parentalCode ? "wrong parental code" : "Wrong room number") +
                                        "!",
                                );
                            }
                        }}
                        setError={setError}
                    />
                </>
            }
        />
    ) : null;
};

export default OrderConfirm;
