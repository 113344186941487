import { vendureQuery } from "../../../hooks/query";

type QueryResult = {
    data: {
        orders: {
            items: {
                id: string;
                state: string;
                total: number;
                currencyCode: string;
                modifications: { note: string }[];
                lines: { quantity: number; productVariant: { name: string }[]; customFields: { extras: string } }[];
            }[];
            shippingLines: { price: number; shippingMethod: { name: string } }[];
            customFields: { schedule: string; deliveryLocation: string; pickUp: { location: string } }[];
        }[];
    };
};

export default (channel: string, ordersInfo: Array<string>): Promise<QueryResult> =>
    new Promise((resolve, reject) =>
        vendureQuery({
            apiType: "shop",
            headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channel },
            cache: true,
            body: `{
    orderInfo(orderIds:[${ordersInfo.map((orderId) => `"${orderId}"`)}]){
          id
          customFields {
            pickUp { location }
            items {
              name
              quantity
              extras { 
                ... on OrderInfoNormalAndNumericExtras {name quantity }
                ... on OrderInfoComboboxAndMulticomboboxExtras  {name options {name quantity}} 
              }
            }
          }
        }
      }`,
            onResponse: (data: QueryResult) => resolve(data),
            onError: (e) => reject(e),
        }),
    );
