import { memo, useEffect, useState } from "preact/compat";
import { useDispatch, useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import Button from "../../components/common/button";
import ReactHtmlParser from "react-html-parser";
import Query from "../../hooks/query";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import { setAlarm } from "../../actions/statusActions";
import { inPreview, padZero } from "../../utils/utils";
import focus from "../../utils/focus";

const AlarmDelete = () => {
    const dispatch = useDispatch();

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const alarm = useSelector((state) => state.status.sessionData.alarm);
    const locationData = useSelector((state) => state.status.locationData);

    const [hour, setHour] = useState("");
    const [day, setDay] = useState("");

    //listeners
    useEffect(() => {
        if (alarm) {
            const alarmDay = new Date(alarm.startTime);

            setHour(`${padZero(alarmDay.getHours())}:${padZero(alarmDay.getMinutes())}`);

            setDay(`${padZero(alarmDay.getDate())}/${padZero(alarmDay.getMonth() + 1)}/${alarmDay.getFullYear()}`);
        }
        focus.value.replace("btn-delete-alarm");
    }, []);

    const deleteAlarm = () => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        //TODO delete alarm
        Query({
            query: `
                mutation{ 
                    deleteAlarm (
                        id: ${alarm.id}
                        roomTvId: ${locationData.roomInfo.roomTvId}
                        )
                    {
                        ok     
                    }
                }
          `,
            onResponse: function (res) {
                dispatch(setAlarm(null));
                dispatch(setPopUp(null));
            },
        });
    };

    return (
        <div className="text-center">
            <div className="text-base mb-8 text-left">
                {ReactHtmlParser(
                    Texts.capitalize(texts["delete-alarm-confirm"].replace("{{day}}", day).replace("{{hour}}", hour)),
                )}
            </div>
            <div className="text-base mb-8 pb-8 text-left">
                {Texts.capitalize(texts["deleted alarms cannot be recovered"])}.
            </div>

            <Button
                insideModal={true}
                data={{
                    id: "delete-alarm",
                    name: Texts.capitalize(texts["delete"]),
                    border: true,
                    customClass: "py-2  w-full text-lg text-center rounded",
                    onClick: function () {
                        deleteAlarm();
                    },
                }}
            />
        </div>
    );
};

export default memo(AlarmDelete);
