import { vendureQuery } from "../../../hooks/query";
import storage from "../../../utils/storage";

type QueryResult = {
    data: {
        orders: {
            items: {
                id: string;
                state: string;
                total: number;
                totalWithTax: number;
                subTotal: number;
                subTotalWithTax: number;
                shipping: number;
                shippingWithTax: number;
                currencyCode: string;
                updatedAt: string;
                orderPlacedAt: string;
                channels: {
                    token: string;
                    customFields: {
                        pickup_location: { languageCode: string; name: string }[];
                    };
                }[];
                modifications: { note: string }[];
                shippingLines: { shippingMethod: { code: string } }[];
                lines: { quantity: number; productVariant: { name: string }[]; customFields: { extras: string } }[];
            }[];
            customFields: { schedule: string; deliveryLocation: string }[];
        }[];
    };
};

export default (channel: string, ordersIds: Array<string>): Promise<QueryResult> =>
    new Promise((resolve, reject) =>
        vendureQuery({
            apiType: "admin",
            headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channel },
            cache: true,
            body: `{
        orders(options:{filter:{id:{in:[${ordersIds.map((orderId) => `"${orderId}"`)}]},roomNumber:{eq:${storage.get(
            "roomNumber",
            0,
        )}}, guestId:{eq:${storage.get("lastStayGuestId", 0)}}}}){
          items{
            id
            state
            subTotal
            subTotalWithTax
            shipping
            shippingWithTax
            total
            totalWithTax
            currencyCode
            updatedAt
            orderPlacedAt
            modifications{
              note
            }
            channels{
              token
              customFields{
                pickup_location
              }
            }
            lines{
              quantity	
              productVariant{
                name
              }
              customFields{
                extras
              }
            }
            shippingLines{
              shippingMethod{
                code
              }
            }
            customFields{
              scheduleStart
              scheduleEnd
              deliveryLocation
            }
          }
        }
      }`,
            onResponse: (data: QueryResult) => resolve(data),
            onError: (e) => reject(e),
        }),
    );
