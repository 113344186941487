import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { setActiveMenuBtnID, setActiveMenuToRestart } from "../actions/eventsActions";
import { displayMenu, setPopUp } from "../actions/uiActions";
import { Media } from "../hooks/apis/media";
import { KEYS } from "../utils/keys";
import { Texts } from "../utils/texts";
import Bill from "./popups/bill";
import Modal from "./common/modal";
import Messages from "../layouts/messsages/messages";
import SelectAppLanguage from "./selectAppLanguage";
import UrgentMessages from "../layouts/messsages/urgentMessages";
import Checkout from "./popups/checkout";
import { ANIMATION_TIMEOUT, MENU_TYPES } from "../utils/constants";
import Reload from "./popups/reload";
import SessionInfo from "./popups/sessionInfo";
import ZafiroMenu from "./popups/zafiroMenu";
import Alarm from "../layouts/alarm/alarm";
import AlarmDelete from "../layouts/alarm/alarmDelete";
import AlarmActive from "../layouts/alarm/alarmActive";
import AlarmOFF from "../layouts/alarm/alarmOFF";
import { eventHandler } from "../utils/eventHandler";
import ClearCart from "./popups/clearCart";
import OrderConfirm from "./popups/orderConfirm";
import CartItemModal from "./popups/cartItemModal";
import ReactHtmlParser from "react-html-parser";
import Allergens from "./popups/allergens";
import ParentalCode from "./popups/parentalCode";
import OrderCancel from "./popups/cancelOrder";
import TvInputs from "./popups/tvInputs";
import InputNotAvailable from "./popups/inputNotAvailable";
import UsbSelectContent from "../hooks/apis/procentric/usbSelectContent";
import Button from "./common/button";
import { STB } from "../hooks/apis/stb";
import { ResetCredentials } from "./popups/deleteCredentials";
import MovieRent from "./popups/movieRent";
import { AdultContentConfirm } from "./popups/adultContentConfirm";

const PopUps = ({ justZafiroMenu = false }) => {
    const dispatch = useDispatch();
    const popupWithKeyBackBlocked = ["alarmActive", "reload"];
    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const activeMenuToRestart = useSelector((state) => state.events.activeMenuToRestart);
    const alarm = useSelector((state) => state.status.sessionData?.alarm);
    const popup = useSelector((state) => state.ui.popup);
    const menu = useSelector((state) => state.ui.menu);
    const showMenu = useSelector((state) => state.ui.showMenu);
    const [onPopup, setOnPopUp] = useState();

    if (justZafiroMenu && !["zafiroMenu", "sessionInfo"].includes(popup)) {
        return null;
    }

    useEffect(() => {
        if (popup) {
            if (menu && (menu.type === MENU_TYPES.HIDDEN || menu?.temporaryType === MENU_TYPES.HIDDEN)) {
                if (showMenu) {
                    dispatch(displayMenu(false));
                }
            }
            //if video present, avoid video to hide popup
            Media.showVideoOnBack();
            if (!onPopup) {
                setOnPopUp(true);
            }
        } else {
            //restore last active menu
            dispatch(setActiveMenuBtnID(activeMenuToRestart));
            dispatch(setActiveMenuToRestart(null));
            Media.showVideoOnFront();
            setOnPopUp(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popup]);

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                    if (!popupWithKeyBackBlocked.indexOf(popup) > -1) {
                        dispatch(setPopUp(null));
                    }
                    preventDefault = true;
                    break;
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <CSSTransition
                in={popup === "messages"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    width="84vw"
                    backBtn={true}
                    backBtnClass={"mb-4"}
                    title={Texts.capitalize(texts["messages"])}
                    titleClass="text-left"
                    body={<Messages />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "urgentMessages"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    title={Texts.capitalize(texts["new message"])}
                    backBtn={true}
                    style={{ container: "h-10rem" }}
                    body={<UrgentMessages />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "language"}
                backBtn={true}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <SelectAppLanguage />
            </CSSTransition>
            <CSSTransition
                in={popup === "alarm"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    style={{ container: "h-10rem" }}
                    width="auto"
                    title={Texts.capitalize(texts[alarm ? "edit alarm" : "set alarm"])}
                    backBtn={true}
                    body={<Alarm />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "alarmDelete"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    width="32vw"
                    backBtn={true}
                    backAction={function () {
                        dispatch(setPopUp("alarm"));
                    }}
                    title={Texts.capitalize(texts["delete alarm"])}
                    body={<AlarmDelete />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "alarmActive"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal width="40vw" backBtn={true} title={Texts.capitalize(texts["alarm"])} body={<AlarmActive />} />
            </CSSTransition>
            <CSSTransition
                in={popup === "alarmOFF"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    width="40vw"
                    backBtn={true}
                    title={Texts.capitalize(texts["alarm turned off"])}
                    body={<AlarmOFF />}
                />
            </CSSTransition>
            <CSSTransition in={popup === "bill"} timeout={ANIMATION_TIMEOUT} classNames="fadeInAnimation" unmountOnExit>
                <Modal
                    style={{ container: "h-10rem" }}
                    width="auto"
                    backBtn={true}
                    title={Texts.capitalize(texts["my-bill"])}
                    body={<Bill />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "checkout"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    style={{ container: "h-10rem" }}
                    width="50vw"
                    backBtn={true}
                    title={Texts.capitalize(texts["express-check-out"])}
                    body={<Checkout />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "reload"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal style={{ container: "h-10rem" }} width="70vw" body={<Reload />} />
            </CSSTransition>
            <CSSTransition
                in={popup === "zafiroMenu"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal backBtn={true} style={{ container: "h-10rem" }} width="30vw" body={<ZafiroMenu />} />
            </CSSTransition>
            <CSSTransition
                in={popup === "sessionInfo"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    backBtn={true}
                    backAction={function () {
                        dispatch(setPopUp("zafiroMenu"));
                    }}
                    style={{ container: "h-10rem" }}
                    width="50vw"
                    body={<SessionInfo />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "clearCart"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Modal
                    title={Texts.translate(texts, "Empty cart")}
                    subtitle={ReactHtmlParser(Texts.capitalize(Texts.translate(texts, "empty-cart-confirm")))}
                    backBtn={true}
                    style={{ container: "h-10rem" }}
                    width="30vw"
                    body={<ClearCart />}
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "CartItemModal"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <CartItemModal />
            </CSSTransition>
            <CSSTransition
                in={popup === "confirmOrder"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <OrderConfirm />
            </CSSTransition>
            <CSSTransition
                in={popup === "allergens"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <Allergens />
            </CSSTransition>
            <CSSTransition
                in={popup === "parentalcode"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <ParentalCode />
            </CSSTransition>
            <CSSTransition
                in={popup === "cancelorder"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <OrderCancel />
            </CSSTransition>
            <CSSTransition in={popup === "inputs"} timeout={0} classNames="bottomAnimation" unmountOnExit>
                <TvInputs />
            </CSSTransition>
            <CSSTransition in={popup === "usbSelectContent"} timeout={0} classNames="bottomAnimation" unmountOnExit>
                <UsbSelectContent />
            </CSSTransition>
            <CSSTransition
                in={popup === "blueToothNotAvailable"}
                timeout={0}
                classNames="bottomAnimation"
                unmountOnExit
            >
                <InputNotAvailable title={Texts.capitalize(Texts.translate(texts, "no-bluetooth"))} />
            </CSSTransition>
            <CSSTransition in={popup === "samsungBluetooth"} timeout={0} classNames="bottomAnimation" unmountOnExit>
                <Modal
                    title={"Bluetooth"}
                    // subtitle={ReactHtmlParser(Texts.capitalize(Texts.translate(texts, "empty-cart-confirm")))}
                    backBtn={false}
                    style={{ container: "h-auto" }}
                    // height="50vh"
                    width="40vw"
                    firstFocus={"btn-close"}
                    body={
                        <>
                            <div class={"text-xl text-center my-7 py-8"}>
                                {Texts.capitalize(Texts.translate(texts, "pair-with")).replace("{{name}}", STB.tvName)}
                            </div>
                            <div class={"py-8"}>
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "close",
                                        name: Texts.capitalize(texts["close"]),
                                        border: true,
                                        customClass: " py-2 px-4 text-lg text-center rounded float-left",
                                        customStyle: {
                                            width: "100%",
                                        },
                                        onClick() {
                                            dispatch(setPopUp(null));
                                        },
                                    }}
                                />
                            </div>
                        </>
                    }
                />
            </CSSTransition>
            <CSSTransition in={popup === "samsungUsb"} timeout={0} classNames="bottomAnimation" unmountOnExit>
                <Modal
                    title={"USB"}
                    // subtitle={ReactHtmlParser(Texts.capitalize(Texts.translate(texts, "empty-cart-confirm")))}
                    backBtn={false}
                    style={{ container: "h-auto" }}
                    // height="50vh"
                    width="40vw"
                    firstFocus={"btn-close"}
                    body={
                        <>
                            <div class={"text-xl text-center my-7 py-8"}>
                                {Texts.capitalize(Texts.translate(texts, "connect-usb"))}
                            </div>
                            <div class={"py-8"}>
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "close",
                                        name: Texts.capitalize(texts["close"]),
                                        border: true,
                                        customClass: " py-2 px-4 text-lg text-center rounded float-left",
                                        customStyle: {
                                            width: "100%",
                                        },
                                        onClick() {
                                            dispatch(setPopUp(null));
                                        },
                                    }}
                                />
                            </div>
                        </>
                    }
                />
            </CSSTransition>
            <CSSTransition
                in={popup === "resetCredentials"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <ResetCredentials />
            </CSSTransition>
            <CSSTransition
                in={popup === "resetCredentials-success"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <ResetCredentials success={true} />
            </CSSTransition>
            <CSSTransition
                in={popup === "movieRent"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <MovieRent />
            </CSSTransition>
            <CSSTransition
                in={popup === "adultContentConfirm"}
                timeout={ANIMATION_TIMEOUT}
                classNames="fadeInAnimation"
                unmountOnExit
            >
                <AdultContentConfirm />
            </CSSTransition>
        </div>
    );
};

export default PopUps;
