import { persistedSignal } from "../../../utils/signals";
import type { Signal } from "@preact/signals";

export const carrito: Signal<Record<string, CartItem[]>> = persistedSignal('cart', {});
export const addToCart = ({variant, token, config, quantity, timestamp, productId}: {variant: number, token: string, config: Record<number, Sales.Extras.Config>,quantity: number, timestamp: string, productId: number})=>{
    const oldCart = carrito.peek();
    oldCart[token] = [...(oldCart[token] || []), {variant, config, quantity, timestamp, productId}];
    carrito.value = oldCart;
}
export const removeFromCart = ({token, entry}: {entry: number, token: string})=>{
    const oldCart = carrito.peek();
    carrito.value = {[token]: (oldCart[token] as NonNullable<typeof oldCart[keyof typeof oldCart]>).filter((_, i)=>entry !== i), ...oldCart};
}

export const clearCart = ({token}: {token: string})=>{
    const {[token]: _, ...rest} = carrito.peek();
    carrito.value = rest;
}