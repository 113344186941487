import memize from "memize";
import { vendureQuery } from "../../../hooks/query";

type QueryResult = {
    data: {
        pickupLocations: {
            id: string;
            available: boolean;
            order: number;
            price: {
                withTax: number;
                withoutTax: number;
            };
            translations: {
                name: string;
                languageCode: string;
            }[];
        }[];
    };
};

export default memize(
    (channel: string): Promise<QueryResult> =>
        new Promise((resolve, reject) =>
            vendureQuery({
                apiType: "shop",
                headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channel },
                cache: true,
                body: `
                {
                    pickupLocations{
                      id
                      available
                      order
                      price{
                        withTax
                        withoutTax
                      }
                      translations{
                        name
                        languageCode
                      }
                    }
                  }`,
                onResponse: (data: QueryResult) => {
                    resolve({
                        data: {
                            pickupLocations: data.data.pickupLocations
                                .filter((item) => item.available)
                                .sort(function (a, b) {
                                    return a.order - b.order;
                                }),
                        },
                    });
                },
                onError: (e) => reject(e),
            }),
        ),
);
