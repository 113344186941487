import memize from "memize";
import { vendureQuery } from "../../../hooks/query";

type QueryResult = {
    "data": {
        "extra": {
            "available": boolean,
            "appliesTo": string,
            "taxCategory": string,
            "type": "combobox" | "multicombobox" | "normal" | "numeric",
            "order": number,
            "id": string,
            "translations": { "name": string, "languageCode": string }[],
            "options":{
              "max" : number,
              "min" : number
            }
        }
    }
}

export default memize((extraId: number | string, channelToken: string): Promise<QueryResult["data"]["extra"]>=>new Promise((resolve, reject)=>vendureQuery({apiType: "shop", body: `
    {
  extra(extraId: "${extraId}"){
    available
    appliesTo
    taxCategory
    type
    order
    id
    translations {
      name
      languageCode
    }
    options{
      max
      priceWithoutTax
      priceWithTax
      choices{
        id
        available
        translations{
          name
          languageCode
        }
        priceWithoutTax
        priceWithTax
        order
      }
      min
    }
  }
}`,
    headers: {
        "vendure-token": channelToken
    },
    onResponse: (x: QueryResult)=>{
        resolve(x.data.extra);
    },
    onError: (e)=>reject(e)
})));