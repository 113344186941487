import { CSSProperties } from "react";
import { object } from "./polyfills/object";

const DEFAULT_FOCUS_COLOR = "#2286EF";
const DEFAULT_BORDER_COLOR = "#D3DAE1";

const WHITE = "#FFFFFF";
const BLACK = "#2E3843";

export const makeStylesheet = <T extends string>(stylesheet: Record<T, CSSProperties>)=>(...classes: (keyof typeof stylesheet | '')[]): CSSProperties=>classes.reduce((acc, cur)=> ({...acc, ...(cur !== '' ? stylesheet[cur] : {} as CSSProperties)}) as CSSProperties, {} as CSSProperties);


type ButtonProperties = {
    color: string,
    backgroundColor: string,
    borderColor: string,
    borderWidth: string,
}

type Templating = {
    css: {
        roomshops: {
            items: {
                default: {
                    backgroundColor?: string
                },
                focus: {
                    backgroundColor?: string
                }
            },
            section1: {
                default: {
                    backgroundColor?: string
                },
            },
            section3: {
                default: {
                    backgroundColor: string
                },
            }
        },
        products: {
            buttons: {
                default: ButtonProperties,
                active: ButtonProperties,
                focus: ButtonProperties
            },
            items: {
                focus: {
                    borderColor: string,
                }
            },
            section1: {
                default: {
                    backgroundColor: string
                }
            },
            section2: {
                default: {
                    color: string,
                    backgroundColor: string
                }
            },
            section3: {
                default: {
                    color: string,
                    backgroundColor: string
                }
            }
        },
        productDetails: {
            buttons: {
                default: ButtonProperties,
                active: ButtonProperties,
                focus: ButtonProperties
            },
            items: {
                default: {
                    backgroundColor: string,
                },
                focus: {
                    backgroundColor: string,
                },
            },
            section1: {
                default: {
                    backgroundColor: string
                },
                focus: {
                    backgroundColor: string
                }
            },
            section2: {
                default: {
                    color: string
                    backgroundColor: string
                }
            },
            section3: {
                default: {
                    color: string,
                    backgroundColor: string
                }
            }
        },
        cart: {
            buttons: {
                default: ButtonProperties,
                active: ButtonProperties,
                focus: ButtonProperties
            },
            items: {
                default: {
                    color: string,
                    backgroundColor: string,
                },
                active: {
                    color: string,
                    backgroundColor: string,
                },
                focus: {
                    color: string,
                    backgroundColor: string,
                },
            },
            section1: {
                default: {
                    backgroundColor: string
                },
            },
            section3: {
                default: {
                    color: string,
                    backgroundColor: string,
                }
            }
        }
    }
};
const roomshops= {
    items: { // Tarjetas de las tiendas
        default: {
            backgroundColor: 'rgba(87, 101, 117, 0.30)'
        },
        focus: {
            backgroundColor: DEFAULT_FOCUS_COLOR
        }
    } as const satisfies Record<string, CSSProperties>,
    section1: { // Barra superior con el mensaje de bienvenida de la tienda
        default: {
            backgroundColor: 'rgba(255, 255, 255, 0.20)'
        },
    } as const satisfies Record<string, CSSProperties>,
    section3: { // Barra central que contiene la lista de tiendas
        default: {
            backgroundColor: 'rgba(255, 255, 255, 0.60)'
        },
    } as const satisfies Record<string, CSSProperties>
} as const satisfies Templating["css"]["roomshops"];

const products = {
    buttons: {
        default: {
            color: BLACK,
            backgroundColor: WHITE,
            borderColor: DEFAULT_BORDER_COLOR,
            borderWidth: '1px',
        },
        active: {
            backgroundColor: "#0F63BD",
            borderColor: "#0F63BD",
            color: WHITE,
        },
        focus: {
            color: WHITE,
            backgroundColor: "#0F63BD",
            borderColor: "#0F63BD",
            borderWidth: '1px',
        }
    } as const satisfies Record<string, CSSProperties>,
    items: {
        focus: {
            borderColor: "#2286EF",
        }
    } as const satisfies Record<string, CSSProperties>,
    section1: { //Barra de arriba que contiene el nombre de la tienda y la lista de categorías de nivel 1
        default: {
            backgroundColor: 'rgba(255, 255, 255, 0.20)'
        }
    } as const satisfies Record<string, CSSProperties>,
    section2: { //Barra con el nombre de la categoría
        default: {
            color: WHITE,
            backgroundColor: '#76889B'
        }
    } as const satisfies Record<string, CSSProperties>,
    section3: { //Contenedor de los objetos de cada categoría
        default: {
            color: '#2E3843',
            backgroundColor: 'rgba(245, 246, 248, 0.60)'
        }
    } as const satisfies Record<string, CSSProperties>,
} as const as Templating["css"]["products"];

const productDetails = {
    buttons: {
        default: {
            color: BLACK,
            backgroundColor: WHITE,
            borderColor: "#D3DAE1",
            borderWidth: '1px',
        },
        active: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
            borderColor: "#D3DAE1",     // TODO these are same as default
            borderWidth: '1px',         // TODO these are same as default
        },
        focus: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
            borderColor: "#D3DAE1",     // TODO these are same as default
            borderWidth: '1px',         // TODO these are same as default
        }
    } as const satisfies Record<string, CSSProperties>,
    items: { // Tarjetas de los extras
        default: {
            backgroundColor: WHITE,
        },
        focus: {
            backgroundColor: WHITE,
        },
    } as const satisfies Record<string, CSSProperties>,
    section1: { // Top and bottom bar
        default: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        },
        focus: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
    },
    section2: { // Tarjeta oscura con el producto principal
        default: {
            color: WHITE,
            backgroundColor: '#76889B'
        }
    },
    section3: { // Tarjeta que contiene a todos los extras
        default: {
            color: BLACK,
            backgroundColor: 'rgba(245, 246, 248, 0.60)'
        }
    }
} as const satisfies Templating["css"]["productDetails"];

const cart = {
    buttons: {
        default: {
            color: BLACK,
            backgroundColor: WHITE,
            borderColor: "#D3DAE1",
            borderWidth: '1px',
        },
        active: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
            borderColor: "#D3DAE1",     // TODO these are same as default
            borderWidth: '1px',         // TODO these are same as default
        },
        focus: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
            borderColor: "#D3DAE1",     // TODO these are same as default
            borderWidth: '1px',         // TODO these are same as default
        }
    } as const satisfies Record<string, CSSProperties>,
    items: {
        default: {
            color: BLACK,
            backgroundColor: WHITE,
        },
        active: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
        },
        focus: {
            color: BLACK,               // TODO these are same as default
            backgroundColor: WHITE,     // TODO these are same as default
        }
    } as const satisfies Record<string, CSSProperties>,
    section1: { //Bottom bar
        default: {
            backgroundColor: 'rgba(236, 243, 251, 0.80)'
        }
    } as const satisfies Record<string, CSSProperties>,
    section3: { // Cart items and total container
        default: {
            color: BLACK,
            backgroundColor: '#F5F6F8',
        }
    } as const satisfies Record<string, CSSProperties>
} as const satisfies Templating["css"]["cart"];

export const DEFAULT_STYLES = {roomshops, products, productDetails, cart} as const;

export const make_stylesheet = <T extends keyof Templating["css"]>(template: Templating["css"][T] = {} as Templating["css"][T])=>{
    return ({
        default:    makeStylesheet(object.fromEntries(object.entries(template).map(([key, value]) => [key, 'default' in value ? value["default"] : {} as Record<string, CSSProperties>]))),
        active:     makeStylesheet(object.fromEntries(object.entries(template).map(([key, value]) => [key, 'active' in value ? value["active"] : {} as Record<string, CSSProperties>]))),
        focus:      makeStylesheet(object.fromEntries(object.entries(template).map(([key, value]) => [key, 'focus' in value ? value["focus"] : {} as Record<string, CSSProperties>]))),
    } as const);
}

export const DEFAULT_STYLEHSEET = {
    roomshops: make_stylesheet(DEFAULT_STYLES.roomshops),
    products: make_stylesheet(DEFAULT_STYLES.products),
    productDetails: make_stylesheet(DEFAULT_STYLES.productDetails),
    cart: make_stylesheet(DEFAULT_STYLES.cart)
} as const;

export default make_stylesheet;

