import { EVENTS } from "../../utils/eventsConst";
import { writeLog, getEnvVar } from "../../utils/utils";
import { STB } from "./stb";

export const Media = {
    ALARM_SNOOZE_TIMEOUT: 600000, //10 minutes
    ALARM_STOP_TIMEOUT: 900000, //15 minutes
    time: 0,
    init() {
        this.kill();
    },

    showVideoOnBack() {
        console.log("show video on back");
    },

    showVideoOnFront() {
        console.log("show video on front");
    },
    playHTMLVideo(videoId) {
        const videoItem = document.getElementById(videoId);
        if (videoItem) {
            videoItem.play();
            videoItem.onended = function () {
                dispatchNewMediaEvent(EVENTS.MEDIA.STATUS_END_OF_STREAM);
            };
            Media.playingMedia = true;
            this.lastVideo = { hls: null, channel: null, html: videoId };
        }
    },
    playHLSVideo(url, loop) {
        Media.playingMedia = true;
        this.lastVideo = { hls: url, channel: null, loop: loop };
        this._playHLSVideo(url, !!loop);
    },
    // eslint-disable-next-line no-unused-vars
    stop(params) {
        Media.playingMedia = false;
        Media.playingChannel = false;
        Media.playingAlarm = false;

        if (this.lastVideo?.html) {
            const videoItem = document.getElementById(this.lastVideo.html);
            if (videoItem) {
                videoItem.pause();
                videoItem.currentTime = 0;
            }
        } else {
            this._stop();
        }

        if (!params?.keepLastVideo) {
            this.lastVideo = null;
        }
    },
    exitLayout() {
        this.showVideoOnBack();
        this.stop();
    },
    playChannel: function (channel) {
        Media.playingChannel = true;
        this.lastVideo = { hls: null, channel: channel };

        switch (channel.type) {
            case "HLS":
                this._playHLSVideo(channel.url || channel.hlsurl);
                break;
            case "DVBT":
                this._playDVBTChannel(channel);
                break;
            case "DVBC":
                this._playDVBCChannel(channel);
                break;
            case "DVBS":
                this._playDVBSChannel(channel);
                break;
            case "ATSC":
                this._playATSCChannel(channel);
                break;
            default:
                this._playChannel(channel.ip, channel.port);
                break;
        }
    },

    playAlarm() {
        this.playingAlarm = true;
        const audioAlarm = document.getElementById("audioAlarm");
        if (!audioAlarm) {
            const audio = document.createElement("audio");
            audio.id = "audioAlarm";
            audio.loop = true;
            const source = document.createElement("source");
            source.type = "audio/mpeg";
            source.src = `${getEnvVar("DRIVE")}/tvs/alarm.mp3`;
            audio.appendChild(source);
            document.body.insertBefore(audio, document.body.firstChild);
            writeLog("play");
            audio.play();
        } else {
            audioAlarm.play();
        }
    },
    stopAlarm() {
        this.playingAlarm = false;
        console.log("stopAlarm");
        clearTimeout(Media.alarmTimeout);
        const audioAlarm = document.getElementById("audioAlarm");
        if (audioAlarm) {
            audioAlarm.pause();
        }
    },
    snoozeAlarm() {
        clearTimeout(Media.alarmTimeout);
        const audioAlarm = document.getElementById("audioAlarm");
        if (audioAlarm) {
            audioAlarm.pause();
        }
        Media.alarmTimeout = setTimeout(() => {
            Media.playAlarm();
        }, Media.ALARM_SNOOZE_TIMEOUT);
    },

    _playChannel(ip, port) {
        console.log(`Play channel IP: ${ip} PORT: ${port}`);
    },

    _playHLSVideo(url) {
        console.log(`Play HLS video ${url}`);
    },
    _playDVBTChannel: function (channel) {
        console.log("Play DVB-T channel");
    },
    _playDVBCChannel: function (channel) {
        console.log("Play DVB-C channel");
    },
    _playDVBSChannel: function (channel) {
        console.log("Play DVB-S channel");
    },
    _playATSCChannel: function (channel) {
        console.log("Play ATS-C channel");
    },
    replayLastVideo: function () {
        this.resetVideoSize();
        if (this.lastVideo?.channel) {
            this.playChannel(this.lastVideo?.channel);
        } else if (this.lastVideo?.html) {
            this.playHTMLVideo(this.lastVideo?.html);
        } else if (this.lastVideo?.hls) {
            this.playHLSVideo(this.lastVideo?.hls, this.lastVideo?.loop);
        }
    },
    _stop() {
        console.log("Die template");
    },
    //topx and width in vw
    //topy and height in vh
    // eslint-disable-next-line no-unused-vars
    setVideoSize(x, y, width, height) {
        console.log("Die template");
    },
    resetVideoSize() {
        this.lastVideoPos &&
            this.setVideoSize(
                this.lastVideoPos.x,
                this.lastVideoPos.y,
                this.lastVideoPos.width,
                this.lastVideoPos.height,
            );
    },
    ensureVideoSize() {
        console.log("Die template");
    },

    getAudioList() {
        console.log("Die template");
    },
    // eslint-disable-next-line no-unused-vars
    getSubtitleList(onSuccess) {
        console.log("Die template");
    },
    disableSubtitles() {
        console.log("Die template");
    },
    kill() {
        console.log("Die template");
    },
    changeAudio() {
        console.log("Die template");
    },
    changeSubtitle() {
        console.log("Die template");
    },
    pause() {
        console.log("Die template");
    },
    resume() {
        console.log("Die template");
    },
    checkForceAudio(movie) {
        console.log("Die template");
        return STB.forceMovieLanguage;
    },
    getStreamPos() {
        this.time = this.time + 50;

        return this.time;
        // in seconds
        console.log("Die template");
        const now = new Date();
        return now.getMinutes() * 60 + now.getSeconds();
    },
    setStreamPos(newpos) {
        // in seconds
        console.log("Die template");
    },
};
