import { Theme } from "../../../utils/theme";
import { printPrice } from "../../../utils/utils";

const DeliveryLocation = (item, index) => {
    const CURRENCY_CODE = sessionStorage.getItem("currencyCode");

    return (
        <div className="float-left" style={{ padding: "1.2vw", height: "14.907vh", width: "17.5vw" }}>
            <div className={`mx-auto text-2xl text-center  text-ellipsis overflow-hidden`} style={{ height: "7.5vh" }}>
                <div
                    style={{
                        overflow: "hidden",
                        lineHeight: item.name.length > 20 ? "3.5vh" : "7vh",
                        height: "7vh",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                    }}
                >
                    {item.name}
                </div>
            </div>
            <div className={" text-center text-xl pt-3"}>{printPrice(item.price, CURRENCY_CODE)}</div>
        </div>
    );
};
export default DeliveryLocation;
