import {  Children } from "preact/compat";
import type { FC} from "preact/compat";
import { memo} from "preact/compat";
import { VNode } from "preact";
import { useLayoutEffect, useMemo } from "preact/hooks";


const BackgroundImage: FC<{src: string}> = ({ src, children }) => {

    useLayoutEffect(() => {
        const targetElement = (Children.only(children) as VNode<any>);
        if (targetElement?.ref && src) {
            if (src.startsWith("rgb(") || src.startsWith("rgba(") || src.startsWith("#")){
                //@ts-ignore
                targetElement.ref.current.style.background = src;
            }
            else if (src.startsWith('data:')){
                //@ts-ignore
                targetElement.ref.current.style.backgroundImage = `url("${src}")`;
            }
            else {
                //@ts-ignore
                targetElement.ref.current.style.backgroundImage = `url("${src}?w=${targetElement.ref.current.clientWidth}&h=${targetElement.ref.current.clientHeight}")`;
            }
            //@ts-ignore
            targetElement.ref.current.style.backgroundPosition = '50% 50%';
            //@ts-ignore
            targetElement.ref.current.style.backgroundSize = 'cover';
        }
    }, []);

  
    return useMemo(() => {
        return (<>{children}</>);
    },[src]);
};

export default memo(BackgroundImage);
