import { useEffect, useState } from "preact/hooks";
import type { FunctionalComponent, ComponentChildren } from "preact";

const Asyncable: FunctionalComponent<{children: PromiseLike<ComponentChildren>, onError?: ComponentChildren, onLoading?: ComponentChildren}> = ({ children, onError = <></>, onLoading = <></> }) => {
    const [elements, setElements] = useState<ComponentChildren>(onLoading);
    useEffect(()=>{
        if (Array.isArray(children)) {
            Promise.all(children).then((x)=>setElements(x), ()=>setElements(onError))
        }
        else {
            children.then(x=>setElements(x), ()=>setElements(onError));
        }
    }, [children]);
    return <>{elements}</>;
};

export default Asyncable;